import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const Communication = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Information Resources Links</title>
                <meta name="description" content="Information Resources Links" />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="communication">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">

                            <h3 className="mainCaption">Information Resources Links</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />

                            
                            <h4 className="caption">Digital Colony Management</h4><br />
                        </div>
                    </div>
                

                    <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px;"}}>
                        <ul className="google-list">
                            <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                            <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                            <li className="text">Learn about <Link className="textLink" to="/emergencyPlan">Emergency Planning</Link></li><br />
                            <li className="text">Learn about <Link className="textLink" to="/disasterRecovery">Disaster Recovery and Research Continuity Environment</Link></li><br />
                            <li className="text">To learn about <b>Security Tips</b> please visit <Link className="textLink" to="/securityHygiene">Security Hygiene Tips</Link></li><br />
                            <li className="text">Visit SoftMouseFAQ (<b>Tutorials and Manual</b>): <a className="textLink" href="https://www.softmousefaq.com" target="_blank" rel="noreferrer">SoftMouseFAQ.com</a></li>
                        </ul>
                        <br /><br />
                    </div>

                </div>
            </section>
            <Footer />
        </div>

    )
}