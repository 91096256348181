import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const Upgrade = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Scheduled Application Upgrades</title>
                <meta name="description" content="To maintain the highest levels of quality in our software, databases and data centers, Iseehear must periodically perform scheduled system maintenance and upgrades. The Scheduled Upgrades or System Maintenance Procedures assist us in ensuring the relevance, security and performance of the Iseehear systems. During Upgrades and System Maintenance Procedures, access to your account will be temporarily unavailable." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="upgrade">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Scheduled Application Upgrades And System Maintenance Procedures</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                            <h4 className="caption">Digital Colony Management</h4><br />
                        </div>
                    </div>
              

                    <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px"}}>
                            <div className="largefont">

                                <p className="text">To maintain the highest levels of quality in our software, databases and data
                                centers, Iseehear must periodically perform scheduled system maintenance and upgrades. The
                                Scheduled Upgrades or System Maintenance Procedures assist us in ensuring the relevance,
                                security and performance of the Iseehear systems. During Upgrades and System Maintenance
                                Procedures, access to your account will be temporarily unavailable.</p><br /> <br />
                                <ol className="data_list">
                                    <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                                    <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                    <li className="text">Scheduled Application Upgrades or System Maintenance Procedures are conducted on Friday and / or
                                        Saturday evenings during our regular maintenance window between 9:00 pm and 3:00 am EST
                                        (Toronto) in order to minimize inconvenience to our subscribers around the world. Note
                                        that major version upgrades may require an extension to the regular maintenance window
                                        to ensure a successful upgrade.
                                    </li>
                                    <li className="text">Iseehear will provide advance notice of Scheduled Application Upgrades or System Maintenance
                                        Procedures to ensure subscribers have sufficient time to plan and prepare for the
                                        temporary service interruption.
                                    </li>
                                    <li className="text">Application access will be temporarily unavailable in order to execute the Upgrade or
                                        System Maintenance Procedures.
                                    </li>
                                    <li className="text">Dates and times for all Scheduled Upgrades or System Maintenance Procedures will be
                                        posted in advance in these locations:
                                        <ol className="data_list">
                                            <li className="text">Iseehear Health <a className="textLink" href="https://iseehearsecurity.statuspage.io/" target="_blank" rel="noreferrer">Network Status Check &nbsp;<img src={require("../../img/external-link-black.png")} alt="link" /></a></li>
                                            <li className="text">On the <b>Notice Board</b> section of the login access page</li>
                                            <li className="text">On the <b>Information Scroll</b> located at the top of <a className="textLink" href="https://www.softmouse.net" style={{textDecoration: "underline"}} target="_blank" rel="noreferrer">www.SoftMouse.NET</a> &nbsp;<img src={require("../../img/external-link-black.png")} alt="link" /></li>
                                        </ol>
                                    </li>
                                    <li className="text">Scheduled Application Upgrades or System Maintenance to applications will not affect the data that
                                        you have entered into the system.
                                    </li>
                                    <li className="text"><span className="SoftMouseredfont bold">Important:</span> During Scheduled Application Upgrades or
                                        System Maintenance Procedures, access to your account will be temporarily unavailable.
                                    </li>
                                    <li className="text">If you have any questions or require additional information, please <Link className="textLink" to="/contactUs">contact the Iseehear Support Desk</Link></li>
                                    <li className="text">In order to convert Toronto time to your local time, please use this <a className="textLink" href="http://www.timeanddate.com/worldclock/converter.html" target="_blank" rel="noreferrer" style={{textDecoration: "underline"}}>Time Zone Converter – Time Difference Calculator</a>&nbsp; <img src={require("../../img/external-link-red.png")} alt="link" />
                                    </li>
                                </ol>
                                <p className="text">Thank you for your understanding and support.</p>
                            </div>
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}