import React from 'react'
// import { useEffect } from 'react'
// import axios from "axios";
import { FetchDataQuestions } from './FetchDataQuestions';
import { useContext } from 'react';
import GeneralContext from '../../Context/GeneralContext';

export const DataSectionRender = () => {
    const {sections} = useContext(GeneralContext)
    const [searchValue, setSearchValue] = React.useState('');

    return (
        <>
            <div className="nav-item loupeItem">
                <img className="exitSearch" onClick={() => setSearchValue('')} src={require("../../img/error.png")} alt="exit" />
                <input className="searchBox" type='text' onChange={(e) => setSearchValue(e.target.value)} value={searchValue} placeholder="Security Search" />
                <img className="loupe" src={require("../../img/loupe.png")} alt="loupe" />
            </div>  
            {sections.map((section) => (
                <div key={section.id} id={section.section_name.replace(/\s/g, '')} className="content-section-b">
                    <ol className="encryption_overview">
                        <h2 className="textCaption fw-bold">{section.section_name}</h2><br />
                        <FetchDataQuestions 
                        searchValue = {searchValue}
                        sectionid = {section.id}
                        />
                    </ol>
                </div>   
            ))}
        </>
    )
}