import React from "react";
import axios from "axios";
import { Component } from "react";
import QuestionContext from '../../Context/GeneralContext';
import {POST_HECVAT_SECTION} from "../ApiURL";

export default class SectionForm extends Component {

    static contextType = QuestionContext

    // State for Sections
    state = {
        section_name: "",
        created_at: "",
        updated_at: ""
    }

            // handle inputs For Sections
            handleSectionName=(e)=>{
                this.setState({
                    section_name:e.target.value
                })
            }
        
            handleSectionCreated_at=(e)=>{
                this.setState({
                    created_at:e.target.value
                })
            }
        
            handleSectionUpdated_at=(e)=>{
                this.setState({
                    updated_at:e.target.value
                })
            }

            formSubmitSections=(e)=>{
                e.preventDefault();
        
                let data = {
                    section_name:this.state.section_name,
                    created_at:this.state.created_at,
                    updated_at:this.state.updated_at
                }
    
                function ErrorMessage() {
                    let questionsForm = document.querySelector('.formGroup');
                    let element = document.createElement('p');
                    element.textContent = "<p class='hideMsg'>Form Not Sent to DB</p>";
                    questionsForm.insertAdjacentHTML('beforebegin', element.textContent);
                }
        
                axios.post(POST_HECVAT_SECTION, data)
                .then(res => {
                    this.setState({
                        sent: true,
                    }, this.resetFormSections())
                })
                .catch(() => {
                    ErrorMessage()
                    this.resetFormSections()
                })

                this.context.forceUpdate()
            }
    
                // form Reseting initial data
            resetFormSections = () => {
                this.setState({
                    section_name: "",
                    created_at: "",
                    updated_at: ""
                })
    
                setTimeout(() => {
                    this.setState({
                        sent: false,
                    })
                }, 3000)
            }

        render () {
            return ( 
                <form className="adminForm" onSubmit={this.formSubmitSections}>
                            <h2 className="caption">Create a New Section</h2>
                            { this.state.sent ? <p className="hideMsgSuccess"> Form Has Been Sent to DB</p> : <p></p>} 
                            <fieldset className="formGroup">
                                <div className="inputBlock">
                                    <label className="textCaption" htmlFor="question">Enter The Section Name</label>
                                    <textarea 
                                        className="textArea text" 
                                        type="text" 
                                        id="question" 
                                        name="question"
                                        onChange={this.handleSectionName}
                                        value={this.state.section_name}
                                    />
                                </div>
                                <div className="inputBlock">
                                    <label className="textCaption" htmlFor="create">Create (date and time):</label>
                                    <input 
                                        className="textArea text" 
                                        type="datetime-local" 
                                        id="create" 
                                        name="create" 
                                        onChange={this.handleSectionCreated_at}
                                        value={this.state.created_at}
                                    />
                                </div>
                                <div className="inputBlock">
                                    <label className="textCaption" htmlFor="update">Update (date and time):</label>
                                    <input 
                                        className="textArea text" 
                                        type="datetime-local" 
                                        id="update" 
                                        name="update" 
                                        onChange={this.handleSectionUpdated_at}
                                        value={this.state.updated_at}
                                    />
                                </div><br />
                                <button type="submit" className="button">Create Section</button>
                            </fieldset>
                </form>              
            )
        }
            
}