import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import GeneralContext from "../../Context/GeneralContext";
import {POST_HECVAT_PASSWORD} from "../ApiURL";

export default function CreatePassword() {
  const { userName, onRemoveUser, forceUpdate } = useContext(GeneralContext);
  const [createUsername, setCreateUsername] = useState();
  const [createPassword, setCreatePassword] = useState();

  const handleSubmitCreatePass = async (e) => {
    e.preventDefault();

    let data = {
      createUsername,
      createPassword,
    };

    axios
      .post(POST_HECVAT_PASSWORD, data)
      .then((res) => {
        this.setState({
          sent: true,
        }, e.target.reset());
      })
      .catch(() => {
        console.error("err");
        e.target.reset();
      });

    forceUpdate();
  };

  return (
    <div
      className="container"
      style={{ flexWrap: "nowrap !important", flexDirection: "column" }}
    >
      <form
        className="questionsForm"
        style={{ margin: "0 auto", textAlign: "center" }}
        onSubmit={handleSubmitCreatePass}
      >
        <h2 className="caption">Welcome to Create Password Page</h2>
        <br />
        <br />
        <fieldset
          className="formGroup"
          style={{
            border: "2px solid lightblue",
            margin: "0 auto",
            padding: "2rem",
            borderRadius: "1rem",
            backgroundColor: "aliceblue",
          }}
        >
          <div
            className="inputBlock"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginBottom: "1rem",
            }}
          >
            <label className="textCaption" htmlFor="username">
              Username:
            </label>
            <input
              style={{ paddingRight: "2rem", maxWidth: "40rem" }}
              className="textArea text"
              type="text"
              id="username"
              name="username"
              required
              onChange={(e) => setCreateUsername(e.target.value)}
            />
          </div>
          <div
            className="inputBlock"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginBottom: "2rem",
              position: "relative",
            }}
          >
            <label className="textCaption" htmlFor="password">
              Password:
            </label>
            <input
              style={{ paddingRight: "2rem", maxWidth: "40rem" }}
              className="textArea text"
              type="text"
              id="password"
              name="password"
              required
              onChange={(e) => setCreatePassword(e.target.value)}
            />
          </div>
          <div className="loginButton">
            <button type="submit" className="mainButton">
              Create New Password
            </button>
            <Link to="/">
              <button onClick={() => sessionStorage.removeItem("token")} className="button">Back to Home</button>
            </Link>
          </div>
        </fieldset>
      </form>
      <div style={{ margin: "0 auto" }}>
        <ul>
          {userName.map((username) => (
            <li
              style={{
                border: "2px solid lightblue",
                padding: "5px 2rem",
                borderRadius: "5px",
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "space-between",
                gap: "3rem",
              }}
              key={username.id}
            >
              <p className="text">Username: {username.username}</p>
              <img
                onClick={() => {
                  onRemoveUser(username.id);
                  forceUpdate();
                }}
                style={{ maxWidth: "2.5rem", cursor: "pointer" }}
                src={require("../../img/bin.png")}
                alt="trash"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
