import React from "react";
import { Helmet } from "react-helmet";
import { HashLink as A } from "react-router-hash-link"; 
import { Footer } from "../Footer";
import { Header } from "../Header";

export const ScheduledSecurityTestingProcedures = () => {
  return (
    <div className="wrapper">
            <Helmet>
                <title>Scheduled Security Testing Procedures</title>
                <meta name="description" content="Security testing and active monitoring assessments of IT infrastructure, platforms and applications are part of Iseehear Inc. Life Sciences (Iseehear) ongoing efforts to strengthen data security associated with securing personal identities, research data and all IT ecosystem cloud infrastructure." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check, security, test" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
        <section className="scheduledSecurityTestingProcedures" style={{marginBottom: "4rem"}}>
            <div className="container">

                <div className="row malware-caption">
                    <div className="col-lg-9">
                        <h3 className="mainCaption">Iseehear Cloud, Platforms and Applications Security Testing</h3>
                        <hr style={{borderBottom: "2px solid lightblue"}} /><br />
                    </div><br />
                </div>

                <div>
                    <p className="text">Security testing and active monitoring assessments of IT infrastructure, 
                        platforms and applications are part of Iseehear Inc. Life Sciences (Iseehear) 
                        ongoing efforts to strengthen data security associated with securing personal identities, 
                        research data and all IT ecosystem cloud infrastructure.
                    </p><br/><br/>

                    <h2 className="caption">Why Security Monitoring Testing</h2>
                    <p className="text">The Iseehear Security Team regularly performs vulnerability and annual 
                        penetration testing as well as daily security monitoring assessments 
                        against the Iseehear cloud infrastructure, platforms and applications:
                    </p>

                    <ul className="data_list">
                        <li className="text">As a preparedness and evaluation exercise of our security measures.</li>
                        <li className="text">To identify and prioritize potential security risks.</li>
                        <li className="text">To be prepared and on alert for a possible breach or attack.</li>
                        <li className="text">As maintenance and upgrade opportunities for our security posture.</li>
                        <li className="text">To comply with industry standards, frameworks and audits that require regular security testing.</li>
                    </ul>

                    <p className="text">These security tests and assessments are 
                        intended to validate and improve the overall security of 
                        Iseehear cloud infrastructure, platforms, and applications services.
                    </p><br/><br/>

                    <h2 className="caption">Preparing For The Annual Iseehear Cloud, Platforms and Applications Security Testing</h2><br/><br/>
                    <p className="text">Iseehear Security will notify customers in advance via the Iseehear Security website Notice Board.</p>
                    <p className="text">During the annual security testing event there will be no interruption to customers services or usage of any Iseehear cloud services, platforms or applications.</p>
                    <p className="text">In the event of unforseen circumstances Iseehear Security will notify customers via the Iseehear Security website Notice Board regarding next steps.</p>
                    <A className="textLink" to="/#rolesAndResponsibilities">Learn more about the roles and responsibilities of the Iseehear IT Security Team.</A>
                </div>

            </div>
        </section>
        <Footer />
    </div>
  )
}


    
