import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const EmergencyPlan = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Emergency Plan</title>
                <meta name="description" content="Many labs and animal care departments have adopted the strategy of having rotating teams go to the animal facility to take care of the animals and manage the colonies. Having these kinds of tag teams helps to ensure physical distancing as well as making sure teams don’t burn out." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="emergency-plan">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">

                            <h3 className="mainCaption">Here is how <span style={{color: "lightblue"}}>YOU</span> can Prepare</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                            <h4 className="caption" style={{color: "lightblue"}}>Emergency Planning</h4><br />
                        </div>
                    </div>
                </div>
                <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px"}}>
                    <div className="container">

                        <div className="largefont">
                        <h4 className="caption"><b>Preparations for Working from Home or Remotely in an emergency</b></h4>
                        <ul className="data_list">
                            <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                            <p className="text">1. Develop contingency plans: Ensure that colony management responsibilities are shared between teams and ensure you put contingency plans in place in case key personnel get sick.</p><br /><br />

                            <b><h3 className="subCaption">Suggestion:</h3></b><br />
                                <ul>
                                    <p className="text">Here is our suggestion to handle rotating teams.</p><br />
                                    <p className="text">Many labs and animal care departments have adopted the strategy of having rotating teams go to the animal facility to take care of the animals and manage the colonies. Having these kinds of tag teams helps to ensure physical distancing as well as making sure teams don’t burn out.</p><br /><br />
                                </ul>
                                <span className="text" style={{color: "blue"}}>SoftMouse.NET &nbsp;Tip:</span>
                                <ul>
                                    <p className="text">You can temporarily allow for relaxed permission settings on your account to ensure the right people have the edit capability they need to make updates to the records.</p> <br />
                                    <p className="text">Have your SoftMouse.NET admin make the necessary changes to the access permissions on the account OR <a className="textLink" href="https://softmouse.net/softmouse-mouse-colony-management-software-database-lims-contacts.jsp" target="_blank" rel="noreferrer">contact our support team</a> for assistance with this.</p>
                                </ul>

                            <br />
                            <p className="text">2. Back up your data: Have a back-up strategy and remember to do it: All important files should be backed up regularly. Automated personal backup: If you would like to receive an automated back up of your data by email either weekly or monthly, please <a className="textLink" href="https://www.softmouse.net/softmouse-mouse-colony-management-software-database-lims-contacts.jsp" target="_blank" rel="noreferrer">contact our support team</a> and we will gladly assist you with setting this up.</p><br /><br />
                            <p className="text">3. Ensure adequate support in case of problems.</p><br /><br />
                            <p className="text">4. Establish procedures to follow in the event of a security incident.</p>
                        </ul><br />
                            <h2 className="caption">Frequently asked questions:</h2><br />
                            <div className="col-lg-12 col-md-12 col-xs-12 largefont">
                                
                                <ul className="data_list">
                                    <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                                    <span className="subCaption"><b>1. How can we use SoftMouse.NET to assist in scaling down the colony?</b></span><br />                               
                                    <p className="text">Different locations are putting in place different policies for what needs to happen with the research animals in these difficult times. Many are suggesting that breeding pairs should be separated and only essential animals be kept in a maintenance mode. Cryopreservation is also being suggested for certain strains.</p><br /><br />
                                    <span className="text" style={{color: "blue"}}>SoftMouse.NET &nbsp;Tip:</span>
                                    <p className="text">Use the <a className="textLink" href="https://softmousefaq.com/Customize+Drop+Down+Lists" target="_blank" rel="noreferrer">customizable Cage Status</a> as a way of marking Priority Cages so that the rotating teams know which cages are marked to save.</p><br />
                                    <br />
                                    <p className="text">Did you know that you can archive mouselines? If you have chosen to cryopreserve a mouseline, on your mouseline list, you can easily select the lines in question and click the Archive button. This will simply remove the line from the main list making it easy to see what is available. You can have peace of mind that all past information for this line will be in the archive and <a className="textLink" href="https://softmousefaq.com/Filter%20for%20Archived%20Mouselines" target="_blank" rel="noreferrer">readily restored</a> when your research requires.</p><br /><br />
                                </ul>
                                
                                <ul className="data_list">
                                    <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                                    <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                                    <span className="subCaption"><b>2. In the event that my administrative/financial colleagues are not as responsive as usual, how do I ensure that I have continuous access to my SoftMouse.NET account if my subscription is up for renewal or I want to sign up?</b></span><br />                                
                                    <p className="text">We understand administrative processes might not be running as usual with the current circumstances and we would like to help as much as we can.</p><br /><br />
                                    <span className="text" style={{color: "blue"}}>SoftMouse.NET &nbsp;Tip:</span>
                                    <p className="text">A simple written confirmation of renewal would allow us to guarantee continuous access to your SoftMouse.NET account while you go through the payment process. Please simply update us as soon as you know more information.
                                    All communication regarding your SoftMouse.NET subscription should be directed to: subscriptions@iseehear.com</p><br /><br />
                                </ul>
                                
                                <ul className="data_list">
                                    <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                    <span className="subCaption" style={{marginTop: "20px", display: "block"}}><b>3. What measures is SoftMouse.NET taking to protect my data?</b></span>                               
                                    <p className="text">We understand the gravity of these uncertain times and appreciate the concern over the security and protection of your research data.</p><br /><br />
                                    <p className="text">The security measures and disaster recovery policies that we already have in place allow us to ensure that your data is monitored, backed up, secured and retrievable at all times. <Link className="textLink" to="/emergencyPlan">Read more HERE</Link>.</p><br /><br />
                                    <span className="text" style={{color: "blue"}}>SoftMouse.NET &nbsp;Tip:</span>
                                    <p className="text">If you would like to receive an automated back up of your data by email either weekly or monthly, please <a className="textLink" href="https://softmouse.net/softmouse-mouse-colony-management-software-database-lims-contacts.jsp" target="_blank" rel="noreferrer">contact our support team</a> and we will gladly assist you with setting this up.</p><br /><br />
                                </ul>
                                <br />
                            </div>

                            <br /><br />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}