import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const ContactUs = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="If you would like to set up a time to speak, please email support@iseehear.com with the time and phone number at which you can be reached." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="contact-us">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">

                            <h3 className="mainCaption">Contact Us Resources</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />

                            
                            <h4 className="caption">Digital Colony Management</h4><br />
                        </div>
                    </div>
                
                    <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px"}}>
                            <br /><br />
                            <ul className="data_list">
                                <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                <li className="text">If you are experiencing an issue, please contact and inform <Link className="textLink" to="/contactForm">customer support</Link></li><br />


                                <li className="text"><span className="bold"><i className="icon-phone blackfont"></i> Telephone: </span> <span>+1 416 492 4358 (Toronto, Worldwide)</span> </li>
                                <br />
                                <li className="text"><span className="bold"><i className="icon-phone blackfont"></i> Toll Free: </span> <span>1 844 559 6322 (United States & Canada)</span></li><br />

                                <li className="text"><span><span className="bold"><i className="icon-envelope-alt blackfont"></i> Email Us 24/7: </span>support@iseehear.com</span></li><br />
                            </ul>
                            <br /><br />
                    </div>

                    <div className="content-section-b" style={{paddingBottom: "20px", paddingTop: "20px"}}>
                            <div className="row contact-us">
                                    <div className="col-lg-6" style={{marginBottom: "-50px"}}>
                                        <div className="row largefont">

                                            <div className="col-lg-12 mleft-10" style={{marginBottom: "20px"}}>
                                                <h4 className="caption"><b>Head Office Location</b></h4><br />
                                                <div className="directionToIseehear">   
                                                    <p className="text">Iseehear Inc.</p><br />
                                                    <a href="http://iseehear.com/directions.php" target="_blank" rel="noreferrer" className="button big blue" style={{marginBottom: "20px"}}><span className="whitefont ">Directions to Iseehear Inc.</span>  <img src={require("../../img/external-link.png")} alt="externalImg" /></a>
                                                </div> 
                                                <p className="text">250 University Avenue</p> <br />
                                                <p className="text">Suite 200</p> <br />
                                                <p className="text">Toronto, Ontario</p><br />
                                                <p className="text">Canada M5H 3E5</p>
                                                <br /><br />

                                                <p className="text">* If you would like to set up a time to speak, please email support@iseehear.com with the time and phone number at which you can be reached.</p><br /><br />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 push-right">

                                        <div className="data_list">
                                            <div className="h_iframe">
                                                {/* a transparent image is preferable */}
                                                <img className="ratio" src={require("../../img/transparent.gif")} alt="tranparentImg" />
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.595613408178!2d-79.41630999999998!3d43.78125249999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x48a58b938402b0e1!2sIseehear+Inc!5e0!3m2!1sen!2sca!4v1396835544607" title="myFrame" width="600" height="350" frameborder="0" style={{border: "0"}} className="mapframe"></iframe>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                            </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}