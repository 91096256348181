const GeneralReduser = (state, action) => {
    switch (action.type) {
        case 'GET_QUESTION' :
            return {
            ...state,
            question:action.payload,
    };
        case 'SET_ERROR':
            return {
            error: true,
            };
        default:
            return state;          
    }
}

export default GeneralReduser;