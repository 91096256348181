import React from "react";
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {POST_LOGIN} from "../ApiURL";

async function loginUser(credentials) {
    return fetch(POST_LOGIN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

export default function Login ({setToken}) {
    const [showPass, setShowPass] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();

        const token = await loginUser({
          username,
          password
        });
        setToken(token);
      }

            return (
                <div className="container">
                    <form className="questionsForm" style={{margin: '0 auto', textAlign: 'center'}} onSubmit={handleSubmit}>
                        <h2 className="caption">Welcome to Login Page</h2><br /><br />
                        <fieldset className="formGroup" style={{border: '2px solid lightblue', maxWidth: '36rem', margin: '0 auto', padding: '2rem', borderRadius: '1rem', backgroundColor: 'aliceblue'}}>
                            <div className="inputBlock" style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}>
                                <label className="textCaption" htmlFor="username">Username:</label>
                                <input
                                style={{paddingRight: "2rem"}}
                                    className="textArea text" 
                                    type="text" 
                                    id="username" 
                                    name="username"
                                    required
                                    onChange={e => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="inputBlock" style={{display: 'flex', justifyContent: 'center', marginBottom: '2rem', position: "relative"}}>
                                <label className="textCaption" htmlFor="password">Password:</label>
                                <input 
                                    style={{paddingRight: "2rem"}}
                                    className="textArea text" 
                                    type={showPass ? "text" : "password"} 
                                    id="password" 
                                    name="password"
                                    required
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <img onClick={() => setShowPass((v) => !v)} className="hiddenEye" src={require("../../img/newPage/hidden.png")} alt="hidden" />
                            </div>
                            <div className="loginButton">
                                <button type="submit" className="mainButton">LogIn</button>
                                <Link to="/"><button className="button">Back to Home</button></Link>    
                            </div>
                        </fieldset>
                    </form>
                </div>
            )
}