import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const Copyright = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Copyright</title>
                <meta name="description" content="All Web site design, text, graphics, the selection and arrangement thereof (collectively, the Content) Copyright ©, Iseehear Inc. ALL RIGHTS RESERVED. Permission is granted to electronically copy and to print in hard copy portions of this Web site for the sole purpose of using this Web site as a research and information resource." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="copyright">
                <div className="container">
                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Copyright Policy</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />                            
                        </div>
                    </div>

                    <div className="subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <p className="text">All Web site design, text, graphics, the selection and arrangement thereof (collectively, the &quot;Content&quot;) Copyright &copy;, Iseehear Inc. ALL RIGHTS RESERVED. Permission is granted to electronically copy and to print in hard copy portions of this Web site for the sole purpose of using this Web site as a research and information resource. Any other use of materials on this Web site--including reproduction for purposes other than those noted above, modification, distribution, or republication--without the prior written permission of Iseehear Inc. is strictly prohibited&acute;.</p><br />
                        <p className="text">All information, content, services and software displayed on, transmitted through, or used in connection with Iseehear.com, including for example directories, maps, text, photographs, images, illustrations, audio clips, video, source and object code, trademarks, logos, and the like (collectively, the &quot;Content&quot;), as well as its selection and arrangement, is owned by Iseehear Inc. (&quot;ISH&quot;), and its affiliated companies, licensors and suppliers. Do not remove any trademark, copyright or other notice from such Content.</p><br />
                        <p className="text">You may not, for example, republish any portion of the Content on any Internet, Intranet or extranet site or incorporate the Content in any database, compilation, archive or cache. You may not distribute any Content to others, whether or not for payment or other consideration, and you may not modify copy, frame, cache, reproduce, sell, publish, transmit, display or otherwise use any portion of the Content. You agree not to decompile, reverse engineer or disassemble any software or other products or processes accessible through Iseehear.com, not to insert any code or product or manipulate the content of Iseehear.com in any way that affects the user&acute;s experience, and not to use any data mining, data gathering or extraction method.</p><br />
                        <p className="text">Requests to use Content for any purpose other than as permitted in these Terms of Use Policies should be directed to legal.affairs@iseehear.com.</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}