import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const SecurityNotifications = () => {
  return (
        <div className="wrapper">
            <Helmet>
              <title>Security Notifications</title>
              <meta name="description" content="UPDATE: OpenSSL Releases Security Updates (CVE-2022-0778)" />
              <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="securityNotifications">
            <div className="container">

              <h1 className="mainCaption">Security Notifications</h1>
              <div className="firstScrinImg">
              </div>
              <div className="content-section-b">
                <hr style={{borderBottom: "2px solid lightblue"}} />
                <div className="row main-content">
                    <div className="subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <div className="accordion">
                          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                            <button style={{backgroundColor: "#ff7675", border: "2px #86b7fe solid"}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne2" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne2">
                              <h3 className="caption" style={{fontSize: "3rem", lineHeight: "3.6rem"}}>UPDATE: OpenSSL Releases Security Updates (CVE-2022-0778) <br /><span className="textCaption">6 months ago</span></h3>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseOne2" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne2">
                            <div className="accordion-body">                            
                                <p className="text">Created Date: March 18, 2022</p>
                                <p className="text">Last Modified Date: March 18, 2022</p>  
                                <h3 className="subCaption">Description</h3> 
                                <p className="text">************************</p>
                                <p className="text">*** NOTICE March 18TH 2022 (10:30 PM EST) ***</p>
                                <p className="text">Iseehear Inc. IT Security has completed it's remediation of CVE-2022-0778.</p>
                                <p className="text">Iseehear Inc. IT Security has completed it's patch remediation upgrade to OpenSSL v3.0.2 as recommended by OpenSSL and CISA.</p>
                                <p className="text">If you have any questions, please contact the Iseehear Support Desk at support@softmouse.net.</p>
                                <p className="text">Thank you</p>
                                <p className="text">Iseehear Inc. Life Sciences</p>
                                <p className="text">************************</p>
                                <p className="text">*** NOTICE March 18TH 2022 ***</p>
                                <p className="text">The Iseehear Inc. IT Security and Application Development Teams are actively reviewing which Iseehear Inc. products are impacted by CVE-2022-0778. As we assess potential impact and remediation, if any, we will update this notice.</p>
                                <p className="text">Please follow this notice for the latest updates.</p>
                                <p className="text">If an Iseehear Inc. application is impacted and remediation is needed a separate link or an email containing remediation steps will be provided to affected customers.</p>
                                <p className="text">Iseehear Inc. IT Security will continue to monitor the (CVE-2022-0778) situation.</p>
                                <p className="text">More details surrounding the CVE can be found in the following 3rd party links:</p>
                                <a className="text" href="https://www.cisa.gov/uscert/ncas/current-activity/2022/03/17/openssl-releases-security-updates" target="_blank" rel="noreferrer"><p className="textLink">https://www.cisa.gov/uscert/ncas/current-activity/2022/03/17/openssl-releases-security-updates</p></a>
                                <a className="text" href="https://www.openssl.org/news/secadv/20220315.txt" target="_blank" rel="noreferrer"><p className="textLink">https://www.openssl.org/news/secadv/20220315.txt</p></a>
                                <p className="text">Thank you</p>
                                <p className="text">Iseehear Inc. Life Sciences</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion">
                          <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                            <button style={{backgroundColor: "#ff7675", border: "2px #86b7fe solid"}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo2" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo2">
                                <h3 className="caption" style={{fontSize: "3rem", lineHeight: "3.6rem"}}>UPDATE: Apache Log4j 2 utility (CVE-2021-44228) <br /><span className="textCaption">8 months ago</span></h3>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseTwo2" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo2">
                            <div className="accordion-body">
                                <p className="text">Created Date: December 11th, 2021</p>
                                <p className="text">Last Modified Date: January 14th, 2022</p>  
                                <h3 className="subCaption">Description</h3> 
                                <p className="text">************************</p>
                                <p className="text">*** NOTICE JANUARY 10TH 2022 ***</p>
                                <p className="text">Iseehear Inc. IT Security continues to scan for CVE-2021-44228 vulnerabilities.</p>
                                <p className="text">At this time, we wish to inform you that no CVE-2021-44228 vulnerabilities were found nor detected with the SoftMouseNET Platform.</p>
                                <p className="text">The Iseehear Inc. IT Security team continues to monitor the situation closely and we will continue to provide updates as soon as we know if there is an evolving threat regarding CVE-2021-44228.</p>
                                <p className="text">************************</p>
                                <p className="text">***UPDATE DECEMBER 29TH 2021 ***</p>
                                <p className="text">Iseehear Inc. IT Security has completed it's evaluation of CVE-2021-44832 in relation to our applications.</p>
                                <p className="text">Based on our investigation and testing none of Iseehear Inc. applications relating to Log4j, are affected by CVE-2021-44832.</p>
                                <p className="text">************************</p>
                                <p className="text">*** NOTICE DECEMBER 24TH 2021 ***</p>
                                <p className="text">Iseehear Inc. IT Security continues to scan for CVE-2021-44228 vulnerabilities.</p>
                                <p className="text">At this time, we wish to inform you that no CVE-2021-44228 vulnerabilities were found nor detected with the SoftMouseNET Platform.</p>
                                <p className="text">The Iseehear Inc. IT Security team continues to monitor the situation closely and we will continue to provide updates as soon as we know if there is an evolving threat regarding CVE-2021-44228.</p>
                                <p className="text">************************</p>
                                <p className="text">*** NOTICE DECEMBER 23RD 2021 ***</p>
                                <p className="text">Iseehear Inc. IT Security continues to scan for CVE-2021-44228 vulnerabilities.</p> 
                                <p className="text">At this time, we wish to inform you that no CVE-2021-44228 vulnerabilities were found nor detected with the SoftMouseNET Platform.</p>
                                <p className="text">The Iseehear Inc. IT Security team continues to monitor the situation closely and we will continue to provide updates as soon as we know if there is an evolving threat regarding CVE-2021-44228.</p>
                                <p className="text">************************</p>
                                <p className="text">***UPDATE DECEMBER 22ND 2021 ***</p>
                                <p className="text">Iseehear Inc. IT Security has completed it's evaluation of CVE-2021-45105 in relation to our applications.</p>
                                <p className="text">Based on our investigation and testing none of Iseehear Inc. applications relating to Log4j, are affected by CVE-2021-45105.</p>
                                <p className="text">************************</p>
                                <p className="text">***UPDATE DECEMBER 20TH 2021 ***</p>
                                <p className="text">The Apache Foundation has disclosed a new 2021-45105 relating to a Denial-of-Service attack using the log4j library. Iseehear Inc. is currently evaluating if this new CVE will impact any of its products. Further updates will be provided if necessary. This CVE relates to a DoS attack not a Remote Code execution vulnerability which was disclosed in the earlier CVEs.</p>
                                <p className="text">************************</p>
                                <p className="text">***UPDATE DECEMBER 14TH 2021 ***</p>
                                <p className="text">On the 14th of December 2021, it was determined that the patch provided by the Apache Foundation for CVE-2021-44228 was not completely effective. CVE-2021-45046 was assigned to address the new denial of service vulnerability that affects log4j version 2.15.</p>
                                <p className="text">Iseehear Inc. IT Security has evaluated this new CVE and has determined that there are no impacts to Iseehear Inc. applications.</p>
                                <p className="text">************************</p>
                                <p className="text">***UPDATE DECEMBER 13TH 2021 ***</p>
                                <p className="text">In the wake of the discovery and public disclosure of the Apache Log4j 2 utility (CVE-2021-44228) security breach Iseehear Inc. IT Security immediately launched a review last Friday December 10th 2021 of all the SoftMouseNET Platform applications.</p>
                                <p className="text">An Iseehear Inc. IT Security investigation to determine whether there was any impact to the SoftMouseNET Platform and by extension our customers was conducted.</p>
                                <p className="text">Iseehear Inc. IT Security reached out to our third party security consultants for advice and suggestions to determine if Iseehear Inc. servers or applications were vulnerable. Advice and suggestions were provided.</p>
                                <p className="text">At this time, we wish to inform you that no CVE-2021-44228 vulnerabilities were found nor detected with the SoftMouseNET Platform.</p>
                                <p className="text">The Iseehear Inc. IT Security team continues to monitor the situation closely and we will continue to provide updates as soon as we know if there is an evolving threat regarding CVE-2021-44228.</p>
                                <p className="text">************************</p>
                                <p className="text">*** NOTICE DECEMBER 10TH 2021 ***</p>
                                <p className="text">A vulnerability (CVE-2021-44832) has been reported on the 10th of December, 2021 in the Java logging library (log4j) in versions 2.0.0 up to version 2.14.1.</p>
                                <p className="text">The Iseehear Inc. IT Security and Application Development Teams are actively reviewing which Iseehear Inc. products are impacted. As we assess potential impact and remediation, if any, we will update this notice.</p>
                                <p className="text">Please follow this notice for the latest updates.</p>
                                <p className="text">If an Iseehear Inc. application is impacted and remediation is needed a separate link to remediation steps will be posted in this notice.</p>
                                <p className="text">Iseehear Inc. IT Security will continue to monitor the (CVE-2021-44832) situation.</p>
                                <p className="text">More details surrounding the CVE can be found in the following 3rd party links:</p>
                                <a className="text" href="https://blog.qualys.com/" target="_blank" rel="noreferrer"><p className="textLink">https://blog.qualys.com/</p></a>
                                <a className="text" href="https://www.cisa.gov/uscert/ncas/current-activity" target="_blank" rel="noreferrer"><p className="textLink">https://www.cisa.gov/uscert/ncas/current-activity</p></a>
                                <a className="text" href="https://www.lunasec.io/docs/blog/" target="_blank" rel="noreferrer"><p className="textLink">https://www.lunasec.io/docs/blog/</p></a>
                                <p className="text">Thank you</p>
                                <p className="text">Iseehear Inc. Life Sciences</p>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>
        </section>
            <Footer />
        </div>
    )
}