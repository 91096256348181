import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { HashLink as A } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

export const FreeTrialPolicy = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Free Trial Policy</title>
                <meta name="description" content="The FREE TRIAL period begins once your account is activated. Access to the FREE TRIAL account is provided solely for the purpose of evaluation for potential subscription to the FOREVER FREE or PREMIUM services.." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="freeTrialPolicy">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Free Trial Policy</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                        </div>
                    </div>

                    <div className="polisy-summary">
                        <p className="text">The FREE TRIAL period begins once your account is activated. Access to the <b>FREE TRIAL</b> account is provided solely for the purpose of evaluation for potential  subscription to the <b>FOREVER FREE</b> or <b>PREMIUM</b> services..</p><br /><br />
                        <p className="text">Please read the <b>FREE TRIAL Policy</b> carefully so that you understand our policies and practices.</p><br /><br />
                        <p className="text">By using the FREE TRIAL account, you signify your acceptance of this policy as well as <Link className="textLink" to="/privacyPolicy">Privacy Policy</Link> and <Link className="textLink" to="/termsOfUse">Terms of Use</Link>. If you do not agree to this policy, please do not use our FREE TRIAL account. Your continued use of the FREE TRIAL account following the posting of changes to this policy will be deemed your acceptance of those changes. The FREE TRIAL Policy, may be amended from time to time with or without advance notice. You acknowledge and agree that it is your responsibility to review this FREE TRIAL Policy periodically and become aware of modifications.</p><br /><br />
                    </div>

                    <div className="google-list">
                        <A className="text" to="#about-free-trial">About FREE TRIAL</A><br />
                        <A className="text" to="#beta-site-agreement">Iseehear Beta Site Agreement</A><br />
                        <A className="text" to="#free-trial-registration-process">FREE TRIAL Registration Process</A><br />
                        <A className="text" to="#activation-period">FREE TRIAL Activation Period</A><br />
                        <A className="text" to="#rights-of-termination">Iseehear Rights of Termination</A><br />
                        <A className="text" to="#support-desk">Iseehear Support Desk</A><br />
                    </div>

                    <div id="about-free-trial" className="subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <span className="caption">About FREE TRIAL</span><br /><br />
                        <p className="text">The FREE TRIAL allows researchers to evaluate the usefulness and benefits of Iseehear database and software offerings prior to committing to an annual subscription.</p>
                    </div>

                    <div id="beta-site-agreement" className="subscription-list">
                        <span className="caption">Iseehear Beta Site Agreement</span><br /><br />
                        <p className="text">Acceptance of the Iseehear Beta Site Agreement is compulsory for access to an Iseehear FREE TRIAL  account and all Iseehear beta testing applications and websites.</p><br /><br />
                        <p className="text">By signing up for a FREE TRIAL account you acknowledge that you have read and agree to the <Link className="textLink" to="/privacyPolicy">Privacy Policy</Link> and <Link className="textLink" to="/termsOfUse">Terms of Use</Link>.</p><br /><br />
                        <p className="text">Please review the <Link className="textLink" to="/betaSiteAgreement">Iseehear Beta Site Agreement</Link>.</p>
                    </div>

                    <div id="free-trial-registration-process" className="subscription-list">
                        <span className="caption">FREE TRIAL Registration Process</span><br /><br />
                        <h3 className="subCaption">Note: <span className="text">After you have registered for a FREE TRIAL, you will receive an email confirming your subscription and your account will be activated.</span></h3><br />
                        <h3 className="subCaption">Important: <span className="text">Please submit complete and accurate information in the Registration and Subscription process.</span></h3><br />
                        <p className="text">Your email address as well as other elements of your contact information are important tools allowing us to communicate with you in a timely manner.</p><br />
                        <p className="text">Iseehear reserves the right to contact you to discuss the potential purchase of the service. For this reason, Iseehear asks you to provide us true and accurate information that identifies who you are, your title, the institution you are employed by, and how we can contact you.</p><br />
                        <p className="text">Iseehear, we will not provide your registration information to any third parties. Iseehear will hold your registration information in strict confidence within our organization. We will only use our registrants' information for contacting them about the FREE TRIAL and Iseehear services.</p><br />
                        <p className="text">For security purposes, <span className="subCaption">only Iseehear registrants and subscribers</span> with verified email addresses and verified affiliation with the institution indicated in their registration will be granted full access to features.</p><br />
                    </div>

                    <div id="activation-period" className="subscription-list">  
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element" src={require("../../img/newPage/element.png")} alt="element" />                                
                        <span className="caption">FREE TRIAL Activation Period</span><br /><br />
                        <p className="text">The FREE TRIAL period begins once your account has been activated by us.</p><br />
                        <p className="text">You will receive an email confirming your registration and that your account has been activated.</p><br />
                        <p className="text">The activation for the FREE TRIAL will only be good for 30 days, after that you will have to contact us to discuss an extension and next steps.</p>
                    </div>

                    <div id="rights-of-termination" className="subscription-list">
                        <span className="caption">Iseehear Rights of Termination</span><br /><br />
                        <p className="text">Iseehear reserves the right, without prior notice, to suspend the availability of the FREE TRIAL to the registrant and or account user at its discretion.  Please visit our <Link className="textLink" to="/privacyPolicy">Privacy Policy</Link>, <Link className="textLink" to="/termsOfUse">Terms of Use</Link> and <Link className="textLink" to="/betaSiteAgreement">Iseehear Beta Site Agreement</Link>.</p>
                    </div>

                    <div id="support-desk" className="subscription-list">
                        <span className="caption">Iseehear Support Desk</span><br /><br />
                        <p className="text">If you have any questions about the FREE TRIAL, please feel free to contact us anytime at support@iseehear.com</p><br />
                        <p className="text">The Iseehear Support Desk is located in Toronto, Canada.</p><br />
                        <p className="text">Iseehear's customer support priority is to promptly address your research needs and requirements.</p><br />
                        <p className="text">If you are experiencing any problems with Iseehear's products and / or websites, please provide us with your feedback. Our experienced customer support team is on hand when you need them. Email: support@iseehear.com</p><br />
                        <p className="text">For Iseehear Support Desk information, please visit <a className="textLink" href="http://www.iseehear.com/support.php" target="_blank" rel="noreferrer">www.iseehear.com/support.php</a></p><br />			
                        <p className="text">Visit Iseehear Support Desk Blog: <a className="textLink" href="https://iseehearsupportdesk.blogspot.com/" target="_blank" rel="noreferrer">http://iseehearsupportdesk.blogspot.com/</a></p><br />
                        <p className="text">Visit Iseehear Support Desk on Twitter: <a className="textLink" href="https://twitter.com/IseehearSupport" target="_blank" rel="noreferrer">https://twitter.com/IseehearSupport</a></p><br />
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}