import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const DataBackup = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Data Backup</title>
                <meta name="description" content="Iseehear Inc. Life Sciences backs-up the SoftMouse database every day at a secure second location. This back-up represents a non-editable copy of all data inputted into the database up to that date. By non-editable, we mean that no SoftMouse user (including yourself or any one in your lab) can access these back-ups to change the content, i.e. the back-ups represent an actual snapshot of your colony from that time point." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="data-backup">
                    <div className="container">

                        <div className="row data-caption">
                            <div className="col-lg-9">
                                
                                <h3 className="mainCaption">User Data Backup Options</h3>
                                <hr style={{borderBottom: "2px solid lightblue"}} />
                                <h4 className="caption">Information Security Management (ISM)</h4><br />
                            </div>
                        </div>
                    

                        <div className="content-section-b" style={{paddingBottom: "20px", paddingTop: "20px"}}>                           
                            <div className="largefont">
                                <h3 className="subCaption">Iseehear Inc. Life Sciences employs multiple backup strategies to backup all of our subscribers' data:</h3><br />
                                <ol className="data_list">
                                    <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                    <li className="text">SoftMouse.NET ensures that customer data remains available through complete automated daily backups.</li>
                                    <li className="text">SoftMouse.NET customer’s also have the option to <a className="textLink" href="https://www.softmousefaq.com/Do+you+keep+a+backup+of+our+SoftMouse+data" target="_blank" rel="noreferrer">manually back their data up to a Spreadsheet or PDF</a> whenever they so desire.</li>

                                </ol>
                                <p className="text">To learn more about Iseehear Inc. Life Sciences Information Security Management <Link className="textLink" to="#">Click Here</Link></p> <br /><br />

                            </div>
                        </div>
                        <hr style={{borderBottom: "2px solid lightblue"}} />
                        <div className="content-section-b" style={{paddingBottom: "20px", paddingTop: "20px"}}>
                            <div className="row">
                                <h4 className="caption"><b>Cloud Backups</b></h4>
                                <p className="text">All subscriber data is automatically backed up.</p><br /><br />
                                <p className="text">Iseehear Inc. Life Sciences backs-up the SoftMouse database every day at a secure second location. This back-up represents a non-editable copy of all data inputted into the database up to that date. By "non-editable", we mean that no SoftMouse user (including yourself or any one in your lab) can access these back-ups to change the content, i.e. the back-ups represent an actual snapshot of your colony from that time point. By extension, with these back-ups, we can pull up a copy of your data from a previous point in time if you ever need to reference what your colony looked like exactly at a specific time point.</p><br /><br />

                                <h3 className="subCaption">We have two internal secure Cloud methods of data backup of all data inputted into the SoftMouse database:</h3>
                                <ol className="data_list">
                                    <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                                    <li className="text">A real time Cloud backup into a second database</li>
                                    <li className="text">A daily backup into a secondary Cloud location</li>
                                </ol>
                                <br />
                            </div>                        
                        </div>
                        <hr style={{borderBottom: "2px solid lightblue"}} />
                        <div className="content-section-b" style={{paddingBottom: "20px", paddingTop: "20px"}}>
                            <div className="row">
                                <h4 className="caption"><b>Manual Personal Backups</b></h4>

                                {/* <h3>Manual Backups</h3> */}
                                <h3 className="subCaption">Subscribers can execute the following manual local back up features that are included within the application and are available at all times:</h3>
                                <ol className="data_list">
                                    <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                                    <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                                    <li className="text">Print all Lists to PDF (Portable Document Format) and/or print to paper copy</li>
                                    <li className="text">You can export your data from each list by clicking the cloud with arrow icon on each main SoftMouse.NET list.   </li><br />

                                    <img className="data_img" src={require("../../img/softmouse-export-excel-0002.png")} alt="softmouse" />
                                    <p className="text">Good Data Backup Hygiene Practices Learn more <a className="textLink" href="https://www.softmousefaq.com/Do+you+keep+a+backup+of+our+SoftMouse+data" target="_blank" rel="noreferrer">here</a></p><br />
                                </ol>
                                <br />
                                <br />
                                
                            </div>                           
                        </div>
                        <hr style={{borderBottom: "2px solid lightblue"}} />
                        <div className="content-section-b" style={{paddingBottom: "20px", paddingTop: "20px"}}>                         
                            <div className="row">
                                <h4 className="caption"><b>Audit Log</b></h4>
                                <p className="subCaption">We also have an audit log that traces all updates made to your data, including:</p>
                                <ol className="data_list">
                                    <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                    <li className="text">what change was made (old value and new value),</li>
                                    <li className="text">which user made the change, </li>
                                    <li className="text">on what date and what time. </li>
                                </ol>

                                <br /><br /><br />
                            </div>                   
                        </div>
                    </div>    
            </section>
            <Footer />
        </div>
    )
}