import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const PasswordManagement = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Password Management</title>
                <meta name="description" content="How to change password? When logged in to your SoftMouse.NET account: Follow these steps to change your password at any time:" />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="password-management">
                <div className="container">

                    <div className="row password_caption">
                                <div className="col-lg-9">     
                                    <h3 className="mainCaption">SoftMouse.NET Password Management</h3>
                                    <hr style={{borderBottom: "2px solid lightblue"}} />
                                    <h4 className="caption">Information Security Management (ISM)</h4><br />
                                </div>
                    </div>
                   
                    <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px;"}}>

                                <div className="largefont">
                                    <div id="message">
                                        <h3 className="subCaption">Your password must contain:</h3>
                                        <ol className="password_list">
                                            <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                            <li className="text">A <b>lowercase</b> letter a, b, c, d, … x, y, z</li>
                                            <li className="text">A <b>capital (uppercase)</b> letter A, B, C, D, … X, Y, Z</li>
                                            <li className="text">A <b>number</b> 0, 1, 2, 3, 4, 5, 6, 7, 8, 9</li>
                                            <li className="text">The  length of your password should be <b>minimum 8</b> characters</li>
                                            <li className="text">Special <b> characters</b> !, @, #, $, %, ^, &, *, ?, _, ~</li>
                                        </ol>
                                    </div>
                                    <h3 className="subCaption">How to create password?</h3>
                                    <p className="text">Go to the <a href="https://softmouse.net/registration.do" target="_blank" rel="noreferrer">registration</a> page and follow the sign up process.</p>
                                </div>
                                <div className="col-lg-12 create_password">
                                    <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                    <img className="img-responsive" src={require("../../img/softmouse-password-policy.png")} alt="softMouse" />
                                    <br /><br />
                                </div>
                    </div>
                    <hr style={{borderBottom: "2px solid lightblue"}} />
                    <div className="row">
                                <div className="col-lg-12 largefont">
                                    <h3 className="caption extracaption">How to change password?<br />When logged in to your SoftMouse.NET account:</h3>

                                    <h2 className="subCaption">Follow these steps to change your password at any time:</h2>
                                    <ol className="password_list">
                                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                                        <li className="text">Click the Home icon to go to the Administration part of your account</li>
                                        <li className="text">Click the My Profiled Menu</li>
                                        <li className="text">Scroll down to the Change Password section</li>
                                        <li className="text">Enter your current password</li>
                                        <li className="text">Enter your new password   --- for optimal security, we recommend an 8 character combination of letters and numbers with at least one capital letter</li>
                                        <li className="text">Re-enter your new password</li>
                                        <li className="text">Click Save</li>
                                    </ol><br /><br />
                                </div>
                                <div className="col-lg-12">

                                    <img src={require("../../img/softmouse-change-password.png")} alt="softMouse" className="img-responsive" />
                                    <br /><br /><br /><br />
                                </div>
                    </div>
                    <hr style={{borderBottom: "2px solid lightblue"}} />
                    <div className="row">
                                <div className="forgot_password">
                                    <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                    <div className="password_description">                               
                                        <h3 className="caption extracaption">Forgot Password?<br />How to login when you forgot your password?</h3>
                                        <h2 className="subCaption">Quickly reset your password as follows:</h2>

                                        <ul className="password_list">
                                            <li className="text">Go to www.SoftMouse.NET</li>
                                            <li className="text">In the login box, click the link "Forgot your password?"</li><br /><br />
                                        </ul>                    
                                    </div>
                                    <img className="forgotImage" src={require("../../img/softmouse-forgot-password.png")} alt="softMouse" />
                                </div>
                                <div className="forgot_password reverse">
                                    <img className="forgotImage" src={require("../../img/softmouse-forgot-password-submit.png")} alt="softMouse" />
                                    <div className="password_description">
                                        <ul className="password_list">
                                            <li className="caption extracaption">In the pop up box, fill in the fields and click Submit</li><br /><br />
                                        </ul>
                                        <ul className="password_list">
                                            <li className="text">Check your email for the new temporary password</li>
                                            <li className="text">Login to your account and reset your password in the My Profile page</li>
                                        </ul>
                                    </div>
                                </div>    
                                <div className="col-lg-8 largefont">

                                </div>
                                <div className="col-lg-4">

                                    <br />
                                </div>
                    </div>  
                </div>
            </section>
            <Footer />
        </div>
    )
}