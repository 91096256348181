import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { HashLink as A } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

export const TermsOfUse = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Terms Of Use</title>
                <meta name="description" content="These are our terms and conditions for use of the Site, which you may access in several ways, including but not limited to the World Wide Web, Internet enabled television, Computer, Intranet, Cellular phone and wireless mobile device." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="terms-of-use">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Terms Of Use</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                        </div>
                    </div>

                    <div className="polisy-summary">
                        <p className="text">Effective Date: May 25, 2018 This Privacy Policy may be amended from time to time.</p><br /><br />
                        <img src={require("../../img/iseehear-logo.png")} alt="logo" className="policiLogo" /><br /><br />
                        <p className="text">Iseehear Inc. Life Sciences (“Iseehear”, “we”, “us”, and “our”).</p><br /><br />
                    </div>

                    <div className="google-list anchorLink">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                        <div className="anchor-link">
                            <A className="text" to="#TermsConditions">Website Terms & Conditions (Iseehear.com)</A><br />
                            <A className="text" to="#ChangestoTermsConditions">Changes to these Terms & Conditions </A><br />
                            <A className="text" to="#SubscriptionandRegistration">Subscription and Registration </A><br />
                            <A className="text" to="#CommunicationswithThirdParties">Communications with Third Parties</A><br />
                            <A className="text" to="#Indemnity">Indemnity</A><br />
                            <A className="text" to="#DataProtection">Data Protection </A><br />
                            <A className="text" to="#OtherWebsites">Other Websites </A><br />
                            <A className="text" to="#Severability">Severability</A><br />
                            <A className="text" to="#GoverningLaw">Governing Law </A><br />      
                        </div>
                        <div>
                            <Link className="text pull-right" to="/documents/Terms-and-Conditions-Iseehear-Com.pdf" target="_blank" rel="noreferrer">Download PDF <img src={require("../../img/SoftMouse_download_black.png")} alt="softmouse" /></Link><br />
                        </div>
                    </div>

                    <div id="TermsConditions" className="subscription-list">
                        <span className="caption">Website Terms & Conditions (Iseehear.com)</span><br /><br />
                        <p className="text">Iseehear.com ("the Site") is a website developed by Iseehear Inc. Life Sciences. By entering the Site or any of its associated network websites you are deemed to have accepted these terms and conditions.</p><br /><br />
                        <p className="text"> These are our terms and conditions for use of the Site, which you may access in several ways, including but not limited to the World Wide Web, Internet enabled television, Computer, Intranet, Cellular phone and wireless mobile device. These terms and conditions apply whenever you access the Site, on whatever device. In these terms and conditions, when we say Iseehear.com we mean this Site or any of its associated network websites, regardless of how you access it. By using the Site, you are deemed to have accepted these conditions.</p><br /><br />
                    </div>

                    <div id="ChangestoTermsConditions" className="subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <span className="caption">Changes to these Terms & Conditions </span><br /><br />
                        <p className="text">Iseehear.com can change these Terms at any time, without notice, and your continued use of the Services after any change we make will mean that you agreed with the changes. IN THE EVENT THAT YOU DO NOT AGREE TO ANY CHANGE, PLEASE DO NOT ACCESS OR USE THE SERVICES IN ANY MANNER FOR ANY PURPOSE.</p><br /><br />
                    </div>

                    <div id="SubscriptionandRegistration" className="subscription-list">
                        <span className="caption">Subscription and Registration </span><br /><br />
                        <p className="text">If you become a Subscriber of Iseehear.com, you accept responsibility for all activities that occur under your account or password, and agree not to sell, transfer or assign your subscription or any subscription rights. You are responsible for maintaining the confidentiality of your password and for restricting access to your computer so others may not access Iseehear.com or any of its associated network websites using your name in whole or in part. Iseehear Inc. Life Sciences reserves the right to terminate your subscription and deny access to the site to any person who violates the terms and conditions of the <Link className="textLink" to="/subscriptionAgreement">Iseehear Inc. Subscription Agreement</Link>. By completing the Profile registration form and by entering your email address and password, you will be deemed to have accepted the terms and conditions of the Iseehear Inc. Subscription Agreement. Also, by subscribing to any of the Site’s online services (including but not limited to: email, surveys), you are deemed to have accepted these terms and conditions. During the Profile registration process, you agree to give us true, accurate and complete information about yourself, and to update this information when it changes.</p><br /><br />
                        <p className="text">If you do not update it, Iseehear Inc. Life Sciences may suspend or terminate your use of the Site or any of its associated network websites. Any personal information that you provide to us is subject to the terms of our <Link className="textLink" to="/privacyPolicy">Privacy Policy</Link>. When you register, you will choose a password so that you can access your account with us. You agree that you will keep this information confidential. You are completely responsible for activities that happen under your account and password. If you think there has been unauthorised use of your account or password, you agree immediately to notify Iseehear Inc. Life Sciences and co-operate with us to resolve it. You understand that if you do not follow the terms of this paragraph or the terms of <Link className="textLink" to="/subscriptionAgreement">Iseehear Inc. Subscription Agreement</Link>, we may suspend or terminate your use of this Site and or associated network websites. You will not do anything that would assist anyone who is not a registered user to gain access to any registration required area of the Site. You do not maliciously create additional registration accounts for the purpose of abusing the functionality of the Site, or other users; nor do you seek to pass yourself off as another user.  Please familiarize yourself with the <Link className="textLink" to="/subscriptionAgreement">Iseehear Inc. Subscription Agreement</Link>.</p><br /><br />
                    </div>

                    <div id="CommunicationswithThirdParties" className="subscription-list">
                        <span className="caption">Communications with Third Parties </span><br /><br />
                        <p className="text">Your dealings or communications through Iseehear.com with any party other than Iseehear Inc. Life Sciences are solely between you and that third party. For example, certain areas of Iseehear.com may allow you to conduct transactions or purchase goods or services. In most cases, our third-party partners and vendors will conduct these transactions. Under no circumstances will Iseehear Inc. Life Sciences be liable for any goods, services, resources or content available through such third party dealings or communications, or for any harm related thereto. Please review carefully that third party’s policies and practices and make sure you are comfortable with them before you engage in any transaction. Complaints, concerns or questions relating to materials provided by third parties should be forwarded directly to the third party.</p><br /><br />
                    </div>

                    <div id="Indemnity" className="subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <span className="caption">Indemnity</span><br /><br />
                        <p className="text">You understand that you are personally responsible for your behavior while on the Iseehear.com and associated Sites, and agree to indemnify and hold Iseehear Inc.Life Sciences and subsidiaries, affiliates, and their officers, directors, employees and agents harmless from and against any loss, damage, liability, cost or expense of any kind (including legal fees and costs) that we may incur in connection with a third party claim or otherwise, in relation to your use of the Services, access to the Iseehear.com and associated Sites, your violation of either these Terms or the rights of any third party, disclosure of subscriber passwords and any other matter in connection with your participation in the services on the site.</p><br /><br />
                        <p className="text">You agree to use the Site only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else’s use and enjoyment of the Site. Prohibited behavior includes harassing or causing distress or inconvenience to any person, transmitting obscene or offensive content or disrupting the normal flow of dialogue within the Site.</p><br /><br />
                        <p className="text">Iseehear Inc. Life Sciences can terminate your access to the Site or the Services at any time.</p><br /><br />
                    </div>

                    <div id="DataProtection" className="subscription-list">
                        <span className="caption">Data Protection </span><br /><br />
                        <p className="text">If you have indicated on the Site’s Profile registration form that you wish to receive marketing material from Iseehear Inc. Life Sciences, your registration details will be included in a database compiled for Iseehear Inc. Life Sciences marketing purposes exclusively. Iseehear Inc. Life Sciences will not share or provide your registration details to any third parties. At certain times you may receive email correspondence from us related to the secure provisioning of the Iseehear.com service.</p><br /><br />
                        <p className="text">If you have indicated that you wish to receive marketing material or email updates from Iseehear Inc. Life Sciences but subsequently change your mind, you should send an email to unsubscribe@iseehear.com. This will immediately remove you from any future mailings. Please visit Iseehear Inc. Life Sciences <Link className="textLink" to="/emailPolicy">Email Policy</Link> for further information.</p><br /><br />
                        <p className="text">Iseehear.com does not warrant that functions contained in the Site content will be uninterrupted or error free, that defects will be corrected, or that Iseehear.com or the server(s) that makes it available are free of viruses or bugs. That said Iseehear Inc. Life Sciences monitors for viruses, bugs, unusual behavior and will take remedial action to ensure the Site is safe and secure. Learn more about how Iseehear Inc. Life Sciences manages <Link className="textLink" to="/securityOverview">Information Security</Link>.</p><br /><br />
                    </div>

                    <div id="OtherWebsites" className="subscription-list">
                        <span className="caption">Other Websites </span><br /><br />
                        <p className="text">Iseehear.com website contains links to third party websites. Iseehear Inc. Life Sciences is not responsible for the privacy practices or the content of third party websites. Links to third party websites have this red colour icon. <img src={require("../../img/external-link-red.png")} alt="link" /></p><br /><br />
                        <p className="text">If you operate a Web site and wish to link to Iseehear.com, you may do so provided you agree to cease such link upon request from Iseehear Inc. Life Sciences. No other use is permitted without prior written permission of Iseehear Inc. Life Sciences. The permitted use described in this Paragraph is contingent on your compliance at all times with all Iseehear Inc. Life Sciences Terms of Service Policies.</p><br /><br />
                    </div>

                    <div id="Severability" className="subscription-list">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                        <span className="caption">Severability</span><br /><br />
                        <p className="text">If any one or more provisions of this Agreement are determined to be invalid or illegal or unenforceable in any respect, the remaining provisions of this Agreement shall remain in effect and shall not be affected by such invalidity, illegality or unenforceability.</p><br /><br />
                    </div>

                    <div id="GoverningLaw" className="subscription-list">
                        <span className="caption">Governing Law</span><br /><br />
                        <p className="text">This Agreement shall be governed by and construed and enforced in accordance with the laws of the Province of Ontario and the laws of Canada applicable therein without regard to its choice of law provisions and will be treated as an Ontario contract.</p><br /><br />
                        <p className="text">You hereby agree that any cause of action you may have with respect to Iseehear.com must be filed in a provincial court located in Toronto, Ontario within two months of the time in which the events giving rise to such claim began, or you agree to waive such claim. If for any reason any provision of this Agreement is found unenforceable, that provision shall be enforced to the maximum extent permissible so as to affect the intent of the parties as reflected in that provision and the remainder of the Agreement shall continue in full force and effect. Any failure of Iseehear.com to enforce or exercise any provision of this Agreement or related right shall not constitute a waiver of that right or provision. The section titles used in the Terms of Use Policies are purely for convenience and carry with them no legal or contractual effect. In the event of termination of this Agreement for any reason, you agree the following provisions will survive: the provisions regarding limitations on your use of Content, the license(s) you have granted to Iseehear Inc. Life Sciences, and all other provisions for which survival is equitable or appropriate.</p><br /><br />
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}