import React from 'react'
// import { useEffect } from 'react'
// import axios from "axios";
import { useContext } from 'react';
import GeneralContext from '../../Context/GeneralContext';

export const FetchDataSections = () => {
    const {sections} = useContext(GeneralContext);
    return (
        <>
            {sections.map((section) => (
                <option id={section.section_name} key={section.id} className="text" value={section.id}>{section.section_name}</option>
           ))}
        </>
    )
}