import React from 'react';
import { Component } from 'react';
import {FetchDataSections} from "../FetchData/FetchDataSections";
import axios from "axios";
import QuestionContext from '../../Context/GeneralContext';
import {POST_HECVAT} from "../ApiURL";

export default class QuestionForm extends Component {

    static contextType = QuestionContext

    state = {
        question: "",
        answer_boolean: "",
        answer: "",
        section_id: "",
        created_at: "",
        updated_at: "",
        link: "",
        sent: false
    }

        // handle inputs Questions and Answers
        handleQuestion=(e)=>{
            this.setState({
                question:e.target.value
            })
        }
    
        handleAnswer_boolean=(e)=>{
            this.setState({
                answer_boolean:e.target.value
            })
        }
    
        handleAnswer=(e)=>{
            this.setState({
                answer:e.target.value
            })
        }
    
        handleSection_id=(e)=>{
            this.setState({
                section_id:e.target.value
            })
        }
    
        handleCreated_at=(e)=>{
            this.setState({
                created_at:e.target.value
            })
        }

        handleUpdated_at=(e)=>{
            this.setState({
                updated_at:e.target.value
            })
        }

        handleLink=(e)=>{
            this.setState({
                link:e.target.value
            })
        }

        formSubmit=(e)=>{
            e.preventDefault();
    
            let data = {
                question:this.state.question,
                answer_boolean:this.state.answer_boolean,
                answer:this.state.answer,
                section_id:this.state.section_id,
                created_at:this.state.created_at,
                updated_at:this.state.updated_at,
                link:this.state.link,
            }

            function ErrorMessage() {
                let questionsForm = document.querySelector('.formGroup');
                let element = document.createElement('p');
                element.textContent = "<p class='hideMsg'>Form Not Sent to DB</p>";
                questionsForm.insertAdjacentHTML('beforebegin', element.textContent);
            }
    
            axios.post(POST_HECVAT, data)
            .then(res => {
                this.setState({
                    sent: true,
                }, this.resetForm())
            })
            .catch(() => {
                ErrorMessage()
                this.resetForm()
            })

            this.context.forceUpdate()
        }

            // form Reseting initial data
        resetForm = () => {
            this.setState({
                question: "",
                answer_boolean: "",
                answer: "",
                section_id: "",
                created_at: "",
                updated_at: "",
                link: "",
            })

            setTimeout(() => {
                this.setState({
                    sent: false,
                })
            }, 3000)
        }

  render() {
    return (
        <form className="adminForm" onSubmit={this.formSubmit}>
        <h2 className="caption">Create a New Question and Answer</h2>
        { this.state.sent ? <p className="hideMsgSuccess"> Form Has Been Sent to DB</p> : <p></p>} 
        <fieldset className="formGroup">
            <div className="inputBlock">
                <label className="textCaption" htmlFor="question">Enter The Qestion</label>
                <textarea 
                    className="textArea text" 
                    type="text" 
                    id="question" 
                    name="question"
                    placeholder='Question...'
                    onChange={this.handleQuestion}
                    value={this.state.question}
                />
            </div>
            <div className="inputBlock">
                <label className="textCaption" htmlFor="boolean">Choose the Answer</label>
                <select 
                    className="select text" 
                    type="select" 
                    id="boolean" 
                    name="boolean"
                    onChange={this.handleAnswer_boolean}
                    value={this.state.answer_boolean}
                >
                    <option style={{borderBottom: "2px solid lightblue"}} className="textCaption">Choose one of the options</option>
                    <option className="text">Yes.</option>
                    <option className="text">No.</option>
                </select>
            </div>
            <div className="inputBlock">
                <label className="textCaption" htmlFor="answer">Enter The Answer</label>
                <textarea 
                    className="textArea text" 
                    type="text" 
                    id="answer" 
                    name="answer" 
                    placeholder='Answer...'
                    onChange={this.handleAnswer}
                    value={this.state.answer}
                />
            </div>
            <div className="inputBlock">
                <label className="textCaption" htmlFor="link">Put The Link</label>
                <textarea 
                    className="textArea text" 
                    type="text" 
                    id="link" 
                    name="link" 
                    placeholder='Optional'
                    onChange={this.handleLink}
                    value={this.state.link}
                />
            </div>
            <div className="inputBlock">
                <label className="textCaption" htmlFor="section">Choose the Section</label>
                <select 
                    className="select text" 
                    type="select" 
                    id="section" 
                    name="section"
                    onChange={this.handleSection_id}
                    value={this.state.section_id}
                >
                    <option style={{borderBottom: "2px solid lightblue"}} className="textCaption">Choose one of the options</option>
                    <FetchDataSections />
                </select>
            </div>
            <div className="inputBlock">
                <label className="textCaption" htmlFor="create">Create (date and time):</label>
                <input 
                    className="textArea text" 
                    type="datetime-local" 
                    id="create" 
                    name="create" 
                    onChange={this.handleCreated_at}
                    value={this.state.created_at}
                />
            </div>
            <div className="inputBlock">
                <label className="textCaption" htmlFor="update">Update (date and time):</label>
                <input 
                    className="textArea text" 
                    type="datetime-local" 
                    id="update" 
                    name="update" 
                    onChange={this.handleUpdated_at}
                    value={this.state.updated_at}
                />
            </div><br />
            <button type="submit" className="button">Create Post</button>
        </fieldset>
    </form>
    )
  }
}