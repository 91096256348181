import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const MalwareDetection = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Malware Detection</title>
                <meta name="At Iseehear, we take your security seriously. SoftMouse.NET undergoes daily anti-malware scans to detect and prevent malicious code or activity. Powered by GeoTrust, this process offers regular checks for malware, instant alerts for swift removal, and protection against blacklisting, ensuring your online experience is secure.
                    In addition, Iseehear conducts regular automated Web Application Scanning for malware detection. The results are thoroughly analyzed by both our internal team and third-party security consultants who conduct a comprehensive risk assessment and initiate response and remediation actions as needed." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="malware_detection">
                <div className="container">

                    <div className="row malware-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Malware Detection</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                            <h4 className="caption">Information Security Management (ISM)</h4><br />
                        </div>
                    </div>
                </div>

                <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px"}}>
                    <div className="container">
                        <div className="largefont">

                            <span>
                                <p className="text">Regular anti-malware scans are performed on SoftMouse.NET in order to detect for the presence of malicious code and to monitor potential malicious activity.</p>
                            <br /><br />
                                <p className="text">Iseehear conducts regular automated Web Application Scanning for malware detection and the results by both our internal team and third party security consultants who conduct a risk assessment and initiate response and remediation action as needed.</p>
                            </span>

                        </div>
                    </div>
                </div>
                <hr style={{borderBottom: "2px solid lightblue"}} />
                <div className="content-section-b" style={{paddingBottom: "20px", paddingTop: "20px"}}>
                    <div className="container">
                        <div className="largefont">

                            <h4 className="caption"><b>Benefits of Anti-malware scanning on SoftMouse.NET</b></h4>
                            <ol className="malware_list">
                                <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                <li className="text">Daily anti-malware scan for regular check-ups to assure our visitors that site has been scanned for malware.</li>
                                <li className="text">The malware scan includes analysis of web page for identifiable malicious code plus monitoring to identify malicious activity to enable easy clean-up if the site is infected.</li>
                                <li className="text">Instant alerts identifying malicious code to enable fast malware removal.</li>
                                <li className="text">On-demand scans to enable quick confirmation of clean site status.</li>
                                <li className="text">Malware scan also protects SoftMouse.NET web site traffic by avoiding blacklisting by browsers and search engines.</li>
                                <li className="text">Geotrust anti-malware seal is placed at the bottom of each SoftMouse.NET web page to assure site visitors that the site is clean of malware.</li>
                                <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                            </ol>
                            <br />

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}