import React from "react";
import { useContext } from "react";
import GeneralContext from "../../Context/GeneralContext";

export const ButtonGroup = ({ openEditButton, questionData }) => {
  const { onRemoveItem, forceUpdate } = useContext(GeneralContext);

  return (
    <div className="buttonGroup">
      <button
        onClick={() => {
          onRemoveItem(questionData.id);
          forceUpdate();
        }}
        type="button"
        className="btn btn-danger"
      >
        Delete
      </button>
      <button
        onClick={openEditButton}
        type="button"
        className="btn btn-primary"
      >
        Edit
      </button>
    </div>
  );
};
