import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { DataSectionRender } from "../FetchData/DataSectionRender";

export const GeneralInformation = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>General Information</title>
                <meta name="description" content="General Information" />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="generalInformation">
                    <div className="container">

                        <div className="row security_caption">
                            <div className="col-lg-9 elemetn_mash">
                                <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                <h3 className="mainCaption">General Information</h3>
                                <hr style={{borderBottom: "2px solid lightblue"}} />
                           </div>
                        </div>

                        <div id="generalInformation" className="content-section-b">
                            <div className="largefont">
                                <h4 className="textCaption fw-bold">Corporate Information</h4><br />
                                <p className="text"><span className="text fw-bold">Vendor Name:</span> Iseehear Inc. Life Sciences</p>
                                <p className="text"><span className="text fw-bold">Vendor Corporate Address:</span> 250 University Avenue, Suite 200, Toronto, Ontario, Canada M5H 3E5</p>
                                <p className="text"><span className="text fw-bold">Vendor Contact Phone Number:</span> 416-492-4358</p>
                                <p className="text"><span className="text fw-bold">Vendor Toll Free:</span> 1 844 559 6322 (United States & Canada)</p>
                                <p className="text"><span className="text fw-bold">Vendor Customer Service:</span> support@Iseehear.com</p>
                                <p className="text"><span className="text fw-bold">Vendor Corporate Website:</span> www.Iseehear.info</p>
                            </div>
                        </div>

                        <hr style={{borderBottom: "2px solid lightblue"}} />

                        <div id="securityContactInformation" className="content-section-b">
                            <div className="encryption_overview">
                                <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                                <h2 className="textCaption fw-bold">Security Contact Information</h2>
                                <p className="text"><span className="text fw-bold">Vendor Security Contact Name:</span> ITSecurity</p>
                                <p className="text"><span className="text fw-bold">Vendor Security Email Address:</span> itsecurity@Iseehear.com</p>
                                <p className="text"><span className="text fw-bold">Vendor Security Website:</span> www.Iseehearsecurity.com</p>
                            </div>                    
                        </div>

                        <hr style={{borderBottom: "2px solid lightblue"}} />

                        <div id="productInformation" className="content-section-b">
                            <div className="encryption_overview">
                                <h2 className="textCaption fw-bold">Product Information</h2>
                                <p className="text"><span className="text fw-bold">Product Name:</span> SoftMouse.NET</p>
                                <p className="text"><span className="text fw-bold">Product Website:</span> www.SoftMouse.NET</p>
                                <p className="text"><span className="text fw-bold">Product Description:</span> Animal Colony Management System</p>
                                <p className="text"><span className="text fw-bold">Product Manual:</span> www.softMouseFAQ.com</p>
                                <p className="text"><span className="text fw-bold">Product Tutorials:</span> www.softMouseTraining.com</p>
                                <p className="text"><span className="text fw-bold">Product SandBox:</span> Please contact customer service or ITSecurity for details</p>
                            </div>                    
                        </div>

                        <hr style={{borderBottom: "2px solid lightblue"}} />

                        <DataSectionRender />
                    </div>
            </section>
            <Footer />
        </div>
    )
}