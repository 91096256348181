import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const Whois = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>WHOIS Verification & Authentication</title>
                <meta name="description" content="We employ tools and techniques to monitor events on SoftMouse.NET, detect attacks, and provide identification of unauthorized use of the system. For example we use vulnerability scans as well as server, database and network monitoring." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="whois">

                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">WHOIS Verification & Authentication</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                            <h4 className="caption">Information Security Management (ISM)</h4><br />
                        </div>
                    </div>


                    <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px"}}>

                            <div className="row">


                                <p className="text">We employ tools and techniques to monitor events on SoftMouse.NET, detect attacks, and provide identification of unauthorized use of the system. For example we use vulnerability scans as well as server, database and network monitoring.</p>

                                <br />
                                    <h4 className="subCaption"><b>Whois Verification for SoftMouse.NET Website Ownership</b></h4>

                                <ol className="data_list">
                                    <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                                    <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                                    <li className="text">Click any one of the Domain Name Ownership Whois resource links below. </li>
                                    <li className="text">Type in SoftMouse.NET.</li>
                                    <li className="text">The SoftMouse.NET ownership <span className="textSpanBlue">contact information</span> should correspond with the contact information hosted on this web page (website).</li>
                                    <li className="text">If the SoftMouse.NET <span className="textSpanBlue">contact ownership information</span> does not correspond to the information on this page, please abandon this website and <Link className="textLink" to="/contactForm" rel="nofollow">contact Iseehear</Link> immediately.</li>
                                </ol>

                            </div>
                    </div>
                    <hr style={{borderBottom: "2px solid lightblue"}} />
                    <div className="content-section-b" style={{paddingBottom: "20px", paddingTop: "20px"}}>

                        <div className="row">
                                    <h4 className="caption"><b>Domain Name Ownership Whois resources</b></h4>
                            <p className="text">Please visit the domain name registration registrars below and enter SoftMouse.NET into the WHOIS search box in order to confirm the SoftMouse.NET domain name ownership registration information.</p>
                            <br /><br />
                            <div className="col-lg-12">
                                <p className="text"><b>1. Internet Corporation for Assigned Names and Numbers (ICANN):</b>
                                <a className="textLink" href="http://whois.icann.org/en" target="_blank" rel="noreferrer" style={{textDecoration: "underline"}}>ICANN Whois&nbsp;&nbsp;<img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />
                            </div>



                            <div className="col-lg-12">
                                <h2 className="subCaption">Click the "Image 1-A" to view the full ownership registration record for SoftMouse.NET:</h2>
                                <br />
                                <a href="img/softmouse_NET-icann-whois-full-information-0001.pdf" target="_blank" className="pull-left"><img src={require("../../img/softmouse_NET-icann-whois-0001.jpg")} alt="softmouse" className="img-responsive img-thumbnail" /></a>

                            </div>

                            <div className="col-lg-12">
                                <br /><br />
                                <h4 className="caption"><b>2. GoDaddy Operating Company, LLC:</b>
                                <p className="text"><a className="textLink" href="https://who.godaddy.com/" target="_blank" rel="noreferrer" style={{textDecoration: "underline"}}>Godaddy Whois&nbsp;&nbsp;<img src={require("../../img/external-link-red.png")} alt="link" /></a></p></h4><br /><br />
                            </div>
                            <div className="col-lg-12">
                                <h3 className="subCaption">Click the "Image 2-A" to view the full ownership registration record for SoftMouse.NET:</h3>
                                <br />
                                <a href="img/softmouse_NET-godaddy-whois-full-information-0001.pdf" target="_blank" rel="noreferrer" className="pull-left"><img src={require("../../img/softmouse_NET-godaddy-whois-0001.jpg")} alt="softmouse" className="../../img-responsive img-thumbnail" /></a>

                            </div>
                            <div className="col-lg-12"><br /><br />
                                <h4 className="caption"><b>3. Uniregistry Corp:</b>
                                <p className="text"><a className="textLink" href="https://uniregistry.com/whois" target="_blank" rel="noreferrer" style={{textDecoration: "underline"}}>Uniregistry Whois&nbsp;&nbsp;<img src={require("../../img/external-link-red.png")} alt="link" /></a></p></h4>
                                <br /><br />
                            </div>
                            <div className="col-lg-12">
                                <h3 className="subCaption">Click the "Image 2-A" to view the full ownership registration record for SoftMouse.NET:</h3>
                                <br />
                                <a href="img/softmouse_NET-uniregistry-whois-full-information-0001.pdf" target="_blank" className="pull-left"><img src={require("../../img/softmouse_NET-uniregistry-whois-0001.jpg")} alt="softmouse" className="img-responsive img-thumbnail" /></a>

                            </div>

                            <br /><br />
                        </div>
                    </div>
                    <hr style={{borderBottom: "2px solid lightblue"}} />
                    <div className="content-section-b" style={{paddingBottom: "20px", paddingTop: "20px"}}>
                            <div className="row">
                                <h4 className="caption">Additional Trusted<img src={require("../../img/trusted_relaiable_checkmark.png")} alt="checkmark" style={{display: "inline-block", height: "40px"}} />Reliable Resources</h4>
                            </div>
                            <div className="col-lg-12">
                                    <ul className="google-list">
                                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                                        <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                                        <li className="text">The Iseehear Trusted Reliable Same Day Support Desk <a className="textLink" href="https://softmouse.net/softmouse-mouse-colony-management-software-database-lims-support.jsp" target="_blank" rel="noreferrer">Click Here</a></li>
                                        <li className="text">Research Continuity Environment (Disaster Recovery) <Link className="textLink" to="/disasterRecovery">Click Here</Link></li>
                                        <li className="text">Information Security Management (ISM) <Link className="textLink" to="/securityOverview">Click Here</Link></li>
                                        <li className="text">Iseehear Privacy Policy <Link className="textLink" to="/privacyPolicy">Click Here&nbsp;&nbsp;<img src={require("../../img/external-link-black.jpg")} alt="link" /></Link></li>
                                        <li className="text">Iseehear Subscription Agreement <Link className="textLink" to="/subscriptionAgreement">Click Here&nbsp;&nbsp;<img src={require("../../img/external-link-black.jpg")} alt="link" /> <img src={require("../../img/SoftMouse_download_black.png")} alt="link" /></Link></li>
                                    </ul>
                            </div>
                            <div className="col-lg-12">
                                    <br /><br />
                                    <div className="text"><img src={require("../../img/external-link-black.jpg")} alt="link" />&nbsp;&nbsp;Indicates the link goes to another Iseehear owned or authorized website</div>
                                    <div className="text"><img src={require("../../img/external-link-red.png")} alt="link" />&nbsp;&nbsp;Indicates the link goes to a third party website.<br /></div>
                                    <div className="text"><img src={require("../../img/SoftMouse_download_black.png")} alt="softmouse" />&nbsp;&nbsp;Indicates PDF Download<br /></div>
                            </div>                
                    </div>
                </div>

            </section>
            <Footer />
        </div>
    )
}