import React from "react";
import {Admin} from "../Admin/Admin";
import useToken from '../PrivateRoute/useToken';
import Login from "./Login";

export const PrivateRoute = () => {

    const  { token, setToken } = useToken();

    if(!token) {
      return <Login setToken={setToken} />
    }

    return (
        <Admin />
    )
}