import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const Trademark = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Trademark Notice</title>
                <meta name="description" content="Iseehear Trademarks shall include all registered or unregistered trademarks and service marks (such as words, names, symbols, devices, slogans, or combination of these) owned by Iseehear." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="trademark">
                <div className="container">
                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Trademark Notice</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                        </div>
                    </div>

                    <div className="subscription-list">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                        <p className="text">Published: September 7, 2000 | Updated: May 28, 2018.</p><br />
                        <p className="text">This page contains the most current information on Iseehear trademarks.</p><br />
                        <p className="text">Iseehear Inc (Iseehear) trademarks are intellectual property, with value both to Iseehear Inc and to its shareholders.</p><br />
                        <p className="text">Iseehear Trademarks shall include all registered or unregistered trademarks and service marks (such as words, names, symbols, devices, slogans, or combination of these) owned by Iseehear.</p><br />
                        <p className="text">Iseehear considers its trademarks and service marks to be valuable intellectual property assets. As such, Iseehear intends to take all appropriate measures to preserve the strength of and retain its exclusive rights to use its marks. If you have any questions, please direct them to legal.affairs@iseehear.com.</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}