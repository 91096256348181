import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const EmergencyUpgrade = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Emergency Upgrade</title>
                <meta name="description" content="Emergency Application Upgrades and System Maintenance Procedures are unscheduled procedures performed in order to keep the Iseehear systems and services performing at first-in-class service levels if a severe issue is noticed." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="emergency-upgrade">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Emergency Application Upgrades And System Maintenance Procedures</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                        </div>
                    </div>
                </div>

                <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px"}}>
                    <div className="container">

                        <div className="largefont">

                            <p className="text">Emergency Application Upgrades and System Maintenance Procedures are unscheduled procedures performed in order to keep the Iseehear systems and services performing at first-in-className service levels if a severe issue is noticed.</p><br /><br />


                            <h4 className="caption"><b>What are Emergency Circumstances?</b></h4>
                            <h3 className="subCaption"> Circumstances can either happen on:</h3><br /><br />
                            <ol className="data_list">
                                <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                                <li className="text">Iseehear's end (e.g. you are unable to access your data due to an issue on Iseehear's network or at Iseehear's hosting facility)</li> <br /><br />

                                <li className="text">The subscriber's end (e.g. your internet has gone down)</li><br /><br />

                                <li className="text">In the event that Iseehear's primary servers are offline, or in the event of an emergency at Iseehear's hosting facility, status updates will be posted for the customers on the <a className="textLink" href="https://iseehearsecurity.statuspage.io/" target="_blank" rel="noreferrer">IseehearHealth Status page</a>.</li><br /><br />

                                <li className="text">In the event that there is an emergency issue on the subscriber's end and the subscriber cannot access their data, please be assured that subscriber data is safe and backed up regularly every 24 hours at a secure secondary location.</li><br /><br /> <br />
                            </ol>
                            <p className="text">If the emergency on the subscribers end persists and Internet access cannot be restored within a reasonable timeframe, the subscriber can contact the Iseehear support team directly and request an Excel back up of their data.</p><br />                       

                            <br /><br /><h3 className="subCaption">Emergency Upgrades or Emergency System Maintenance Procedures will be posted in advance in these locations:</h3><br /><br />
                            <ol className="data_list">
                                <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                                <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                <li className="text">Iseehear Health <a className="textLink" href="https://iseehearsecurity.statuspage.io/" target="_blank" rel="noreferrer"> Network Status Check &nbsp;<img src={require("../../img/external-link-black.png")} alt="link-black" /></a></li>
                                <li className="text">On the <b>Notice Board</b> section of the login access page</li><br /><br />
                                <img src={require("../../img/softmouse-net-notice-board-0001.png")} alt="softmous" className="img-responsive" /><br /><br />
                                <li className="text">On the <b>Information Scroll</b> located at the top of <a className="textLink" href="https://www.softmouse.net" style={{textDecoration: "underline"}} target="_blank" rel="noreferrer">www.SoftMouse.NET</a> &nbsp;<img src={require("../../img/external-link-black.png")} alt="link-black" /></li><br /><br />
                                <img src={require("../../img/softmouse-net-scroll-0001.png")} alt="softmous"  className="img-responsive" />
                            </ol>

                            <br />
                            <p className="text">Iseehear will post a notice message to the Notice Board section on the login access pages and on the Iseehear Blogs and Iseehear Twitter account. Subscribers will also receive an emergency maintenance notice email informing them of the unscheduled event.</p>
                            <br /><br />
                            <p className="text">Iseehear will keep you informed of our progress and estimated time to resumption of affected systems and services.</p>
                            <br /><br />
                            <p className="text"><br />To learn more about Emergency Upgrades And System Maintenance Procedures, <Link className="textLink" to="/disasterRecovery" rel="nofollow">click here.</Link></p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}