import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const EmergencyPrepare = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Emergency Prepare</title>
                <meta name="description" content="In the event the Iseehear Inc. IT-Security Team concludes that the service disruption will last beyond five (5) minutes, then the IT-Security Team will inform our cutomers of this situation. We will post status updates on the Health Status Check Dashboard page." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="emergency-prepare">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Here is how  <span style={{color: "lightblue"}}>WE</span> are Prepared</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                            <h4 className="caption" style={{color: "lightblue"}}>Emergency Planning</h4><br />
                        </div>
                    </div>
                </div>
                <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px"}}>
                    <div className="container">
                        <div className="col-lg-12">

                            <h4 className="caption"><b>Service Disruption Plan of Action</b></h4>
                            <ul className="data_list prepare-list">
                                <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                                <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                                <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                                <li className="text">1. In the event the Iseehear Inc. IT-Security Team concludes that the service disruption will last beyond five (5) minutes, then the IT-Security Team will inform our cutomers of this situation. We will post status updates on the <a className="textLink" href="https://iseehearsecurity.statuspage.io/" target="_blank" rel="noreferrer">Health Status Check Dashboard</a> page.</li>
                                <br /><br />
                                <li className="text">2. In the event of a security incident, network outage, cyberattack, data breach or some type of service distruption, please visit our <a className="textLink" href="https://iseehearsecurity.statuspage.io/" target="_blank" rel="noreferrer">Health Status Check Dashboard</a> located on the IseehearHealth.com/status page for updates, information and instructions.</li>
                                <br /><br />
                                <li className="text">3. In the event of any type of service distruption occurrence, the Iseehear Inc. IT-Security Team logs and prioritizes the service distruption according to its severity and all service distruption notifications will be posted and archived on the <a className="textLink" href="https://iseehearsecurity.statuspage.io/" target="_blank" rel="noreferrer">Iseehear Health Status Check Dashboard</a>.</li>
                                <br /><br />
                                <li className="text">4. The <a className="textLink" href="https://iseehearsecurity.statuspage.io/" target="_blank" rel="noreferrer">Health Status Check Dashboard</a> will be updated regularly so as to keep the customers informed on the status of the incident and the steps being taken to resolve the incident. Once the incident has been resolved, the customers will be informed via the <a className="textLink" href="https://iseehearsecurity.statuspage.io/" target="_blank" rel="noreferrer">Health Status Check Dashboard</a>.</li>
                                <br /><br />
                                <li className="text">5. In the event the Iseehear Inc. IT-Security Team concludes that the service disruption will last beyond sixty (60) minutes, then the IT-Security Team will inform our cutomers of this situation and deploy the cloud application along with a backed-up copy of the customer data to the <a className="textLink" href="https://www.iseehear.com/research+continuity.php" target="_blank" rel="noreferrer">Research Continuity Environment</a>.</li> <br /><br />
                                <li className="text">For example, in the event that there is a service disruption affecting SoftMouse.NET lasting longer than 60 minutes, then these steps will be taken:</li> <br /><br />
                                <ul className="prepare-list prepare-side">
                                    <li className="text">5.1. The <a className="textLink" href="https://www.iseehear.com/research+continuity.php" target="_blank" rel="noreferrer">Research Continuity Environment</a> will be deployed:</li><br /><br />
                                    <ul className="prepare-list prepare-side">
                                        <li className="text">5.1.1. With a clean SoftMouse.NET application.</li><br /><br />
                                        <li className="text">5.1.2. With a backed-up copy of the customer data.</li><br /><br />
                                    </ul>
                                    <li className="text">5.2. Customers will be informed on how to access SoftMouse.NET in order to continue with their research activities.</li> <br /><br />
                                </ul>

                                <br />
                                <li className="text">Read more about the <a className="textLink" href="https://www.iseehear.com/research+continuity.php" target="_blank" rel="noreferrer">Research Continuity Environment</a></li><br /><br />
                                <p className="text">Please visit the Iseehear Health Status Check Dashboard &nbsp;&nbsp;
                                    <a href="https://iseehearsecurity.statuspage.io/" className="btn btn-large" target="_blank" rel="noreferrer"><button className="button">Iseehear Health Status Check Dashboard</button></a>
                                </p>
                            </ul>
                            <br />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}