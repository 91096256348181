import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { HashLink as A } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

export const  BetaSiteAgreement = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Beta Site Agreement</title>
                <meta name="description" content="By clicking the I accept the Iseehear Beta Site Agreement terms checkbox on the Registration page, by executing a written copy of this Agreement, or by installing, copying or otherwise using this Product, you agree to be bound by the terms of this Agreement. If you do not agree with any term or condition, do not access, download, order, open, install or use the Product or product package." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="betaSiteAgreement">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">

                            <h3 className="mainCaption">Beta Site Agreement</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />

                            
                        </div>
                    </div>

                    <div className="polisy-summary">
                        <p className="text">Effective Date: May 31, 2018 This Beta Site Agreement may be amended from time to time.</p><br /><br />
                        <img src={require("../../img/iseehear-logo.png")} alt="logo" className="policiLogo" /><br /><br />
                        <p className="text">Iseehear Inc. Life Sciences (“Iseehear”, “we”, “us”, and “our”).</p><br /><br />
                    </div>

                    <div className="google-list anchorLink">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                        <div className="anchor-link">
                            <A className="text" to="#Definitions">Definitions</A><br />
                            <A className="text" to="#BetaTrialPeriodandFeedback">Beta Trial Period and Feedback</A><br />
                            <A className="text" to="#Licence">Licence</A><br />
                            <A className="text" to="#RestrictiononUseofSoftwareCreatedwiththeProduct">Restriction on Use of Software Created with the Product</A><br />
                            <A className="text" to="#IntellectualPropertyinProduct">Intellectual Property in Product</A><br />
                            <A className="text" to="#ExclusionofWarranties">Exclusion of Warranties</A><br />
                            <A className="text" to="#LimitationofLiability">Limitation of Liability</A><br />
                            <A className="text" to="#TerminationandExpiryofBetaTrialPeriod">Termination and Expiry of Beta Trial Period</A><br />
                            <A className="text" to="#IntentiontoOfferLicenceofCommercialVersionofProduct">Intention to Offer Licence of Commercial Version of Product</A><br />
                            <A className="text" to="#ConfidentialInformationandNonDisclosure">Confidential Information and Non-Disclosure</A><br />
                            <A className="text" to="#MediaRelations">Media Relations</A><br />
                            <A className="text" to="#General">General</A><br />    
                        </div>
                        <div>
                            <Link className="text pull-right" to="/documents/beta-site-agreement.pdf" target="_blank" rel="noreferrer">Download PDF <img src={require("../../img/SoftMouse_download_black.png")} alt="softmouse" /></Link><br />
                        </div>
                    </div>

                    <div className="subscription-list">
                        <span className="caption">ISEEHEAR INC. BETA  SITE  AGREEMENT  </span><br /><br />      
                        <p className="text">THIS AGREEMENT is made</p>
                        <p className="text">B E T W E E N:</p>
                        <p className="text">ISEEHEAR INC., a company incorporated in Ontario, with its principal office at 250 University Avenue, Suite 200, Toronto, Ontario, Canada, M5H 3E5</p>
                        <p className="text">(“ISH”)</p>
                        <p className="text">- and –</p>
                        <p className="text">The registered applicant to this Beta Site Agreement</p>
                        <p className="text">(“User”)</p>
                        <p className="text">ASSENT TO BE BOUND:</p>
                        <p className="text">By clicking the "I accept the Iseehear Beta Site Agreement terms" checkbox on the Registration page, by executing a written copy of this Agreement, or by installing, copying or otherwise using this Product, you agree to be bound by the terms of this Agreement. If you do not agree with any term or condition, do not access, download, order, open, install or use the Product or product package.</p>
                        <p className="text">NOTE TO THE    BETA   SITE USER:</p>
                        <p className="text">This Beta Site Agreement will permit you to use the beta version of the ISH product for a thirty (30) day test period only.  While you should read the entire Beta Site Agreement, please understand the following important points:</p>
                        <p className="text">•	The beta version of the product will permit the User to use all the functionality of the product. However, the licence granted you by this Beta Site Agreement is limited for only for the thirty (30) day period or for the length of time the User account is active.</p>
                        <p className="text">•	The beta version of the product is a trial version which may be improved by ISH as part of the beta testing and development process.  It is possible that the beta version of the product contains errors or defects.  You use the beta version of this product completely at your own risk, and ISH will have no responsibility to you of any description.</p>
                        <p className="text">THE PARTIES HEREBY AGREE AS FOLLOWS:</p>  
                    </div>

                    <div id="Definitions" className="subscription-list">
                        <span className="caption">1.	Definitions </span><br /> 
                        <p className="text">In this Agreement, the following terms shall have the following meanings: “Beta Trial Period” shall have the meaning set out in Section 2.  “Confidential Information” means all non-public information of ISH including the Product, methods and concepts used in the Product, any technical or business information of ISH, marketing plans of ISH, and any beta test reports or feedback made by the User to ISH.  However, “Confidential Information” does not include information which the User can clearly establish: was lawfully known to or independently developed by the User before being received from ISH; has become available to the general public through no fault of the User or its employees; or is lawfully obtained by the User from a third party not having an obligation to maintain the confidence of the information. “Product” means the software.</p><br />
                    </div>
        
                    <div id="BetaTrialPeriodandFeedback" className="subscription-list">
                        <span className="caption">2.	Beta Trial Period and Feedback </span><br /><br />       
                        <p className="text">The User agrees to test the Product free of charge by using the Product for a period of thirty (30) days or for the length of time the User account is active beginning on the date that the User receives access to the Product from ISH (the “Beta Trial Period”).  ISH shall provide access to the Product to User at no charge. During the Beta Trial period, the User may use the Product on a regular basis and provide feedback to ISH (by feedback form, telephone call, text message or e-mail) on the testing, the Product’s performance and any problems identified. At its sole discretion, ISH may provide technical support for the Product to the User. However, ISH shall not be obligated to provide technical support for the Product, ensure the proper operation of the Product with any software or hardware, or correct any errors or defects in the Product.</p><br />
                        <p className="text">It is expressly understood, acknowledged and agreed that the User may provide to ISH reasonable suggestions, comments and feedback regarding the Product, including but not limited to usability, bug reports and test results, with respect to Product usage (collectively, "Feedback"). If the User provides such Feedback to ISH, all such feedback shall be and remain the property of ISH.</p><br />
                    </div>

                    <div id="Licence" className="subscription-list">
                        <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                        <span className="caption">3.	Licence </span><br /><br />
                        <p className="text">For the term of the Beta Trial Period only, ISH grants the User a royalty-free, non-transferable and non-assignable right to use the Product solely for the purpose of evaluating the Product. Under this limited licence, the User shall permit only authorized users, who possess rightfully obtained access permissions to use the Product or to view the Documentation associated with the Product.</p><br />
                    </div>

                    <div id="RestrictiononUseofSoftwareCreatedwiththeProduct" className="subscription-list">
                        <span className="caption">4.	Restriction on Use of Software Created with the Product </span><br /><br />
                        <p className="text">The User agrees that it shall not resell or distribute the Product.</p><br />
                    </div>

                    <div id="IntellectualPropertyinProduct" className="subscription-list">
                        <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                        <span className="caption">5.	Intellectual Property in Product </span><br /><br />
                        <p className="text">The User acknowledges that ISH retains all intellectual property rights in the Product and all derivative works created using the Product, including copyright, trade-mark, patent and trade secret rights.  The User shall not copy the Product. The User shall not copy, sub-license, distribute, reverse engineer, decompile or disassemble the Product.</p><br />
                    </div>

                    <div id="ExclusionofWarranties" className="subscription-list">
                        <span className="caption">6.	Exclusion of Warranties </span><br /><br />
                        <p className="text">THE USER ACKNOWLEDGES THAT THE PRODUCT IS STILL UNDER DEVELOPMENT AND IS PROVIDED “AS IS” WITHOUT ANY WARRANTY OF ANY KIND.  ISH EXPRESSLY EXCLUDES ALL EXPRESS OR IMPLIED WARRANTIES RELATING TO THE PRODUCT, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT.  THE USER ACKNOWLEDGES THAT ENTIRE RISK ARISING OUT OF USE OR PERFORMANCE OF THE PRODUCT REMAINS WITH THE USER.</p><br />
                    </div>

                    <div id="LimitationofLiability" className="subscription-list">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <span className="caption">7.	Limitation of Liability </span><br /><br />     
                        <p className="text">THE USER AGREES THAT NEITHER ISH NOR ANY OTHER PERSON INVOLVED IN CREATING, INSTALLING, DELIVERING, TESTING OR USING THE PRODUCT SHALL BE LIABLE FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL OR INCIDENTAL LOSS OR DAMAGE (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS, PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION OR ANY OTHER PECUNIARY LOSS) ARISING FROM OR RELATING TO THE PRODUCT OR ITS TESTING HEREUNDER.</p><br />
                    </div>

                    <div id="TerminationandExpiryofBetaTrialPeriod" className="subscription-list">
                        <span className="caption">8.	Termination and Expiry of Beta Trial Period </span><br /><br />      
                        <p className="text">This Agreement may be terminated by either party before the end of the Beta Trial Period, for any reason, without notice to the other party. At the end of the Beta Trial Period or upon the termination of this Agreement, the User shall promptly cease using the Product.</p><br />  
                    </div>

                    <div id="IntentiontoOfferLicenceofCommercialVersionofProduct" className="subscription-list">
                        <span className="caption">9.	Intention to Offer Licence of Commercial Version of Product </span><br /><br />       
                        <p className="text">On the completion of its beta trial program, ISH intends to offer a licence to the commercial version of the Product at a special licence fee to users that have successfully completed the beta trial process.  However, the User acknowledges that ISH does not represent that ISH will release a commercial version of the Product, that the commercial version of the Product will have the same functionality or performance of the Product, or that ISH will correct any errors or defects identified in the course of the beta trial program.</p><br />  
                    </div>

                    <div id="ConfidentialInformationandNonDisclosure" className="subscription-list">
                        <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <span className="caption">10.	Confidential Information and Non-Disclosure </span><br /><br />                
                        <p className="text">The User acknowledges that the Product and information to be disclosed to the User, directly or indirectly, by ISH will include Confidential Information. The User agrees to hold the Product and any Confidential Information confidential and not to use the Product and information except for the purposes of this Agreement. The User shall disclose the Product and Confidential Information only to its employees or colleagues who have a need to know the Product and information for the purposes of this Agreement. The User shall inform such employees or colleagues of the confidentiality provisions of this Agreement and take reasonable measures to ensure that its employees or colleagues abide by such provisions.  The User shall obtain the written permission of ISH before disclosing the Product or any Confidential Information to a party other than the User’s own employees or colleagues. This Section 10 shall not prevent any disclosure or use of information by the User which is required to comply with a court order. This Section 10 shall survive the expiry or termination of this Agreement.</p><br />   
                    </div>

                    <div id="MediaRelations" className="subscription-list">
                        <span className="caption">11.	Media Relations </span><br /><br />        
                        <p className="text">Except with the prior written consent of ISH, the User shall not make any report about the Product to a third party nor give any interview with the media, nor post information on the Internet about the Product before ISH’s release of the commercial version of the Product.</p><br />     
                    </div>

                    <div id="General" className="subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <span className="caption">12.	General </span><br /><br />       
                        <p className="text">(a)	The User shall have no right to assign this Agreement.  This Agreement shall enure to the benefit of ISH’s successors and assigns.</p><br />
                        <p className="text">(b)	In the event that any or any part of the terms, conditions or provisions contained in this Agreement are determined by a competent authority to be invalid, unlawful or unenforceable, such term, condition or provision shall to that extent be severed from the remaining terms, conditions and provisions, which shall continue to be valid and enforceable to the fullest extent permitted by law.</p><br />
                        <p className="text">(c)	This Agreement constitutes the entire agreement between the parties pertaining to all the matters herein and supersedes all prior agreements, understandings, letters of intent, negotiations and discussions between the parties, whether oral or written in respect thereof.</p><br />
                        <p className="text">(d)	This Agreement shall be governed in all respects by the law of Ontario and the federal laws of Canada applicable therein. The User hereby agrees to the non-exclusive jurisdiction of the Courts of Ontario located in the City of Toronto to resolve any matter relating to this Agreement or the beta trial program.</p><br />     
                    </div>

                    <div className="subscription-list">
                        <span className="caption">Contact Information </span><br /><br />       
                        <p className="text">If you have any questions please contact Iseehear Inc. at information@iseehear.com or at</p>
                        <p className="text">ISEEHEAR INC. LIFE SCIENCES</p>
                        <p className="text">250 University Avenue,</p>
                        <p className="text">Suite 200,</p>
                        <p className="text">Toronto, Ontario,</p>
                        <p className="text">Canada, M5H 3E5</p>   
                    </div>

                    <div className="subscription-list">
                        <p className="text">Iseehear is a trademark of Iseehear Inc.</p>
                        <p className="text">Copyright (C) 2000-2018 Iseehear Inc. All rights reserved.</p>       
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}