// scroll up
import React from "react";
import { useEffect, useState } from "react";

export const BackToTopButton = () => {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if(window.scrollY > 100) {
                setBackToTopButton(true)
            } else {
                setBackToTopButton(false)
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
            <div>
                {backToTopButton && (
                <button onClick={scrollUp} className="errowScrol">
                    <svg width="46" height="51" viewBox="0 0 46 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.9991 6.1891L20.4638 2.46865L22.9424 0.781072L25.4559 2.42151L22.9991 6.1891ZM27.9185 46.1444C27.9446 48.6296 25.9511 50.6632 23.4659 50.6867C20.9807 50.7101 18.945 48.7145 18.9189 46.2294L27.9185 46.1444ZM0.604546 15.9901L20.4638 2.46865L25.5344 9.90956L5.67518 23.431L0.604546 15.9901ZM25.4559 2.42151L45.5948 15.5653L40.6813 23.1005L20.5423 9.95669L25.4559 2.42151ZM27.4989 6.14662L27.9185 46.1444L18.9189 46.2294L18.4993 6.23159L27.4989 6.14662Z" fill="#67C3F3" fillOpacity="0.65"/>
                    </svg>
                </button>
                )}
            </div>
    )
}
    
