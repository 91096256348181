import React from "react";
import { Link } from "react-router-dom";
import SectionForm from "./SectionForm";
import { DataSectionRender } from "../FetchData/DataSectionRender";
import QuestionForm from "./QuestionForm";
import CreatePassword from "../PrivateRoute/CreatePassword";

export const Admin = () => {
  function refreshPage() {
    sessionStorage.removeItem("token");
    window.location.reload(false);
  }


  return (
    <div className="admin container">
      <nav className="navbar navbar-dark bg-dark fixed-top">
        <div className="container-fluid">
          <p className="navbar-brand textCaption">
            Iseehearsecurity Admin Panel
          </p>
          <div className="logOutMenu">
            <Link
              className="navbar-brand active textCaption"
              to="/admin"
              onClick={refreshPage}
            >
              LogOut
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasDarkNavbar"
              aria-controls="offcanvasDarkNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div
            className="offcanvas offcanvas-end text-bg-dark bg-dark"
            tabIndex="-1"
            id="offcanvasDarkNavbar"
            aria-labelledby="offcanvasDarkNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
                Dark offcanvas
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 nav flex-column">
                <Link
                  className="nav-link active textCaption"
                  to="/"
                  onClick={() => sessionStorage.removeItem("token")}
                >
                  Home
                </Link>
                <li className="nav-item">
                  <a
                    className="nav-link text active"
                    href="#dashboard"
                    data-bs-toggle="pill"
                  >
                    Questions and Answer
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text" href="#users" data-bs-toggle="pill">
                    Create new Password
                  </a>
                </li>
                {/* <li class="nav-item">
                  <a
                    class="nav-link text"
                    href="#settings"
                    data-bs-toggle="pill"
                  >
                    Settings
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div className="col-12">
        <div className="tab-content">
          <div className="tab-pane fade show active" id="dashboard">
          <div className="container">
                <QuestionForm />
                <SectionForm />
            </div>
            <DataSectionRender />
          </div>
          <div className="tab-pane fade" id="users">
                <CreatePassword refreshPage={refreshPage} />
          </div>
          <div className="tab-pane fade" id="settings">
            <h1>Settings</h1>
            <p>This is the main content area for the Settings tab.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
