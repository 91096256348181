import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const Mapping = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Security Compliance Mapping</title>
                <meta name="description" content="Security Compliance Mapping" />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="mapping">
                <div className="container">
                    <div className="row disaster-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Security Compliance Mapping</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                            <h4 className="caption">Security Compliance Mapping</h4><br />
                        </div>
                    </div>

                    <div className="google-list anchorLink">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                        <div>
                            <Link className="text pull-right" to="/documents/mapping.docx.pdf" target="_blank" rel="noreferrer">Download PDF <img src={require("../../img/SoftMouse_download_black.png")} alt="softMouse" /></Link><br />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}