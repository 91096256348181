import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const PolicyNotices = () => {
  return (
        <div className="wrapper">
          <Helmet>
            <title>Policy Notices History</title>
            <meta name="description" content="Iseehear Inc. Life Sciences Electronic Subscription Agreement has been updated as of October 22, 2020. If You are a new Subscriber, then this Electronic Subscription Agreement will be effective as of November 2, 2020. If You are an existing Subscriber, We are providing You with prior notice of these changes which will be effective as of December 1, 2020." />
            <meta http-equiv="keywords" content="life, sciens, status, health, check" />
          </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="policyNotices">
                <div className="container">

                  <h1 className="mainCaption">Policy Notices History</h1>
                  <div className="content-section-b">
                    <hr style={{borderBottom: "2px solid lightblue"}} />
                    <div className="row main-content">
                        <div className="subscription-list">
                            <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                            <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                            <div className="accordion">
                              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                <button style={{backgroundColor: "#f9ca24", border: "2px #86b7fe solid"}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne2" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne2">
                                  <h3 className="caption" style={{fontSize: "3.5rem", lineHeight: "3.6rem"}}>UPDATE: Iseehear Inc. Life Sciences Electronic Subscription Agreement <br /><span className="textCaption">2 years ago</span></h3>
                                </button>
                              </h2>
                              <div id="panelsStayOpen-collapseOne2" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne2">
                                <div className="accordion-body">                            
                                    <p className="text">It is our goal to communicate in a timely fashion the changes we make to our Electronic Subscription Agreement.</p>
                                    <p className="text">Iseehear Inc. Life Sciences Electronic Subscription Agreement has been updated as of October 22, 2020. If You are a new Subscriber, then this Electronic Subscription Agreement will be effective as of November 2, 2020. If You are an existing Subscriber, We are providing You with prior notice of these changes which will be effective as of December 1, 2020. For the updated version and the previous version of the Iseehear Inc. Life Sciences Electronic Subscription Agreement, please see the links below.</p>  
                                    <Link className="text" to="/documents/subscription+agreement.pdf" target="_blank"><p className="textLink">Updated Subscription Agreement: October 22, 2020</p></Link>
                                    <Link className="text" to="/documents/subscription+agreement-old.pdf" target="_blank"><p className="textLink">Previous Subscription Agreement: February 26, 2019</p></Link>
                                    <p className="text">Thank you</p>
                                    <p className="text">Iseehear Inc. Life Sciences</p>
                                </div>
                              </div>
                            </div>
                        </div>                    
                    </div>
                  </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}