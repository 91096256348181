import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
    return (
        <div>
            <footer className="footer">
              <div className="container">
                  <div className="contactInfo">
                      <h2 className="subCaption">Support and Security</h2>
                  </div>
                  
                  <div className="contactInfo">
                      <h2 className="subCaption">Corporate Websites</h2>
                      <ul>
                          <li>
                              <a href="https://www.iseehear.info" target="_blank" rel="noreferrer">
                                  <p className="text">Iseehear.INFO</p>
                              </a>
                          </li>
                          <li>
                              <a href="http://www.iseehear.com" target="_blank" rel="noreferrer">
                                  <p className="text">Iseehear.com</p>
                              </a>
                          </li>
                          <li>
                              <a href="https://www.softmouse.net" target="_blank" rel="noreferrer">
                                  <p className="text">SoftMouse.NET</p>
                              </a>
                          </li>
                          <li>
                              <a href="http://www.softmousefaq.com" target="_blank" rel="noreferrer">
                                  <p className="text">SoftMouseFAQ.com</p>
                              </a>
                          </li>
                          <li>
                              <a href="http://www.breedingservices.com" target="_blank" rel="noreferrer">
                                  <p className="text">BreedingServices.com</p>
                              </a>
                          </li>
                      </ul>
                  </div>

                  <div className="contactInfo">
                      <h2 className="subCaption">Contact Information</h2>        
                      <Link to="/contactForm"><button className="button">Contact Us</button></Link> 
                      <p className="text">If you are experiencing an issue, please contact customer support.</p>
                      <p className="text">Phone: +1 416-492-4358</p>
                      <p className="text">Toll Free: 1 844 559 6322</p>
                  </div>
              </div>
              <a href="http://www.iseehear.com" target="_blank" rel="noreferrer"> <img src={require("../img/iseehear-cloud-0005.png")} alt="cloudImg" className="img-rISHSeconsive cloud-footer" /></a>
              <img src={require("../img/iseehear-health-check-0002.png")} alt="healthCheck" className="iseehearImg" />
              <div className="bottomLink">
                  <p className="text">2000 - 2023 Iseehear Inc. All rights reserved.</p>
              </div>
              <div className="bottomLink">
                <a href="accessibility.html" className="text">Accessibility</a> 
                <p className="text">|</p> 
                <a href="policiesOverview.html" className="text" rel="noreferrer">Privacy Policy</a> 
                <p className="text">|</p> 
                <a href="termsOfUse.html" className="text" rel="noreferrer">Terms of Use</a>
                <p className="text">|</p> 
                <a href="/#" className="text">Sitemap</a>
              </div>
            </footer>
        </div>
    )
}