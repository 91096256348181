import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const PoliciesOverview = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Policies Overview</title>
                <meta name="description" content="At Iseehear Inc. Life Sciences (Iseehear) we are committed to protecting your privacy. Iseehear endeavours to ensure that the information that you submit to us remains private and that it is used only for the services we provide to you. We aim to provide a safe and secure experience for all of our users." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="policiesOverview">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Iseehear Privacy Policy (Summary)</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                        </div>
                    </div>

                    <div className="polisy-summary">
                        <p className="text">Effective Date: May 25, 2018 This Privacy Policy may be amended from time to time.</p><br /><br />
                        <img className="policiLogo" src={require("../../img/iseehear-logo.png")} alt="logo" /><br /><br />
                        <p className="text">Iseehear Inc. Life Sciences (“Iseehear”, “we”, “us”, and “our”).</p><br /><br />
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="summaryVersion">
                        <h2 className="subCaption">PRIVACY POLICY (Summary Version)</h2><br /><br />
                        <p className="text">Privacy Policy describes how your personal information is collected, used, and shared when you visit or register at ISEEHEAR.COM (the “Site”).</p><br /><br />
                        <p className="text">At Iseehear Inc. Life Sciences (Iseehear) we are committed to protecting your privacy. Iseehear endeavours to ensure that the information that you submit to us remains private and that it is used only for the services we provide to you. We aim to provide a safe and secure experience for all of our users.</p><br /><br />
                        <p className="text">Respecting and protecting your personal data privacy is important to us. So we have updated our Privacy Policy as well as Terms of Use on May 25, 2018 to make our policies even more transparent as well as compliant with new privacy regulations being enacted in various countries and regions around the world (for example PIEPEDA in Canada, GDPR in Europe, PIPA in South Korea, DPB in the United Kingdom, PDPA in Singapore, …). The majority of the regional data protections and privacy regulations (enacted, being revised or pending) have some common expectations and requirements regarding the transparency, control, securing and responsibility of personally identifiable information collected by companies providing digital or internet centric services to their citizens.</p><br /><br />
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div>
                        <h2 className="subCaption">PERSONAL INFORMATION WE COLLECT</h2><br />
                        <p className="text">When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address and operating system. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”</p><br /><br />
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div>
                        <h2 className="subCaption">We collect Device Information using the following technologies:</h2><br />
                        <ol className="data_list">
                            <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                            <li className="text">"Cookies" are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <a className="textLink" href="http://www.allaboutcookies.org" target="_blank" rel="noreferrer">http://www.allaboutcookies.org <img src={require("../../img/external-link-red.png")} alt="link-red" /></a>.</li>
                            <li className="text">"Log files" track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
                        </ol>
                        <br />

                        <p className="text">Additionally when you register through the Site, we collect certain information from you, including your name, billing address, email address, and phone number.  We refer to this information as “Registration Information.”<br />
                        When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Registration Information.</p><br /><br />
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div>
                        <h2 className="subCaption">HOW DO WE USE YOUR PERSONAL INFORMATION?</h2><br />
                        <p className="text">We use the Registration Information that we collect generally to fulfill any registrations placed through the Site (providing you with trusted and reliable customer service, quotes, invoices and/or subscription confirmations).  Additionally, we use this Registration Information to:</p><br />
                        <p className="text">1. Communicate via email or telephone with you about:</p><br />
                        <ol className="data_list">
                            <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                            <li className="text">Customer service support (enquires and follow ups)</li>
                            <li className="text">System and new features updates / upgrades</li>
                            <li className="text">Scheduled or unscheduled system maintenance bulletins</li>
                            <li className="text">Security bulletins</li>
                        </ol>
                        <p className="text">2. Screen registrations for potential security risk or fraud.</p><br /><br />
      
                        <p className="text">We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p><br /><br />
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div>                        
                        <h2 className="subCaption">SHARING YOUR PERSONAL INFORMATION</h2><br />
                        <p className="text">We share your Personal Information with third parties to help us use your Personal Information, as described above.  For example, we use Mail Chimp for managing our email communication, you can read more about how Mail Chimp uses your Personal Information here:  <a className="link-wrap textLink" href="https://www.mailchimp.com/legal/privacy" target="_blank" rel="noreferrer">https://www.mailchimp.com/legal/privacy <img src={require("../../img/external-link-red.png")} alt="link-red" /> </a>.  We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here:  <a className="link-wrap textLink" href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noreferrer">https://www.google.com/intl/en/policies/privacy/  <img src={require("../../img/external-link-red.png")} alt="link-red" /></a>.  You can also opt-out of Google Analytics here:  <a className="link-wrap textLink" href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout  <img src={require("../../img/external-link-red.png")} alt="link-red" /></a>.</p><br />
                        <p className="text">Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p><br /><br />
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div>   
                        <h2 className="subCaption">YOUR RIGHTS</h2><br />
                        <p className="text">Individuals may complain to The Privacy Commissioner of Canada about any matter specified in Section 11 of the Personal Information Protection and Electronic Documents Act (PIPEDA). This Act applies to matters relating to personal information in the private sector. (Please see <a className="textLink" href="https://www.priv.gc.ca/en/report-a-concern" target="_blank" rel="noreferrer">https://www.priv.gc.ca/en/report-a-concern <img src={require("../../img/external-link-red.png")} alt="link-red" /></a> for more information.)</p><br /><br />
      
                        <p className="text">If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.<br />
                        Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your personal information will be transferred outside of Europe, including to Canada and the United States.</p><br /><br />
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div>
                        <h2 className="subCaption">DATA RETENTION</h2><br />
                        <p className="text">When you register through the Site, we will maintain your registration Information for our records unless and until you ask us to delete this information.</p><br /><br />
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="text-frame">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <h2 className="subCaption">CHANGES</h2><br />
                        <p className="text">We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons</p><br /><br />
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div>
                        <h2 className="subCaption">SUMMARY</h2><br />
                        <p className="text">In summary, Iseehear commits to transparent privacy practices, and will collect and use personal data only as it might be needed for us to deliver to you our world className services. This Privacy Policy explains the what, how, and why of the data we collect when you visit or when you use Iseehear Services. If you do not agree to this Privacy Policy and the applicable Iseehear Terms of Use, please exit, and do not access or use, Iseehear Services.</p><br /><br />
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div>
                        <h2 className="subCaption">CONTACT US</h2><br />
                        <p className="text">For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at privacy@iseehear.com or by mail using the details provided below:</p><br /><br />
                        <p className="text">ISEEHEAR INC. LIFE SCIENCES</p><br />
                        <p className="text">250 University Avenue</p><br />
                        <p className="text">Suite 200</p><br />
                        <p className="text">Toronto, Ontario</p><br />
                        <p className="text">Canada, M5H 3E5</p><br />
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="text"><img src={require("../../img/SoftMouse_download_black.png")} alt="softMouse" />&nbsp;&nbsp;
                        Indicates the link goes to another Iseehear owned or authorized website
                    </div><br /><br />

                </div>
            </section>
            <Footer />
        </div>
    )
}