import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

import axios from "axios";
import { Helmet } from "react-helmet";

export default class ContactForm extends Component {

    state = {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        sent: false
    }

    // handle inputs
    handleName=(e)=>{
        this.setState({
            name:e.target.value
        })
    }

    handleEmail=(e)=>{
        this.setState({
            email:e.target.value
        })
    }

    handlePhone=(e)=>{
        this.setState({
            phone:e.target.value
        })
    }

    handleSubject=(e)=>{
        this.setState({
            subject:e.target.value
        })
    }

    handleMessage=(e)=>{
        this.setState({
            message:e.target.value
        })
    }

    formSubmit=(e)=>{
        e.preventDefault();

        let data = {
            name:this.state.name,
            email:this.state.email,
            phone:this.state.phone,
            subject:this.state.subject,
            message:this.state.message,
        }

        function ErrorMessage() {
            let newForm = document.querySelector('.pull-right');
            let element = document.createElement('p');
            element.textContent = "<p class='hideMsg'>Message not sent</p>";
            newForm.insertAdjacentHTML('beforebegin', element.textContent);
        }

        axios.post('https://iseehearsecurity.com/post', data)
        .then(res => {
            this.setState({
                sent: true,
            }, this.resetForm())
        })
        .catch(() => {
            ErrorMessage()
            this.resetForm()
        })
    }

    // form Reseting initial data
    resetForm = () => {
        this.setState({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
        })

        setTimeout(() => {
            this.setState({
                sent: false,
            })
        }, 3000)
    }

    render () {
        return (
            <div className="wrapper">
                <Helmet>
                    <title>Contact Form</title>
                    <meta name="description" content="We would like to hear from you! Please fill out this form and we will get in touch with you shortly." />
                    <meta http-equiv="keywords" content="life, sciens, status, health, check" />
                </Helmet>
                <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
                <section className="contactForm">
                    <div className="container">

                        <div className="row data-caption">
                            <div className="col-lg-9">
                                <h3 className="mainCaption">Contact Form</h3>
                                <hr style={{borderBottom: "2px solid lightblue"}} />                               
                            </div>
                        </div>

                        <div className="contactUsForm">
                            <div className="google-list newForm">
                                { this.state.sent ? <p className="hideMsgSuccess"> Message has been sent</p> : <p></p>}                               
                                <img src={require("../../img/lock_secure-page_0001.jpg")} alt="lockImg" className="pull-right" />
                                <p className="noHover">We would like to hear from you!</p>
                                <p className="noHover">Please fill out this form and we will get in touch with you shortly.</p>
                                <div id="result" style={{color: "#E9322D", fontSize: "17px; font-weight: bold"}}></div>
                                <br /><br />

                                <form className="contactForm" onSubmit={this.formSubmit}>
                                    <fieldset>
                                        <input 
                                            type="hidden" 
                                            id="recaptcha_response" 
                                            name="recaptcha_response" 
                                            size="1" 
                                            value="" 
                                        />
                                        <input 
                                            name="name" 
                                            type="text" 
                                            className="text input-block-level tip pplaceholder" 
                                            placeholder="Name *" 
                                            title="This field is required" 
                                            data-toggle="tooltip" 
                                            required minLength="2" 
                                            maxLength="30"
                                            onChange={this.handleName}
                                            value={this.state.name} /><br 
                                        />
                                        <input 
                                            name="email" 
                                            type="email" 
                                            id="email" 
                                            className="text input-block-level tip pplaceholder" 
                                            placeholder="Email *" title="This field is required" 
                                            data-toggle="tooltip" 
                                            required minLength="3" 
                                            maxLength="30"
                                            onChange={this.handleEmail}
                                            value={this.state.email} /><br 
                                        />
                                        <input 
                                            type="tel" 
                                            name="phone" 
                                            id="phone" 
                                            className="text input-block-level" 
                                            placeholder="Phone +1 (999) 999-9999" 
                                            maxLength="15"
                                            onChange={this.handlePhone}
                                            value={this.state.phone} /><br 
                                        />
                                        <input 
                                            name="subject" 
                                            id="subject" 
                                            type="text" 
                                            className="text input-block-level tip pplaceholder" 
                                            placeholder="Subject *" title="This field is required" 
                                            data-toggle="tooltip" 
                                            required minLength="5" 
                                            maxLength="150" 
                                            onChange={this.handleSubject}
                                            value={this.state.subject} /><br 
                                        />
                                        <textarea 
                                            name="message" 
                                            id="message" 
                                            rows="5" 
                                            className="text input-block-level tip pplaceholder" 
                                            title="This field is required" 
                                            data-toggle="tooltip" 
                                            placeholder="Message *" 
                                            required minLength="20" 
                                            maxLength="500" 
                                            onChange={this.handleMessage}
                                            value={this.state.message}
                                        ></textarea><br />
                                        <input 
                                            type="hidden" 
                                            name="curPageName_i" v
                                            alue="{{ Request::path() }}" /><br /><br 
                                        />

                                        <div className="button-form">            
                                            <button 
                                                id="btn_send_message" 
                                                type="submit" 
                                                className="button" 
                                                name="sendemail"><i 
                                                className="icon-envelope"
                                            ></i> Send Message</button>
                                            <button 
                                                onClick={this.resetForm}
                                                id="btn_reset_message" 
                                                type="reset" 
                                                className="mainButton" 
                                                name="reset"><i 
                                                className="icon-remove"
                                            ></i> Clear</button>
                                        </div>
                                    </fieldset>

                                </form>
                                <br />
                                <div className="noHover">We respect your privacy. We will never share or sell your personal information. Please read our <Link to="/privacyPolicy" className="smallfont privacyFooter textLink" rel="nofollow">Privacy Policy</Link>.</div>
                                <br /><br />
                            </div>

                            <div className="data_list feelFree">
                                <h2 className="caption">Feel free to contact us to:</h2>
                                <br />
                                    <ul className="navlistcheck" id="navlistcheck">
                                        <li>
                                            <img className="check" src={require("../../img/check.png")} alt="check" />
                                            <span className="text">create a custom survey</span>
                                        </li><br />
                                        <li><img className="check" src={require("../../img/check.png")} alt="check" /><span className="text">get personalized customer care</span></li><br />
                                        <li><img className="check" src={require("../../img/check.png")} alt="check" /><span className="text">get pricing plan options</span></li><br />
                                        <li><img className="check" src={require("../../img/check.png")} alt="check" /><span className="text">get group discounts</span></li><br />
                                        <li><img className="check" src={require("../../img/check.png")} alt="check" /><span className="text">set up your account</span></li><br />
                                        <li><img className="check" src={require("../../img/check.png")} alt="check" /><span className="text">schedule a demo</span></li><br />
                                        <li><img className="check" src={require("../../img/check.png")} alt="check" /><span className="text">arrange an appointment</span></li><br />
                                        <li><img className="check" src={require("../../img/check.png")} alt="check" /><span className="text">get assistance</span></li><br />
                                        <li><img className="check" src={require("../../img/check.png")} alt="check" /><span className="text">make a suggestion</span></li><br />
                                        <li><img className="check" src={require("../../img/check.png")} alt="check" /><span className="text">learn about professional data import services</span></li><br />
                                        <li><img className="check" src={require("../../img/check.png")} alt="check" /><span className="text">learn about custom software, database and application development</span></li><br />
                                        <li><img className="check" src={require("../../img/check.png")} alt="check" /><span className="text">learn about digital animal colony management consulting</span></li><br />
                                    </ul>
                                    <br /><br /><br />                           
                        
                            </div>
                        </div>

                    </div>
                </section>
                <Footer />
            </div>
        )
    }

}