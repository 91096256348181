import React from "react";
import { ButtonGroup } from "./ButtonGroup";
import UpdateForm from "../Admin/UpdateForm";

function ShowQuestionData(questionData) {
  const [openUpdateMenu, setOpenUpdateMenu] = React.useState(false);
  const [buttonRender, setButtonRender] = React.useState(false);

  React.useEffect(() => {
    if (window.location.href.includes("admin")) {
      setButtonRender(true);
    } else {
      setButtonRender(false);
    }
  }, []);

  return (
    <>
      {openUpdateMenu && (
        <UpdateForm
          closeButtun={() => setOpenUpdateMenu(false)}
          questionData={questionData}
        />
      )}
      <li
        className="questionBox"
        style={{ padding: "1rem 7rem 1rem 1rem", position: "relative" }}
      >
        <p
          className="textSpanBlue"
          style={{ borderBottom: "2px solid lightblue" }}
        >
          {questionData.question}
        </p>
        <p className="text fw-bold">
          {questionData.answer_boolean} {questionData.answer}{" "}
          {questionData.link === "" || questionData.link === null ? (
            ""
          ) : (
            <span className="textSpanBlue">
              For more information click:{" "}
              <a
                className="textLink"
                href={questionData.link}
                target="_blank"
                rel="noreferrer"
              >
                Read More
              </a>
            </span>
          )}
        </p>
        {buttonRender && (
          <ButtonGroup
            questionData={questionData}
            openEditButton={() => setOpenUpdateMenu(true)}
          />
        )}
      </li>
      <br />
      <br />
    </>
  );
}

export default ShowQuestionData;
