import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const CookiePolicy = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Cookie Policy</title>
                <meta name="description" content="Cookies and similar technologies on our websites and our mobile applications allow us to track your browsing behavior, links clicked, items purchased, your device type, and to collect various data, including analytics, about how you use and interact with our Services." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="cookiePolicy">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">

                            <h3 className="mainCaption">Cookie Policy</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                           
                        </div>
                    </div>

                    <div className="polisy-summary subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <p className="text">Effective Date: May 25, 2018 This Privacy Policy may be amended from time to time.</p><br /><br />
                        <img className="policiLogo" src={require("../../img/iseehear-logo.png")} alt="logo" /><br /><br />
                        <p className="text">Iseehear Inc. Life Sciences (“Iseehear”, “we”, “us”, and “our”).</p><br /><br />
                        <span className="caption">Overview</span><br /><br />

                        <p className="text">This Cookie Policy explains how we use cookies in the course of our business, including through our websites that link to this Cookie Policy,
                            such as <a className="textLink" href="https://www.softmouse.NET" target="_blank" rel="noreferrer" >https://www.softmouse.NET  
                            <img src={require("../../img/external-link-red.png")} alt="link-red" /></a>, <a className="textLink" href="https://iseehear.com" target="_blank" rel="noreferrer">https://iseehear.com  
                            <img src={require("../../img/external-link-red.png")} alt="link-red" /></a>, <a className="textLink" href="https://iseehear.info" target="_blank" rel="noreferrer">https://iseehear.info  
                            <img src={require("../../img/external-link-red.png")} alt="link-red" /></a>, <a className="textLink" href="https://www.labcloudplatform.com" target="_blank" rel="noreferrer">https://www.labcloudplatform.com  
                            <img src={require("../../img/external-link-red.png")} alt="link-red" /></a> or any website or mobile application owned, operated or controlled by us (collectively, “Websites” or “websites”).
                        </p><br />
                        <p className="text">We hope the information below provides you with clear and comprehensive information about the cookies we use and the purposes for which we use them, but it you have any additional questions, or require any additional information, please review our Privacy Policy, or contact us at privacy@iseehear.com.</p><br />
                    </div>

                    <div className="subscription-list">
                        <span className="caption">SECTION 1 - YOUR CONSENT </span><br /><br />       
                        <p className="text">By continuing to use and navigate our sites, services, applications, tools or messaging, you are agreeing to our use of cookies as described herein and in our <Link className="textLink" to="/privacyPolicy">Privacy Policy </Link>.</p><br />
                        <p className="text">If you do not wish to accept these cookies in connection with your visit to or use of our sites, services, applications, tools or messaging, please see options below allowing you to control or delete our, or our service provider’s, use of these cookies.</p><br />
                    </div>

                    <div className="subscription-list">
                        <span className="caption">SECTION 2 - WHAT ARE COOKIES?</span><br /><br />        
                        <p className="text">Cookies are small data files that are placed on your computer or mobile device when you visit a website or view a message. Cookies are widely used by online service providers in order to (for example) make their websites or services work, or to work more efficiently, as well as to provide reporting information.   Please see <a className="textLink" href="http://www.allaboutcookies.org" target="_blank" rel="noreferrer" >http://www.allaboutcookies.org  <img src={require("../../img/external-link-red.png")} alt="link-red" /></a>    for more information.</p><br />
                        <p className="text">Cookies set by the website owner (in this case, Iseehear Inc. Life Sciences) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies".</p><br />
                    </div>

                    <div className="subscription-list">
                        <span className="caption">SECTION 3 - WHY DO WE USE COOKIES?</span><br /><br />       
                        <p className="text">We use first party and third party cookies for several reasons. Some cookies are required for technical reasons in order for our Websites and Services to operate, and we refer to these as “essential” or “strictly necessary” cookies. Other cookies enable us and the third parties we work with to track and target the interests of visitors to our Websites, and we refer to these as “performance” or “functionality” cookies.</p><br />
                        <p className="text">Cookies and similar technologies on our websites and our mobile applications allow us to track your browsing behavior, links clicked, items purchased, your device type, and to collect various data, including analytics, about how you use and interact with our Services. This allows us to provide you with more relevant product offerings, a better experience on our sites and mobile applications, and to collect, analyze and improve the performance of our Services. We may also collect your location (IP address) so that we can personalize our Services.</p><br />
                    </div>

                    <div className="subscription-list">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                        <span className="caption">SECTION 4 - HOW CAN I CONTROL COOKIES?</span><br /><br />
                        <ol>
                            <li className="text">1. You have the right to decide whether to accept or reject cookies.</li>
                            <li className="text">2. You can set or amend your web browser controls to accept or refuse cookies. </li>
                            <li className="text">3. If you choose to reject cookies, you may still use our Websites though your access to some functionality and areas of our Websites may be restricted.</li>
                            <li className="text">4. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.</li>
                            <li className="text">5. How to accept or refuse cookies in your browser controls. See links below: <br /><br />
                                <ul className="google-list">
                                    <li className="text"><b>Google Chrome</b> <a className="textLink" href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" target="_blank" rel="noreferrer">https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en  <img src={require("../../img/external-link-red.png")} alt="link" /></a></li>
                                    <li className="text"><b>Mozilla Firefox</b> <a className="textLink" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noreferrer">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences  <img src={require("../../img/external-link-red.png")} alt="link" /></a></li>
                                    <li className="text"><b>Apple Safari</b> <a className="textLink" href="https://support.apple.com/kb/ph21411?locale=en_US" target="_blank" rel="noreferrer">https://support.apple.com/kb/ph21411?locale=en_US  <img src={require("../../img/external-link-red.png")} alt="link" /></a></li>
                                    <li className="text"><b>Microsoft Edge</b> <a className="textLink" href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy" target="_blank" rel="noreferrer">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy  <img src={require("../../img/external-link-red.png")} alt="link" /></a></li>
                                    <li className="text"><b>Microsoft Internet Explorer</b> <a className="textLink" href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noreferrer">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies  <img src={require("../../img/external-link-red.png")} alt="link" /></a></li>
                                    <li className="text"><b>Opera</b> <a className="textLink" href="https://www.opera.com/help/tutorials/security/privacy/" target="_blank" rel="noreferrer">https://www.opera.com/help/tutorials/security/privacy/  <img src={require("../../img/external-link-red.png")} alt="link" /></a></li>        
                                </ul>
                            </li>
                        </ol>
                    </div>

                    <div className="tableStyle">
                        <span className="caption">SECTION 5 - WHAT KIND OF COOKIES DO WE USE? </span><br /><br />       
                        <p className="text">The specific types of first and third party cookies served through our Websites and the purposes they perform are described in the table below:</p>         
                        <table className="alternatecolor  subscription-list" id="alternatecolor">
                            <tr className="policy-table">
                                <th aligne="center" className="text">Types of cookie</th>
                                <th aligne="center" className="text">Who serves these cookies</th>
                                <th aligne="center" className="text">Purpose</th>        
                            </tr>
                            <tr className="policy-table">
                                <td className="text">Strictly Necessary or Essential Website Cookies</td>
                                <td className="text">SoftMouse.NET</td>
                                <td className="text">These cookies are strictly necessary to provide you with services available through our websites and to use some of its features, such as access to secure areas. Because these cookies are strictly necessary to deliver the websites, you cannot refuse them without impacting how our websites function. We suggest not to disable these cookies.</td>
                           </tr>
                            <tr className="policy-table">
                                <td className="text">Performance & Functionality Cookies</td>
                                <td className="text">SoftMouse.NET</td>
                                <td className="text">These cookies are used to enhance the performance and functionality of our Websites but are non-essential to their use. However, without these cookies, certain functionality may become unavailable. We suggest not to disable these cookies.</td>
                            </tr>
                            <tr className="policy-table">
                                <td className="text">Analytics and Customization Cookie</td>
                                <td className="text">Google Analytics</td>
                                <td className="text">These cookies collect information that is used either in aggregate form to help us understand how our Websites are being used or how effective are marketing campaigns are, or to help us customise our Websites for you in order to enhance your experience.<br />
                                    Learn more about how Google uses your data.<br />
                                    <a className="text link" href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noreferrer">support.google.com/analytics/answer </a><img src={require("../../img/external-link-red.png")} alt="link-red" /></td>
                            </tr>
                            <tr className="policy-table">
                                <td className="text">Analytics and Customization Cookie</td>
                                <td className="text">Wistia</td>
                                <td className="text">These cookies collect information about the usage of videos including how many were viewed by a particular user, from where and how many times it was viewed.</td>
                            </tr>
                            <tr className="policy-table">
                                <td className="text">Analytics and Customization Cookie</td>
                                <td className="text">Mailchimp</td>
                                <td className="text">These cookies collect information about when subscribers have opened an email or clicked certain links.</td>
                            </tr>
                            <tr className="policy-table">
                                <td className="text">Analytics and Customization Cookie</td>
                                <td className="text">Cloudflare</td>
                                <td className="text">These cookies are used to identify malicious visitors, errors, reduce the chance of blocking legitimate users, and to provide customized services.</td>
                            </tr>
                            <tr className="policy-table">
                                <td className="text">Analytics and Customization Cookie</td>
                                <td className="text">Sumo Logic</td>
                                <td className="text">These cookies are used to analyze trends, administer the site and track users’ movements around the site to improve site functionality.</td>
                            </tr>
                            <tr className="policy-table">
                                <td className="text">Analytics and Customization Cookie</td>
                                <td className="text">Stackdriver</td>
                                <td className="text">These cookies provide site monitoring, logging and diagnostics of the website.</td>
                            </tr>        
                        </table>       
                    </div>

                    <div className="subscription-list">
                        <span className="caption">SECTION 6 - THIRD PARTY AND SOCIAL MEDIA COOKIES</span><br /><br />       
                        <p className="text">If you click on a hyperlink from Iseehear Inc. Life Sciences websites to any third-party websites (for example, if you 'share' content from Iseehear Inc. Life Science’s websites with friends or colleagues through social networks), you may be sent cookies from these third-party websites.</p>
                        <p className="text">Iseehear Inc. Life Sciences Inc. does not assume responsibility for the content or information practices of these other websites, and we strongly encourage all web visitors to review the privacy statements and policies of all externally-linked sites.</p>
                    </div>

                    <div className="subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <span className="caption">SECTION 7 - CHANGES TO COOKIE POLICY</span><br /><br />
                        <p className="text">You can review the most current version of the Cookie Policy at any time at this page.</p>
                        <p className="text">We reserve the right, at our sole discretion, to update, change or replace any part of this Cookie Policy by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to this Cookie Policy constitutes acceptance of those changes.</p>
                        <p className="text">IN THE EVENT THAT YOU DO NOT AGREE TO ANY CHANGE, PLEASE DO NOT ACCESS OR USE THE SERVICES IN ANY MANNER FOR ANY PURPOSE.</p>
                    </div>

                    <div className="subscription-list">
                        <span className="caption">SECTION 8 - CONTACT INFORMATION</span><br /><br />   
                        <p className="text">Questions about the Cookie Policy should be sent to us at:</p><br /><br />
                        <p className="text">ISEEHEAR INC. LIFE SCIENCES</p><br />
                        <p className="text">250 University Avenue</p><br />
                        <p className="text">Suite 200</p><br />
                        <p className="text">Toronto, Ontario</p><br />
                        <p className="text">Canada M5H 3E5</p><br />
                        <p className="text">privacy@iseehear.com</p><br />
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}