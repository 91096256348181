import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const IntelectualProperty = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Intellectual Property</title>
                <meta name="description" content="Our systems logically isolate each customer’s data from that of other customers, even when the data is stored on the same physical server. For our employees, access rights and levels are based on their job function and role, using the concepts of least-privilege and need-to-know to match access privileges to defined responsibilities." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="intellectualProperty">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Intellectual Property Research Data Rights Policy</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                        </div>
                    </div>

                    <div id="customer-data" className="subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <span className="caption">Intellectual Property Research Data Rights Policy</span><br /><br />
                        <p className="text">First and foremost, the research data that our customers enter into the SoftMouse.NET database is their proprietary data and each customer maintains and retains full ownership of their data at all times. The Iseehear Inc. Life Sciences <Link className="textLink" to="/documents/subscription+agreement.pdf" target="_blank" rel="noreferrer">Subscription Agreement</Link> addresses customer data rights in these sections:</p><br /><br />
                        <ol className="data_list">
                            <li className="text">Article 2.2 Nature of Services</li>
                            <ul>
                                <li className="text">2.2b</li>
                                <li className="text">2.2d</li>
                            </ul>
                            <li className="text">SCHEDULE A - TERMS OF USE</li>
                            <ul>
                                <li className="text">Intellectual Property</li>
                            </ul>
                        </ol><br />
                        <p className="text">Our systems logically isolate each customer’s data from that of other customers, even when the data is stored on the same physical server. For our employees, access rights and levels are based on their job function and role, using the concepts of least-privilege and need-to-know to match access privileges to defined responsibilities.</p><br /><br />
                        <p className="text">To ensure the confidentiality, integrity, and availability of customer data, internal access to customer data is limited to three individuals at Iseehear Inc. Life Sciences: the CEO, Customer Service Manager and Cloud Operations Manager (Cloud Admin).</p><br /><br />
                        <p className="text">Internal access to customer research data is dictated by our Security Policy on Internal Access to Customer Data.</p><br /><br />

                            <h3 className="subCaption">Security Policy on Internal Access to Customer Data:</h3><br />
                              <ol className="data_list">
                                  <li className="text">Customer data is only accessed in the event of a support-related inquiry that cannot otherwise be addressed via email/telephone troubleshooting or screen sharing.</li>
                                  <li className="text">Only three members of the SoftMouse.NET Support Desk Team have the ability to access customer data.</li>
                                  <li className="text">Before customer data is accessed, the customer must provide data access consent.</li>
                                  <li className="text">In order to provide data access consent the customer must change their Access Permissions for Intellectual Property and Research Data Rights:</li>
                                  <ol>
                                      <li className="text">1. Customer logs into their SoftMouse.NET account</li>
                                      <li className="text">2. Go to the “My Profile”</li>
                                      <li className="text">3. Scroll down to “Access Permissions for Intellectual Property Research and  Data Rights” section</li>
                                      <li className="text">4. Select “Yes” in the drop-down menu (“No” is automatically selected by default)</li>
                                      <li className="text">5. Confirm your selection (in the pop-up window on the screen)</li>
                                      <li className="text">6. Then click save button and a confirmation email will be sent to the customer</li>

                                  </ol>
                                  <li className="text">Once permission has been granted by the customer, their data can then be accessed if necessary by the Support Desk team in order to address a specific customer issue or request.</li>
                              </ol><br />
                            <h3 className="subCaption">Research Data Portability</h3><br /><br />
                            <p className="text">All or a subset of customer data can be exported into spreadsheet or PDF format at any time via Export buttons on the application interface (e.g. cage cards, reports, spreadsheets). Review <a className="textLink" href="https://www.softmouse.net/security-data-backup-options.jsp" target="_blank" rel="noreferrer">Data Backup Options</a> here.</p>
                            <br /><br />
                            <p className="text">In the event of contract / subscription termination Iseehear Inc. Life Sciences will provide a backup of customer data in a spreadsheet format. If there is a written request for the data to be deleted, a letter confirming deletion will be provided.</p>
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}