import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const WorkingFromHome = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Working From Home</title>
                <meta name="description" content="We understand that you, your lab, your team are making the necessary updates to your research planning. As these decisions trickle down to the management and maintenance of your animal colonies, we appreciate the enormity and difficulty of these decisions, along with the scientific and emotional toll that comes with it." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="workingFromHome">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Useful information to assist you while working from home or remotely</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                        </div>
                    </div>

                    <div className="usefulInformation">
                        <p className="text">We are here to assist you in these challenging times by offering reliable help when you need it the most.</p>
                        <p className="text">We understand that you, your lab, your team are making the necessary updates to your research planning. As these decisions trickle down to the management and maintenance of your animal colonies, we appreciate the enormity and difficulty of these decisions, along with the scientific and emotional toll that comes with it.</p>
                        <p className="text">It is our goal to make your experience as productive and hassle free as possible. You’ll find below frequently asked questions that have been coming in to our support team over the past few weeks. We hope that you find this information helpful!</p>
                        <p className="text">Please rest assured that our support desk is open and we are ready to help:</p>
                        <p className="text">- Call us at 1 844 559 6322 (toll free) or +1 416 492 4358</p>
                        <p className="text">- Email us at <a className="textLink" href="https://www.softmouse.net/softmouse-mouse-colony-management-software-database-lims-contacts.jsp" target="_blank" rel="noreferrer">support@softmouse.net</a></p>
                        <p className="text">- Request an <a className="textLink" href="https://www.softmouse.net/softmouse-mouse-colony-management-software-database-lims-contacts.jsp" target="_blank" rel="noreferrer">online Zoom meeting</a></p>
                        <p className="text">Stay safe and healthy,</p>
                        <p className="text">SoftMouse.NET Support Team</p>
                        <p className="text">Iseehear Inc. Life Sciences</p>
                    </div>
                    
                    <h2 className="caption">Frequently asked questions:</h2>
                    <div id="ARTICLE11" className="subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <div className="accordion">
                          <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne11" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne11">
                              <h3 className="subCaption">1. How can I work from home or remotely safely and comfortably?</h3>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseOne11" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne11">
                            <div className="accordion-body">           
                                <p className="text"><span style={{color: "lightseagreen"}}>Tip:</span> Please visit our Tips for Working from Home or Remotely <a className="textLink" href="https://www.softmouse.net/tips-for-working-remotely.jsp" target="_blank" rel="noreferrer">page</a>.</p>                           
                            </div>
                          </div>
                        </div>
                        <div className="accordion">
                          <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo11" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo11">
                                <h3 className="subCaption">2. Are you working from home or remotely and can’t remember your login information?</h3>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseTwo11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo11">
                            <div className="accordion-body">
                                <p className="text">Many of you may have your SoftMouse.NET login credentials saved on your work computer...but not your home computer!</p>
                                <p className="text"><span style={{color: "lightseagreen"}}>Tip:</span> You can easily reset your password by clicking the "Forgot Password" link within the SoftMouse.NET login box OR <a className="textLink" href="https://www.softmouse.net/softmouse-mouse-colony-management-software-database-lims-contacts.jsp" target="_blank" rel="noreferrer">contact our support team</a> for assistance.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion">
                          <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree11" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree11">
                                <h3 className="subCaption">3. What’s the best way to handle rotating teams?</h3>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseThree11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree11">
                            <div className="accordion-body">
                                <p className="text">Many labs and animal care departments have adopted the strategy of having rotating teams go to the animal facility to take care of the animals and manage the colonies. Having these kinds of tag teams helps to ensure physical distancing as well as making sure teams don’t burn out.</p>  
                                <p className="text"><span style={{color: "lightseagreen"}}>Tip:</span> You can temporarily allow for relaxed permission settings on your account to ensure the right people have the edit capability they need to make updates to the records.</p>
                                <p className="text">Have your SoftMouse.NET admin make the necessary changes to the access permissions on the account OR <a className="textLink" href="https://www.softmouse.net/softmouse-mouse-colony-management-software-database-lims-contacts.jsp" target="_blank" rel="noreferrer">contact our support team</a> for assistance with this.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion">
                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFoure11" aria-expanded="false" aria-controls="panelsStayOpen-collapseFoure11">
                                  <h3 className="subCaption">4. How can we use SoftMouse.NET to assist in scaling down the colony?</h3>
                              </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFoure11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFoure11">
                                <div className="accordion-body">
                                    <p className="text">Different locations are putting in place different policies for what needs to happen with the research animals in these difficult times. Many are suggesting that breeding pairs should be separated and only essential animals be kept in a maintenance mode. Cryopreservation is also being suggested for certain strains.</p>  
                                    <p className="text"><span style={{color: "lightseagreen"}}>Tip:</span> Use the <a className="textLink" href="https://softmousefaq.com/Customize+Drop+Down+Lists" target="_blank" rel="noreferrer">customizable Cage Status</a> as a way of marking Priority Cages so that the rotating teams know which cages are marked to save.</p>
                                    <p className="text">Did you know that you can archive mouselines? If you have chosen to cryopreserve a mouseline, on your mouseline list, you can easily select the lines in question and click the Archive button. This will simply remove the line from the main list making it easy to see what is available. You can have peace of mind that all past information for this line will be in the archive and <a className="textLink" href="https://softmousefaq.com/Filter%20for%20Archived%20Mouselines" target="_blank" rel="noreferrer">readily restored</a> when your research requires.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion">
                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive11" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive11">
                                  <h3 className="subCaption">5. In the event that my administrative/financial colleagues are not as responsive as usual, how do I ensure that I have continuous access to my SoftMouse.NET account if my subscription is up for renewal or I want to sign up?</h3>
                              </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFive11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive11">
                              <div className="accordion-body">
                                  <p className="text">We understand administrative processes might not be running as usual with the current circumstances and we would like to help as much as we can.</p>
                                  <p className="text"><span style={{color: "lightseagreen"}}>Tip:</span> A simple written confirmation of renewal would allow us to guarantee continuous access to your SoftMouse.NET account while you go through the payment process. Please simply update us as soon as you know more information. All communication regarding your SoftMouse.NET subscription should be directed to: subscriptions@iseehear.com</p> 
                              </div>
                            </div>
                        </div>
                        <div className="accordion">
                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix11" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix11">
                                  <h3 className="subCaption">6. With my team now working from home, does SoftMouse.NET provide training for continued learning?</h3>
                              </button>
                            </h2>
                            <div id="panelsStayOpen-collapseSix11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix11">
                                <div className="accordion-body">
                                    <p className="text">Absolutely! With everyone having increased working from home hours, why not dedicate some time to learning about the latest and upcoming functionality that SoftMouse.NET offers to streamline your tasks?</p>  
                                    <p className="text"><span style={{color: "lightseagreen"}}>Tip:</span> <a className="textLink" href="https://softmousetraining.com/webinars" target="_blank" rel="noreferrer">Click HERE</a> to see and register for our upcoming online webinars.</p>  
                                    <p className="text">We will be hosting 2-3 ~30 minute webinars every week. You can expect to learn about:</p>
                                    <ul className="article">
                                        <li className="text">Advanced SoftMouse.NET training/tips</li>
                                        <li className="text">Doing more with SoftMouse.NET</li>                                   
                                    </ul>  
                                </div>
                            </div>
                        </div>
                        <div className="accordion">
                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven11" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven11">
                                  <h3 className="subCaption">7. What measures is SoftMouse.NET taking to protect my data?</h3>
                              </button>
                            </h2>
                            <div id="panelsStayOpen-collapseSeven11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven11">
                              <div className="accordion-body">
                                  <p className="text">We understand the gravity of these uncertain times and appreciate the concern over the security and protection of your research data.</p>
                                  <p className="text">The security measures and disaster recovery policies that we already have in place allow us to ensure that your data is monitored, backed up, secured and retrievable at all times. <a className="textLink" href="emergencyplan.html">Read more HERE</a>.</p> 
                                  <p className="text"><span style={{color: "lightseagreen"}}>Tip:</span> If you would like to receive an automated back up of your data by email either weekly or monthly, please <a className="textLink" href="https://www.softmouse.net/softmouse-mouse-colony-management-software-database-lims-contacts.jsp" target="_blank" rel="noreferrer">contact our support team</a> and we will gladly assist you with setting this up.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 className="caption">COVID-19 Information & Links to Various Health Agencies</h2>
                    <h3 className="subCaption"><span style={{color: "lightseagreen"}}>Note:</span> For specific information where you live or work, please refer to your local public health agency.</h3>
                    <div className="google-list">
                        <h2 className="subCaption">World Health Organization (WHO):</h2>
                        <p className="text">World Health Organization: Coronavirus disease (COVID-19) advice for the public<br />
                        <a className="google-link textLink" href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public" target="_blank" rel="noreferrer">https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />
                    </div>
                 
                    <div className="google-list">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                        <h2 className="subCaption">North, Central and South America:</h2>
                        <p className="text">United States CDC: Watch for symptoms<br />
                        <a className="google-link textLink" href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html" target="_blank" rel="noreferrer">https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />

                        <p className="text">United States CDC: How to Protect Yourself<br />
                        <a className="google-link textLink" href="https://www.cdc.gov/coronavirus/2019-ncov/prepare/prevention.html" target="_blank" rel="noreferrer">https://www.cdc.gov/coronavirus/2019-ncov/prepare/prevention.html <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />

                        <p className="text">United States Mayo Clinic: Coronavirus disease 2019 (COVID-19)<br />
                        <a className="google-link textLink" href="https://www.mayoclinic.org/diseases-conditions/coronavirus/symptoms-causes/syc-20479963" target="_blank" rel="noreferrer">https://www.mayoclinic.org/diseases-conditions/coronavirus/symptoms-causes/syc-20479963 <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />


                        <p className="text">United States WebMD: Know the Symptoms of COVID-19<br />
                        <a className="google-link textLink" href="https://www.webmd.com/lung/covid-19-symptoms#1" target="_blank" rel="noreferrer">https://www.webmd.com/lung/news/20200310/know-the-symptoms-of-covid19 <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />

                        <p className="text">Government of Canada: Coronavirus (COVID-19)<br />
                        <a className="google-link textLink" href="https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19.html?utm_campaign=not-applicable&utm_medium=vanity-url&utm_source=canada-ca_coronavirus" target="_blank" rel="noreferrer">https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19.html?utm_campaign=not-applicable&utm_medium=vanity-url&utm_source=canada-ca_coronavirus <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />
                    
                        <p className="text">Brazil Ministry of Health: Últimas notícias coronavirus<br />
                        <a className="google-link textLink" href="https://agenciabrasil.ebc.com.br/en/tags/coronavirus-0" target="_blank" rel="noreferrer">https://agenciabrasil.ebc.com.br/en/tags/coronavirus-0 <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />

                        <p className="text">Chile Ministry of Health: Coronavirus: qué es y cómo combatirlo<br />
                        <a className="google-link textLink" href="https://www.chileatiende.gob.cl/fichas/76884-coronavirus-que-es-y-como-combatirlo" target="_blank" rel="noreferrer">https://www.chileatiende.gob.cl/fichas/76884-coronavirus-que-es-y-como-combatirlo <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />

                        <p className="text">Costa Rica Ministry of Health: Lineamientos Nacionales para la Vigilancia de la infección por Coronavirus (COVID-19)<br />
                        <a className="google-link textLink" href="https://www.ministeriodesalud.go.cr/index.php/centro-de-prensa/noticias/741-noticias-2020/1532-lineamientos-nacionales-para-la-vigilancia-de-la-infeccion-por-coronavirus-2019-ncov" target="_blank" rel="noreferrer">https://www.ministeriodesalud.go.cr/index.php/centro-de-prensa/noticias/741-noticias-2020/1532-lineamientos-nacionales-para-la-vigilancia-de-la-infeccion-por-coronavirus-2019-ncov<img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />
                    </div>

                    <div className="google-list">
                        <h2 className="subCaption">Europe and the United Kingdom:</h2>
                        <p className="text">European Centre for Disease Prevention and Control: Novel coronavirus<br />
                        <a className="google-link textLink" href="https://www.ecdc.europa.eu/en" target="_blank" rel="noreferrer">https://www.ecdc.europa.eu/en <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />

                        <p className="text">Italy Ministry of Health: Coronavirus COVID-19<br />
                        <a className="google-link textLink" href="https://www.salute.gov.it/portale/nuovocoronavirus/homeNuovoCoronavirus.jsp" target="_blank" rel="noreferrer">http://www.salute.gov.it/nuovocoronavirus <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />

                        <p className="text">France Ministry of Health: Coronavirus COVID-19<br />
                        <a className="google-link textLink" href="https://solidarites-sante.gouv.fr/" target="_blank" rel="noreferrer">https://solidarites-sante.gouv.fr/ <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />


                        <p className="text">Netherlands Ministry of Health: Coronavirus COVID-19<br />
                        <a className="google-link textLink" href="https://www.government.nl/topics/coronavirus-covid-19" target="_blank" rel="noreferrer">https://www.government.nl/topics/coronavirus-covid-19 <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />

                        <p className="text">UK Government (NHS): Coronavirus (COVID-19)<br />
                        <a className="google-link textLink" href="https://www.nhs.uk/conditions/coronavirus-covid-19/" target="_blank" rel="noreferrer">https://www.nhs.uk/conditions/coronavirus-covid-19/ <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />
                    </div>

                    <div className="google-list">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <h2 className="subCaption">Asia and Pacific:</h2>
                        <p className="text">Australia Department of Health: Coronavirus (COVID-19) Health Alert<br />
                        <a className="google-link textLink" href="https://www.health.gov.au/health-alerts/covid-19" target="_blank" rel="noreferrer">https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />

                        <p className="text">India Ministry of Health: Novel Corona Virus<br />
                        <a className="google-link textLink" href="https://www.mohfw.gov.in/" target="_blank" rel="noreferrer">https://www.mohfw.gov.in/ <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />

                        <p className="text">Japan Ministry of Health: About Coronavirus Disease 2019 (COVID-19)<br />
                        <a className="google-link textLink" href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/newpage_00032.html" target="_blank" rel="noreferrer">https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/newpage_00032.html <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />


                        <p className="text">New Zealand Ministry of Health: COVID-19 (novel coronavirus)<br />
                        <a className="google-link textLink" href="https://www.health.govt.nz/covid-19-novel-coronavirus" target="_blank" rel="noreferrer">https://www.health.govt.nz/our-work/diseases-and-conditions/covid-19-novel-coronavirus <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />

                        <p className="text">Singapore Ministry of Health: Updates on COVID-19<br />
                        <a className="google-link textLink" href="https://www.moh.gov.sg/" target="_blank" rel="noreferrer">https://www.moh.gov.sg/ <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />
                    
                        <p className="text">South Korea Ministry of Health: Regular Briefing of Central Disaster and Safety<br />
                        <a className="google-link textLink" href="https://www.mohw.go.kr/eng/" target="_blank" rel="noreferrer">https://www.mohw.go.kr/eng/ <img src={require("../../img/external-link-red.png")} alt="link" /></a></p><br /><br />
                    </div>
                    <p className="text">Note to the reader: We were not able to locate the relevant websites for several national governments' Ministry of Health.</p>
                </div>
            </section>
            <Footer />
        </div>
    )
}