import React from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


export const Index = () => {
     return (
        <div className="wrapper wrapperWelcome">
            <Helmet>
                <title>Iseehear Life Sciences - Data Security Information</title>
                <meta name="description" content="Iseehear Inc. Security stack and Information Security Management Policies were established in order to protect our subscriber's Data, Intellectual Property and Privacy." />
                <meta http-equiv="keywords" content="Iseehear, Security, Policies, protect, subscriber's, Data, Intellectual Property, Privacy" />
            </Helmet>
            <img className="mainPageBack" src={require("../../img/newPage/2.jpg")} alt="SecurityImg" />
            <Header logo={require("../../img/security-logo.png")} errowColor={"white"} />

            <section className="welcome">
                <div className="container iseehearsec-description">

                    <h1 class="mainCaption">Iseehear Life Sciences</h1>
                    <h1 class="mainCaption">Office of the Chief Security Officer (OCSO)</h1>
                    <div className="content-section-b">
                        <hr style={{borderBottom: "2px solid white"}} />
                        <div className="row main-content">                     
                            <div className="helthStatus">
                            <h3 class="text"><br/>Iseehear Inc. is at the forefront of cybersecurity innovation in the biomedical life sciences sector,
                            driven by our Office of the Chief Security Officer (OCSO). Under the leadership of seasoned
                            executives, the OCSO embodies Iseehear's commitment to safeguarding our customers and our information assets,
                            ensuring regulatory compliance, and nurturing a culture of cybersecurity awareness across the
                            organization. The OCSO's comprehensive approach to cybersecurity is built on a foundation of proactive
                            protection, strategic governance, and resilient business support, allowing us to anticipate and
                            navigate the evolving landscape of cyber threats effectively.</h3>
                            </div>                      
                        </div>
                    </div>
                    <Link to="/ocsoexecutivesummary"><button className="learnMoreButton">Learn more about Iseehear OCSO</button></Link>
                </div>
            </section>

            <div className="container securityControls">
            <div className="row data-caption">
                    <div className="col-lg-9">
                        <h3 id="rolesAndResponsibilities" className="mainCaption">The Roles and Responsibilities of The Iseehear Office of the Chief Security Officer (OCSO)</h3>
                        <hr style={{borderBottom: "2px solid lightblue"}} />
                    </div>
                    <p className="text">The Iseehear IT Security Team (Iseehear Security) 
                        is responsible for the evaluation, development, implementation 
                        and monitoring of information technology infrastructure and 
                        computer assets, privacy and data security policies and programs 
                        for Iseehear Inc. Life Sciences. Iseehear Security manages various 
                        cloud security testing environments for conducting internal testing 
                        in order to probe for vulnerabilities in the company’s cloud infrastructure, 
                        platforms and applications.
                    </p>
                    <p className="text" style={{marginTop: "2rem"}} >Iseehear Security conducts security education sessions, 
                        annual penetration tests (conducted by a third party), monitors for 
                        threats and vulnerabilities, evaluates internal and external threats, 
                        assess the security posture of Iseehear websites, advise management with 
                        timely and appropriate security insights.
                    </p>
                    <Link style={{marginTop: "1rem"}} className="textLink" to="/scheduledSecurityTestingProcedures">Learn more about Iseehear Security Cloud Infrastructure, Platforms and Applications Security Testing</Link>
                </div>

                <div className="row data-caption">
                    <div className="col-lg-9">
                        <h3 id="ourSecurityControls" className="mainCaption">Our Security Controls</h3>
                        <hr style={{borderBottom: "2px solid lightblue"}} />
                    </div>
                </div>

                <div id="dataSecurityOverview" className="protectingYourData mtop">
                    <div className="protectingDataDescription">
                        <h2 className="caption mtop">Data Security Overview</h2>
                        <p className="text">At Iseehear Life Sciences | Security (ISHSec), the security of your data has always been a priority. We are committed to protecting and securing your data as you use each of our services.</p>
                        <p className="text"><Link className="textLink" to="/securityOverview">Continue reading about DATA SECURITY OVERVIEW</Link>.</p>
                        <h2 id="protectingYourData" className="caption mtop">Protecting Your Data</h2>
                        <p className="text">The aim of security practices is to prevent any unauthorized access to customer data.</p>
                        <p className="text">We are always looking at ways in which we can improve the security of our applications and continuously work to identify, monitor, and mitigate risks in our environment.</p>
                        <p className="text">Is committed to constantly maintaining knowledge of the evolving application security landscape and implementing controls designed to ensure that security best practices are upheld across our organization.</p>
                        <p className="text">Regular management security reviews are in place to address any areas that we believe should be improved upon and further secured. We implement security controls as described in this document and may modify them from time to time by pursuing new security certifications, evaluating our compliance posture, or through the use of third-party testing from time to time.</p>
                    </div>
                    <div>
                        <ul className="securityHighlights">
                            <li className="bgBlue">
                                <p className="caption">Security Highlights</p>
                            </li>
                            <li>
                                <p className="text">Least Privilege Access</p>
                                <img className="check" src={require("../../img/correct.png")} alt="check" />
                            </li>
                            <li>
                                <p className="text">Full Daily Backups</p>
                                <img className="check" src={require("../../img/correct.png")} alt="check" />
                            </li>
                            <li>
                                <p className="text">Data Encrypted in Transit and at Rest</p>
                                <img className="check" src={require("../../img/correct.png")} alt="check" />
                            </li>
                            <li>
                                <p className="text">Single Sign On (SSO)*</p>
                                <img className="check" src={require("../../img/correct.png")} alt="check" />
                            </li>
                            <li>
                                <p className="text">Vulnerability Scanning</p>
                                <img className="check" src={require("../../img/correct.png")} alt="check" />
                            </li>
                            <li>
                                <p className="text">Mitigating Common Attacks</p>
                                <img className="check" src={require("../../img/correct.png")} alt="check" />
                            </li>
                            <li>
                                <p className="text">Annual Penetration Testing</p>
                                <img className="check" src={require("../../img/correct.png")} alt="check" />
                            </li>
                            <li className="bgBlue">
                                <p className="text">*Please contact itsecurity@iseehear.com</p>
                            </li>                               
                        </ul>
                    </div>
                </div>

                <div id="privacyPolicy" className="mtop">
                    <div className="protectingYourData">
                        <div className="protectingDataDescription mtop">
                            <h2 className="caption">Privacy Policy</h2>
                            <div>
                                <div>
                                    <p className="text">Privacy Policy describes how your personal information is collected, used, and shared when you visit or register at ISEEHEAR.COM (the “Site”).
                                        At Iseehear Inc. Life Sciences (Iseehear) we are committed to protecting your privacy. Iseehear endeavours to ensure that the information that you submit to us remains private and that it is used only for the services we provide to you. We aim to provide a safe and secure experience for all of our users.
                                        Respecting and protecting your personal data privacy is important to us. So we have updated our Privacy Policy as well as Terms of Use on May 25, 2018 to make our policies even more transparent as well as compliant with new privacy regulations being enacted in various countries and regions around the world 
                                        (for example PIEPEDA in Canada, GDPR in Europe, PIPA in South Korea, DPB in the United Kingdom, PDPA in Singapore, …). The majority of the regional data protections and privacy regulations (enacted, being revised or pending) have some common expectations and requirements regarding the transparency, control,
                                        securing and responsibility of personally identifiable information collected by companies providing digital or internet centric services to their citizens. <Link className="textLink" to="/policiesOverview">Continue reading about Privacy Policy</Link>.</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ul className="securityHighlights">
                                <li className="bgBlue">
                                    <p className="caption">Security Links</p>
                                </li>
                                <li>
                                    <p className="text"><Link className="textLink" to="/securityOverview">Data Security</Link></p>
                                    <img className="check" src={require("../../img/link.png")} alt="link" />
                                </li>
                                <li>
                                    <p className="text"><Link className="textLink" to="/policiesOverview">Policies</Link></p>
                                    <img className="check" src={require("../../img/link.png")} alt="link" />
                                </li>
                                <li>
                                    <p className="text"><Link className="textLink" to="/communication">Communications</Link></p>
                                    <img className="check" src={require("../../img/link.png")} alt="link" />
                                </li>
                                <li>
                                    <p className="text"><Link className="textLink" to="/emergencyPlan">Emergency</Link></p>
                                    <img className="check" src={require("../../img/link.png")} alt="link" />
                                </li>                            
                            </ul>
                        </div>
                    </div>
                </div>

                <div id="physicalSecurity" className="mtop">
                    <h2 className="caption">Physical Security</h2>
                    <p className="text">We have engaged Google Cloud Platform (“GCP”) to provide cloud hosting for the Services. A summary of the controls in place at GCP facilities and environments is set out below (as described by GCP). <Link className="textLink" to="/googleCloudPlatform">Continue reading about Google Cloud Platform Security</Link>.</p>
                    <div className="descriptionBox mtop encryption_list">
                        <div className="padding">
                            <h3 className="subCaption">Data Center Facilities</h3>
                            <p className="text">GCP operates ISO27001, PCI DSS Level 1 & SOC 2 Type compliant data centers. Automated fire detection and suppression systems are installed in networking, mechanical, and infrastructure areas. All GCP data centers are constructed to N+1 redundancy standards.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Server Monitoring</h3>
                            <p className="text">GCP's global security operation centers conduct 24/7 monitoring of data center access activities, with electronic intrusion detection systems installed in the data layer.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Hard Perimeter</h3>
                            <p className="text">Each of GCP' data centers have a controlled perimeter layer with 24/7 on-site security teams, restricted and controlled physical access, multi-factor authentication, electronic intrusion detection systems and door alarming.</p>
                        </div>
                    </div>
                </div>

                <div id="networkSecurity" className="mtop">
                    <h2 className="caption">Network Security</h2>
                    <div className="descriptionBox mtop encryption_list">
                        <div className="padding">
                            <h3 className="subCaption">Architecture</h3>
                            <p className="text">We employ GCP security groups and IAM controls to lock down communication between components so access to Services must be granted explicitly on an as-needed basis.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">DDoS Mitigation, Content Delivery, and Internet Security Monitoring</h3>
                            <p className="text">ISHSec system audit logs are maintained and checked for anomalies, and we use GCP services to protect from distributed attacks.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Least Privilege Access</h3>
                            <p className="text">Access to hosting servers for the Services and live environments as well as testing and sandbox environments are provided on least privilege access. A very limited number of personnel have access to live, testing and sandbox environments, which also require multiple levels of security access.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Security Incident Response</h3>
                            <p className="text">ISHSec continually monitors our cloud services and has response teams on call 24/7 to respond to security incidents. Our hosting provider, GCP, as well as ISHSec internal security provides 24/7 monitoring and support.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Penetration Testing</h3>
                            <p className="text">ISHSec conducts a third-party penetration test annually or after any major changes to the platform.</p>
                        </div>
                    </div>
                </div>

                <div id="platformApplicationSecurity" className="mtop">
                    <h2 className="caption">Platform & Application Security</h2>
                    <div className="descriptionBox mtop encryption_list">
                        <div className="padding bgBlue">
                            <h3 className="subCaption">Development</h3>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Quality Assurance</h3>
                            <p className="text">We have teams of individuals who review and test all changes to our code base. For every update or release to the software, testing is performed by development, QA, project management and security teams with a multi-level approach.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Separate Environments</h3>
                            <p className="text">We maintain separate environments for both testing, sandboxing and production. These environments are logically separated from the live production environment. No customer data is used in testing or development.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Vulnerability Scanning</h3>
                            <p className="text">In addition to application penetration testing, unit testing, human auditing, static analysis, and functional tests, we perform weekly third-party vulnerability scans of our test, sandbox and production environments.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Mitigating Common Attacks (XSS, CSRF, SQLi)</h3>
                            <p className="text">Our tools have been built to mitigate common attack vectors such as SQL injection attacks and cross-site scripting attacks (XSS). ISHSec cloud environments also take advantage of GCP’ enterprise-grade Web Application Firewall (WAF) in an attempt to automatically block or challenge suspicious requests.</p>
                        </div>
                    </div>
                </div>

                <div className="mtop">
                    <div className="descriptionBox mtop encryption_list">
                        <div className="padding bgBlue">
                            <h3 className="subCaption">Data Encryption</h3>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Data at Rest</h3>
                            <p className="text">All customer data is stored encrypted on GCP servers with the <Link className="textLink" to="/softmouseEncryption">AES-256</Link> encryption algorithm.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Data in Transit</h3>
                            <p className="text">Any data that is transmitted into and from the ISHSec Services is encrypted. Web traffic over HTTP is secured by GCP as well as Cloudflare with TLS 1.2 or 1.3 using proven-secure cipher suites.</p>
                        </div>
                    </div>
                </div>

                <div className="mtop">
                    <div className="descriptionBox mtop encryption_list">
                        <div className="padding bgBlue">
                            <h3 className="subCaption">Software</h3>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Single Sign On</h3>
                            <p className="text">Depending on how many licenses you have purchased, you may have access to an SSO option for the Services. Please contact us at itsecurity@iseehear.com if you have any questions.</p>
                        </div>
                    </div>
                </div>

                <div className="mtop">
                    <h2 className="caption">Availability & Security Incidents</h2>
                    <div className="descriptionBox mtop encryption_list">
                        <div className="padding">
                            <h3 className="subCaption">Uptime</h3>
                            <p className="text">ISHSec uses GCP to host its Services, and maintains an uptime promise from GCP of at least 99% (subject to scheduled downtime, emergency maintenance, and issues outside our or GCP’ control).</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Redundancy</h3>
                            <p className="text">ISHSec use GCP with redundancy over multiple availability zones, with database backups offering 35-days’ worth of point-in-time recovery, if needed. Additional encrypted off-site backups are updated daily.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Responding to Security Incidents</h3>
                            <p className="text">ISHSec has established procedures and policies with regards to responding and communicating about security incidents. The level of the security incident will dictate how we communicate and responding to our customers. If a security incident does occur which affects your personal information, we will inform you as required by applicable law. We annually reevaluate our responding procedures and amend them as we deem necessary.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Disaster Recovery and Business Continuity Plan</h3>
                            <p className="text">A business continuity plan has been put in place in the event an emergency or critical incident impacting any facet of ISHSec business operations, including the Services, occurs. This was created with the intent that we can continue to function as a business for our customers in the event of major disruptions. The business continuity plan is tested and checked on an annual basis for applicability and any additional improvements that could be made.</p>
                        </div>
                    </div>
                </div>

                <div className="mtop">
                    <h2 className="caption">Organizational Security</h2>
                    <div className="descriptionBox mtop encryption_list">
                        <div className="descriptionBoxItem padding bgBlue">
                            <h3 className="subCaption">Personnel & Endpoints</h3>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Workstation Set-Up</h3>
                            <p className="text">Every employee workstation is set up and monitored to ensure data is encrypted at rest, passwords are strong (managed by a secure password management vault), up-to-date OS patches, and active, up-to-date antivirus.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Confidentiality</h3>
                            <p className="text">We perform background checks on all new hires and on commencement of employment at ISHSec, and all personnel who have access to your personal information and Financial Information are required to execute nondisclosure agreements.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Security Training Program</h3>
                            <p className="text">All employees at ISHSec are required to participate in our security awareness training that focuses on helping each person understand the role they play in protecting data and preventing security breaches. Employees also are required to review the ISHSec security policies on a recurring annual basis.</p>
                        </div>
                    </div>
                </div>

                <div className="mtop">
                    <div className="descriptionBox mtop encryption_list">
                        <div className="descriptionBoxItem padding bgBlue">
                            <h3 className="subCaption">Vendor Management</h3>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Sub-Service Organizations</h3>
                            <p className="text">In order for ISHSec to run efficiently, we rely on sub-service organizations to help us deliver our Services. When selecting a suitable vendor for a required Service, we take the appropriate steps designed to ensure that the security and integrity of our Services is maintained. Every sub-service organization is scrutinized, tested, and security checked prior to being implemented into ISHSec.</p>
                        </div>
                        <div className="descriptionBoxItem padding">
                            <h3 className="subCaption">Vendor Compliance</h3>
                            <p className="text">ISHSec monitors the effectiveness of these vendors and they are reviewed annually to confirm security and safeguards are being upheld per the terms of our agreements with them.</p>
                        </div>
                    </div>
                </div>

                <div className="mtop">
                    <h2 className="caption">Terms and Conditions</h2>
                    <div>
                        <p className="text">Terms and conditions for use of the Site, which you may access in several ways, including but not limited to the World Wide Web, Internet enabled television, Computer, Intranet, Cellular phone and wireless mobile device. These terms and conditions apply whenever you access the Site, on whatever device. In these terms and conditions, when we say Iseehear.com we mean this Site or any of its associated network websites, regardless of how you access it. By using the Site, you are deemed to have accepted these conditions. <Link className="textLink" to="/termsOfUse">Continue reading about our Terms and Conditions</Link>.</p>
                    </div>
                </div>

                <div className="mtop">
                    <h2 className="caption">Accessibility</h2>
                    <div>
                        <p className="text">Digital Accessibility refers to how well people with visual, hearing, motor and or cognitive challenges can access our website content. Iseehear Inc. Life Sciences is committed to providing a website that is accessible to the widest possible audience. We want everyone who visits to feel welcome and find the experience useful. <Link className="textLink" to="/accessibility">Continue reading about our Accessibility.</Link></p>
                    </div>
                </div>

                <div className="row main-content content-section-b">
                    <div className="encryption_list"> <br />
                        <p className="text">
                        Use this site to stay informed on the health of Iseehear Inc. Life Sciences (<a className="textLink" href="http://www.ISHLS.Com" target="_blank" rel="noreferrer">ISHLS.Com</a>) services, cloud applications, mobile applications and networks.
                        </p>
                        <p className="text">
                        At Iseehear Inc. Life Sciences (<a className="textLink" href="http://www.ISHLS.Com" target="_blank" rel="noreferrer">ISHLS.Com</a>) our goal is to be as open and transparent with our clients as possible. To that end, we have implemented numerous communication resources thus allowing us to communicate timely information regarding services, scheduled events, security, policies and emergency situations that may arise from time to time-related to the services Iseehear Life Sciences provides.
                        </p>
                    </div>
                    
                </div>
            </div>
            <Footer />
        </div>
    )
}