import React from 'react';
import {FetchDataSections} from "../FetchData/FetchDataSections";
import axios from 'axios';
import { Component } from 'react';
import QuestionContext from '../../Context/GeneralContext';
import {POST_HECVAT} from "../ApiURL";

export default class UpdateForm extends Component {

    static contextType = QuestionContext
    
        state = {
            question: '',
            answer_boolean: '',
            answer: '',
            section_id: '',
            created_at: '',
            updated_at: '',
            link: '',
        };

        componentDidMount() {
            const data = this.props.questionData;
            this.setState ({
                question: data.question,
                answer_boolean: data.answer_boolean,
                answer: data.answer,
                section_id: data.section_id,
                created_at: data.created_at,
                updated_at: data.updated_at,
                link: data.link,
            })
        }

        hendleInputChange = (event) => {
            const {name, value} = event.target;
            this.setState({ [name]: value });
        }

        newFormSubmit=(e)=>{
            e.preventDefault();
    
            let data = {
                question:this.state.question,
                answer_boolean:this.state.answer_boolean,
                answer:this.state.answer,
                section_id:this.state.section_id,
                created_at:this.state.created_at,
                updated_at:this.state.updated_at,
                link:this.state.link,
            }
    
            axios.put(`${POST_HECVAT}/${this.props.questionData.id}`, data)
            .then(res => {
                this.setState({
                    sent: true,
                }, this.resetForm())
            })
            .catch(() => {
    
                this.resetForm()
            })

            this.context.forceUpdate()
        }

        // form Reseting initial data
        resetForm = () => {
            this.setState({
                question: "",
                answer_boolean: "",
                answer: "",
                section_id: "",
                created_at: "",
                updated_at: "",
                link: "",
            })
            
            setTimeout(() => {
                this.setState({
                    sent: false,
                })
            }, 3000)
        }

        render(){

                return (
                    <>
                    <form className="adminForm hideUpdateForm" onSubmit={this.newFormSubmit} >
                    <h2 className="caption">Edit this Question and Answer</h2>
                    <fieldset className="formGroup">
                        <div className="inputBlock">
                            <label className="textCaption" htmlFor="question">Enter NEW Qestion</label>
                            <textarea 
                                className="textArea text" 
                                type="text" 
                                id="question" 
                                name="question"
                                onChange={this.hendleInputChange}
                                value={this.state.question}
                            />
                        </div>
                        <div className="inputBlock">
                            <label className="textCaption" htmlFor="boolean">Choose The NEW Answer</label>
                            <select 
                                className="select text" 
                                type="select" 
                                id="boolean" 
                                name="answer_boolean"
                                onChange={this.hendleInputChange}
                                value={this.state.answer_boolean}
                            >
                                <option style={{borderBottom: "2px solid lightblue"}} className="text">Choose one of the NEW options</option>
                                <option className="text">Yes.</option>
                                <option className="text">No.</option>
                            </select>
                        </div>
                        <div className="inputBlock">
                            <label className="textCaption" htmlFor="answer">Enter The NEW Answer</label>
                            <textarea 
                                className="textArea text" 
                                type="text" 
                                id="answer" 
                                name="answer" 
                                onChange={this.hendleInputChange}
                                value={this.state.answer}
                            />
                        </div>
                        <div className="inputBlock">
                            <label className="textCaption" htmlFor="link">Put The Link</label>
                            <textarea 
                                className="textArea text" 
                                type="text" 
                                id="link" 
                                name="link" 
                                placeholder='Optional'
                                onChange={this.hendleInputChange}
                                value={this.state.link}
                            />
                        </div>
                        <div className="inputBlock">
                            <label className="textCaption" htmlFor="section">Choose the NEW Section</label>
                            <select 
                                className="select text" 
                                type="select" 
                                id="section" 
                                name="section_id"
                                onChange={this.hendleInputChange}
                                value={this.state.section_id}
                            >
                                <option style={{borderBottom: "2px solid lightblue"}} className="text">Choose one of the options</option>
                                <FetchDataSections />
                            </select>
                        </div>
                        <div className="inputBlock">
                            <label className="textCaption" htmlFor="create">Create (date and time):</label>
                            <input 
                                className="textArea text" 
                                type="datetime-local" 
                                id="create" 
                                name="created_at"
                                onChange={this.hendleInputChange}
                                value={this.state.created_at}
                            />
                        </div>
                        <div className="inputBlock">
                            <label className="textCaption" htmlFor="update">Update NEW (date and time):</label>
                            <input 
                                className="textArea text" 
                                type="datetime-local" 
                                id="update" 
                                name="updated_at"
                                onChange={this.hendleInputChange}
                                value={this.state.updated_at}
                            />
                        </div><br />
                        <div className='buttonUpdate'>
                            <button type='submit' className="btn btn-success">Success</button>
                            <button onClick={this.props.closeButtun} className="btn btn-dark">Close</button>
                        </div>
                    </fieldset>
                </form>
                </>
                )
        }
}
UpdateForm.defaultProps = {questionData: '', closeButtun: ''}