import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const DisasterRecovery = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Disaster Recovery</title>
                <meta name="description" content="Once the emergency has been resolved and the primary servers and databases are in proper working order, subscribers will be notified by the Iseehear Support team of the time and date that all data that was inputted into the Research Continuity Environment will be transferred (updated) to the main SoftMouse.NET server." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="disaster-recovery">
                <div className="container">

                    <div className="row disaster-caption">
                        <div className="col-lg-9">

                            <h3 className="mainCaption">Disaster Recovery</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                            <h4 className="caption">Information Security Management (ISM)</h4><br />
                        </div>
                    </div>

                    <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px"}}>
                            <div className="row">
                                <h4 className="caption"><b>Restoration of Service</b></h4>
                                <ol className="disaster_list">
                                    <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                                    <li className="text">Once the emergency has been resolved and the primary servers and databases are in proper working order, subscribers will be notified by the Iseehear Support team of the time and date that all data that was inputted into the Research Continuity Environment will be transferred (updated) to the main SoftMouse.NET server.</li>
                                    <li className="text">As soon as the data transfer is complete, subscribers will be notified by the Iseehear Support team that access has been restored at the main SoftMouse.NET URL (www.softmouse.net)</li>
                                </ol>
                            </div>
                    </div>
                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="content-section-b" style={{paddingBottom: "20px", paddingTop: "20px"}}>
                            <div className="row">
                                <h4 className="caption"><b>Additional Information</b></h4>

                                <h3 className="subCaption">Iseehear Support Desk Channels:</h3><br /><br />
                                <ol className="disaster_list">
                                    <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                    <li className="text"><span className="bold">Email:</span> support@SoftMouse.NET</li> <br />
                                    <li className="text"><span className="bold">Notice Board:</span> Located on the right side of the page below Username and Password login at <a className="textLink" href="http://www.softmouse.net" target="_blank" rel="noreferrer">www.softmouse.net</a></li>  <br />
                                </ol>
                            </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>

    )
}