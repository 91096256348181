import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { HashLink as A } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

export const PrivacyPolicy = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Privacy Policy</title>
                <meta name="description" content="Iseehear endeavours to ensure that the information that you submit to us remains private and that it is used only for the services we provide to you. We aim to provide a safe and secure experience for all of our users." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="privacy_policy">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Iseehear Privacy Policy</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                        </div>
                    </div>

                    <div className="polisy-summary">
                        <p className="text">Effective Date: May 25, 2018 This Privacy Policy may be amended from time to time.</p><br /><br />
                        <img src={require("../../img/iseehear-logo.png")} alt="logo" className="policiLogo" /><br /><br />
                        <p className="text">Iseehear Inc. Life Sciences (“Iseehear”, “we”, “us”, and “our”).</p><br /><br />
                    </div>

                    <div className="google-list anchorLink">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                        <div className="anchor-link">
                            <A className="text" to="#ISHPolicy">Iseehear Inc. Life Sciences Privacy Policy (Full Version)</A><br />
                            <A className="text" to="#policycovers">What this Privacy Policy covers</A><br />
                            <A className="text" to="#collectinformation">How we collect information</A><br />
                            <A className="text" to="#personalinformation">Use and storage of your personal information</A><br />
                            <A className="text" to="#customerinformation">How we protect customer information</A><br />
                            <A className="text" to="#Accuracyofuserinformation">Accuracy of user information</A><br />
                            <A className="text" to="#Accesstoyourpersonalinformation">Access to your personal information</A><br />
                            <A className="text" to="#Otherwebsites">Other websites</A><br />
                            <A className="text" to="#ChangestoourPrivacyPolicy">Changes to our Privacy Policy</A><br />        
                        </div>
                        <div>
                            <Link className="text pull-right" to="/documents/Privacy-Policy-Iseehear-Inc-Life-Sciences.pdf" target="_blank" rel="noreferrer">Download PDF <img src={require("../../img/SoftMouse_download_black.png")} alt="softmouse" /></Link><br />
                        </div>
                    </div>

                    <div id="ISHPolicy" className="subscription-list">
                        <span className="caption">Iseehear Inc. Life Sciences Privacy Policy (Full Version)</span><br /><br />
                        <p className="text">At Iseehear Inc. Life Sciences (Iseehear) we are committed to protecting your privacy.</p><br /><br />       
                        <p className="text">Iseehear endeavours to ensure that the information that you submit to us remains private and that it is used only for the services we provide to you. We aim to provide a safe and secure experience for all of our users.</p><br /><br />      
                        <p className="text">Individuals may complain to The Privacy Commissioner of Canada about any matter specified in Section 11 of the Personal Information Protection and Electronic Documents Act (PIPEDA). This Act applies to matters relating to personal information in the private sector. (Please see https://www.priv.gc.ca/en/report-a-concern/)</p><br /><br />       
                    </div>

                    <div id="policycovers" className="subscription-list">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                        <span className="caption"> What this Privacy Policy covers</span><br /><br />      
                        <p className="text">This Privacy Policy covers Iseehear and any of its network of websites, treatment of personally identifiable information that Iseehear collects when you are on an Iseehear website, and when you use an Iseehear web services application. This policy also covers Iseehear's treatment of any personally identifiable information that Iseehear's partners and clients share with Iseehear. This policy does not apply to companies that Iseehear does not own or control, or to people that Iseehear does not employ.</p><br /><br />        
                        <p className="text">At times you will be asked to submit personal information about yourself (name, email address, postal / zip code, etc). We collect different types of information about our users for a number of reasons:</p><br />       
                        <ul className="data_list">
                            <li className="text">To help Iseehear improve the services we offer.</li>
                            <li className="text">To provide personalized services unique to individual users.</li>
                            <li className="text">To use anonymous details about our users to sell appropriate advertising space on the site. Please note that where we collect registration information from a user, we will never share that person´s information with a third party, including an advertiser.</li>
                        </ul>       
                        <br />
                        <p className="text">By entering your details in the registration fields requested, you enable Iseehear to provide you with the services you select. Whenever you provide such personal information, we will treat that information in accordance with this policy. All of our email correspondences include instructions on how to ´unsubscribe´ from email alerts. Our services are designed to give you the information that you want to receive. Iseehear will act in accordance with current Canadian Privacy legislation (including European Union and United States legislation) and aim to meet current Internet best practice.</p>
                    </div>

                    <div id="collectinformation" className="subscription-list">
                        <span className="caption"> How we collect information</span><br /><br />        
                        <p className="text">Iseehear collects personally identifiable information when you register with Iseehear, when you use certain Iseehear products or services, when you visit Iseehear web pages, and when you enter surveys and competitions. Iseehear may also receive personally identifiable information from our partners and clients.</p><br /><br />      
                        <p className="text">Iseehear also automatically receives and records information on our server logs from your browser including your Internet Protocol address. Every computer on the Internet has an IP address, which is a unique number by which it can be identified. Iseehear records IP addresses for anonymous statistical purposes, security purposes, administration of our website and servers, and to improve our services.</p><br /><br />       
                        <p className="text">Iseehear may also set and access cookies on your computer.</p><br /><br />
                    </div>

                    <div id="personalinformation" className="subscription-list">     
                        <span className="caption"> Use and storage of your personal information</span><br /><br />      
                        <p className="text">When you supply any personal information to Iseehear we have legal obligations towards you in the way we deal with that data. We must collect the information fairly, that is, we must explain how we will use it and tell you if we want to pass the information on to anyone else. In general, any information you provide to Iseehear will only be used within Iseehear to improve the service we provide to you. It will never be supplied to anyone outside Iseehear, unless we are obliged or permitted by law to disclose it. Also, if you post or send offensive or inappropriate content anywhere on or to Iseehear or otherwise engage in any disruptive behaviour on Iseehear websites, and Iseehear considers such behaviour to be serious and/or repeated, Iseehear can use whatever information that is available to it about you to stop such behaviour. This may include informing relevant third parties such as <b>Law Enforcement Authorities, ISP or email provider about the content and your behaviour</b>.</p><br /><br />       
                        <p className="text">Iseehear will hold your personal information on our systems for as long as you use the service you have requested, and remove it in the event that the purpose has been met, or, in the case of Iseehear membership you no longer wish to continue your registration as an Iseehear member. Where personal information is held for people who are not yet registered but have taken part in other Iseehear services (e.g. surveys and or competitions), that information will be held only as long as necessary to ensure that the service is run smoothly. We will ensure that all personal information supplied is held securely, in accordance with the Canadian Privacy Legislation.</p><br /><br />       
                        <p className="text">Iseehear may perform statistical analysis of user behaviour in order to measure user activity on specific areas of an Iseehear website. This is aggregated data and does not hold any personal user information. We will also use user information to target online advertising on the site, so that you will see advertisements that are more relevant to you as a user. This is based on you being a member of a demographic group and not as a specific individual.</p><br /><br />
                    </div>

                    <div id="customerinformation" className="subscription-list">  
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />                      
                        <span className="caption"> How we protect customer information</span><br /><br />     
                        <p className="text">Iseehear has put in place security measures and practices to ensure and protect user privacy. Iseehear follows strict security procedures in the storage and disclosure of user information to prevent unauthorised access.</p><br /><br />
                    </div>

                    <div id="Accuracyofuserinformation" className="subscription-list">      
                        <span className="caption"> Accuracy of user information</span><br /><br />      
                        <p className="text">While Iseehear endeavours to ensure that the information Iseehear holds about you is accurate and, where necessary, kept up to date, Iseehear shall assume that in the absence of evidence to the contrary, that the information you provide us with is accurate. If there is any reasonable doubt as to its accuracy Iseehear shall contact you to confirm the information. Should there be any inaccuracies in the information of which you inform us, or of which Iseehear becomes aware, it shall be promptly rectified by us. Iseehear does not retain any information about you, which is out of date, or which is no longer required.</p><br /><br />
                    </div>

                    <div id="Accesstoyourpersonalinformation" className="subscription-list">     
                        <span className="caption"> Access to your personal information</span><br /><br />
                        <p className="text">Along with all other rights granted to you under the Canadian Privacy Legislation which we shall respect and observe, you may at any time in writing or by email request to be informed of the personal information which we hold about you, either as a whole or limited to certain information of a particular nature. We shall comply with this request but, in the interests of your privacy, only after we are supplied with such information by you as would reasonably be required to satisfy ourselves as to your identity. In the interests of the privacy of others, we would not be able to comply with such a request if it involved disclosing information about another person unless they consented to the disclosure or it was reasonable in all the circumstances to comply with the request without their consent. We may charge a reasonable, administrative fee for providing this information, which will be provided no later than 30 days from when we receive your request.</p><br /><br />       
                        <p className="text">If you do not wish us to continue using your information, a simple, email message from you to the address shown below will suffice to prevent this: privacy.policy@iseehear.com</p><br /><br />
                    </div>

                    <div id="Otherwebsites" className="subscription-list">     
                        <span className="caption"> Other websites</span><br /><br />
                        <p className="text">The Iseehear website contains links to other third party websites. Iseehear is not responsible for the privacy practices or the content of such third party websites.</p><br /><br />       
                        <p className="text">If you operate a Web site and wish to link to Iseehear, you may do so provided you agree to cease such link upon request from Iseehear. No other use is permitted without prior written permission of Iseehear. The permitted use described in this Paragraph is contingent on your compliance at all times with all Iseehear Terms of Service Policies.</p><br /><br />
                    </div>

                    <div id="ChangestoourPrivacyPolicy" className="subscription-list">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                        <span className="caption"> Changes to our Privacy Policy</span><br /><br />
                        <p className="text">Iseehear may amend this policy from time to time. Any changes to the Iseehear Privacy Policy will be posted here so you always know what information Iseehear gathers about you and how that information might be used. If Iseehear makes any substantial changes in the way we use your personal information Iseehear will notify you by posting a prominent announcement on our pages.</p><br />
                        <p className="text">For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at privacy@iseehear.com or by mail using the details provided below:</p><br />       
                        <p className="text">ISEEHEAR INC. LIFE SCIENCES</p>
                        <p className="text">250 University Avenue,</p>
                        <p className="text">Suite 200,</p>
                        <p className="text">Toronto, Ontario,</p>
                        <p className="text">Canada, M5H 3E5</p>
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}