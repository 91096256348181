import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";


export const Accessibility = () => {
    return (
        <div className="wrapper">
            <Helmet>
            <title>Accessibility</title>
            <meta name="description" content="Iseehear Inc. Life Sciences is committed to ensuring digital accessibility for everyone. Our website follows Web Content Accessibility Guidelines 2.0, level AA, making it inclusive for individuals with disabilities. We use automated tools and regular evaluations to address accessibility issues. Your feedback is valuable, and we're dedicated to providing an accessible digital experience for all." />
            <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="malware_detection">
                <div className="container">

                    <div className="row malware-caption">
                        <div className="col-lg-9">

                            <h3 className="mainCaption">Accessibility</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />

                            
                            <h4 className="caption">Digital Accessibility</h4><br />
                        </div>
                    </div>

                    <div className="content-section-b">
                        <div className="largefont">
                            <p className="text">Digital Accessibility refers to how well people with visual, hearing, motor and or cognitive challenges can access our website content. Iseehear Inc. Life Sciences is committed to providing a website that is accessible to the widest possible audience. We want everyone who visits to feel welcome and find the experience useful.</p>
                        </div>
                    </div>
                
                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="content-section-b">
                        <div className="encryption_overview">
                            <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                            <p className="text">Our website endeavors to conform to the <a className="textLink" href="https://www.w3.org/TR/WCAG21/" target="_blank" rel="noreferrer">Web Content Accessibility Guidelines 2.0, level AA</a>. These guidelines explain how to make web content more accessible for people with disabilities and conformance with these guidelines helps to make the web a more inclusive space.</p>
                        </div>                    
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="content-section-b">
                        <div className="largefont">
                            <p className="text">We use a digital tool to perform automated checks for Accessibility and have also had an external evaluation of the website to make us aware of Accessibility issues present on our site.</p>
                        </div>
                    </div>
                
                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="content-section-b">
                        <div className="largefont">
                            <p className="text">As we post new content, build new pages, add new features to all websites of  Iseehear Inc. Life Sciences, ensuring our website is accessible for all users is a responsibility we are committed to. We actively work to fix identified issues as they come up to increase the Accessibility and usability of our website.</p>
                        </div>
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="content-section-b">
                        <div className="largefont">
                            <div className="anchorLink">
                                <div>
                                    <p className="text">SoftMouse.NET Accessibility Conformance Report (Voluntary Product Accessibility Template or VPAT)</p>
                                    <Link className="textLink" to="/documents/Iseehear-Inc.-Accessibility-Conformance-Report.docx(1).pdf" target="_blank" rel="noreferrer">Download PDF <img src={require("../../img/SoftMouse_download_black.png")} alt="softmouse" /></Link><br />
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="content-section-b">
                        <div className="largefont">
                            <p className="text">If you have a question or comment related to Accessibility on Iseehearsecurity.com, please provide feedback to itsecurity@iseehear.com. You can also request alternative formats of documents or processes by filling out our <Link className="textLink" to="/contactForm">Accessible Format Request Form</Link>.</p>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
   
}