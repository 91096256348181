import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const SoftmouseEncryption = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>SoftMouse Encryption</title>
                <meta name="description" content="This page you are viewing was encrypted before being transmitted over the Internet. Encryption makes it difficult for unauthorized persons to view information traveling between a web server and a web browser." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="softmouse-encryption">
                <div className="container">

                    <div className="row data-encryption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Data Encryption (Data At Rest, SSL and In Transit)</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                            <h4 className="caption">Information Security Management (ISM)</h4><br />
                        </div>
                    </div>         

                    <div className="content-section-b">
                            <div className="largefont">
                                <h4 className="subCaption"><b>Data Encryption At Rest</b></h4><br />

                            <p className="text">SoftMouse.NET customer data is encrypted while stored at rest in the Google Cloud Platform. Read more about data encryption at rest. <a className="textLink long_link" href="https://cloud.google.com/security/encryption-at-rest/default-encryption/" target="_blank" rel="noreferrer">https://cloud.google.com/security/encryption-at-rest/default-encryption/ <img src={require("../../img/external-link-red.png")} alt="link" /></a></p>
                            </div>
                    </div>
                    
                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="content-section-b">
                            <div className="encryption_overview">
                                <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                                <h4 className="subCaption"><b>SSL Encryption Overview</b></h4><br />

                                <p className="text">
                                    Iseehear Inc. supports encryption of customer data over Internet and in storage in the database.
                                    Subscriber / User data is stored with 256-bit encryption at rest and 256-bit SSL encryption
                                    while in transit over the Internet (Read more about SSL Encryption below). Website Malware scans are performed daily (Please read more about website <Link className="textLink" to="/malwareDetection">Malware Detection</Link>).
                                </p>
                            </div>                    
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="content-section-b">
                            <div className="largefont">
                                <h4 className="subCaption"><b>Secure Socket Layer (SSL) Encryption Information</b></h4><br />

                                    <p className="text">Data transactions (email contact form, registration form, login, data entry) executed through this website are encrypted using 256 bit Secure Socket Layer (SSL).
                                    Secure Socket Layer (SSL) is the standard security technology for establishing a secure encrypted connection between a web server and a web browser (such as Chrome, Firefox, Safari).</p><br /><br />
                                    
                                    <p className="text">This page you are viewing was encrypted before being transmitted over the Internet.
                                    Encryption makes it difficult for unauthorized persons to view information traveling between a web server and a web browser.</p>

                                    <br /><br />
                                    <ol className="encryption_list">
                                        <li className="text">The SoftMouse.NET website uses Secure Socket Layer (SSL) encryption which has been verified by Cloudflare as well as GeoTrust Inc. (GeoTrust Inc. is a subsidiary of Symantec Corporation).</li>
                                        <li className="text">Data transactions (email contact form, registration form, login, data entry) executed through this website are encrypted using 256 bit Secure Socket Layer (SSL).</li>
                                        <li className="text">To identify if a website is using SSL <b>look for the lock icon and https</b> in the address bar <i>(see Image  1-A and Image  1-B)</i>.</li>
                                        <li className="text">The address bar for this website should read: <b>"https://SoftMouse.NET"</b> or <b>"https://www.SoftMouse.NET"</b> <i>(see Image  1-A and Image  1-B)</i>.</li>
                                        <li className="text"><b>Important:</b> If the "home page" address of this website is not written exactly as either <b>"https://SoftMouse.NET"</b> or <b>"https://www.SoftMouse.NET"</b> then do not enter any data and please notify Iseehear Inc. immediately.</li>
                                        <li className="text">To identify if the SSL certificate is valid click on <b>the lock icon</b> in the address bar. <i>(see Image  1-A and Image  1-B)</i></li>

                                    </ol><br />
                            </div>
                    </div>

                    <hr style={{borderBottom: "2px solid lightblue"}} />

                    <div className="content-section-b">
                            <div className="largefont">
                                <h4 className="subCaption"><b>Encryption in Transit in Google Cloud</b></h4><br />
                                <p className="text">Encryption in Transit in Google Cloud. Read more about Encryption in Transit in Google Cloud here: <a className="textLink long_link" href="https://cloud.google.com/security/encryption-in-transit" target="_blank" rel="noreferrer">https://cloud.google.com/security/encryption-in-transit</a></p>

                                <hr style={{borderBottom: "2px solid lightblue"}} />
                                <br />
                            </div>

                            <div className="col-lg-12">
                                <ul className="googleChrome">
                                    <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                    <br />
                                    <img src={require("../../img/softmouse_https_confirmation.png")} alt="softmouse" className="img-responsive" /><br />
                                </ul>
                                <br /><br />

                                <ul>
                                    <img src={require("../../img/softmouse_https_confirmation_firefox.png")} alt="softmouse" className="img-responsive" /><br />
                                </ul>
                                <br /><br />

                                <ul className="firefox">
                                    <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                    <img src={require("../../img/softmouse_ssl_details_firefox.png")} alt="softmouse" className="img-responsive img2-b" /><br />
                                </ul>
                            </div>
                            <br /><br /><br />

                            <div className="col-lg-12">
                                <div className="text third_party"><img src={require("../../img/external-link-red.png")} alt="link" />
                                    &nbsp;&nbsp;Indicates the link goes to a third party website.<br /><br />
                                </div>
                            </div>
                    </div>
                
                </div>    
            </section>
            <Footer />
        </div>
    )
}