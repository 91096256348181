import React from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { HashLink as A } from 'react-router-hash-link';
import { BackToTopButton } from "./scroll";
import { useContext } from 'react';
import GeneralContext from '../Context/GeneralContext';
// import { SearchBox } from "./SearchBox";

export const Header = (props) => {
    const [openMenu, setOpenMenu] = React.useState(true);
    const [openMenuMobile, setOpenMenuMobile] = React.useState(true);
    const [openSubMenuZero, setOpenSubMenuZero] = React.useState(true);
    const [openSubMenuOne, setOpenSubMenuOne] = React.useState(true);
    const [openSubMenuTwo, setOpenSubMenuTwo] = React.useState(true);
    const [openSubMenuThree, setOpenSubMenuThree] = React.useState(true);
    const [openSubMenuFour, setOpenSubMenuFour] = React.useState(true);

    const {sections} = useContext(GeneralContext)

    const HendlerCloseAllMenu = () => {
        if(setOpenMenu) {
            setOpenMenu(true);
            setOpenSubMenuZero(true);
            setOpenSubMenuOne(true);
            setOpenSubMenuTwo(true);
            setOpenSubMenuThree(true);
            setOpenSubMenuFour(true);
        }
    }

    const  HendlerOpenSubMenuZero = () => {
        if(setOpenSubMenuZero) {
            setOpenSubMenuZero((v) => !v);
            setOpenSubMenuOne(true);
            setOpenSubMenuTwo(true);
            setOpenSubMenuThree(true);
            setOpenSubMenuFour(true);
        }
    }

    const  HendlerOpenSubMenuOne = () => {
        if(setOpenSubMenuOne) {
            setOpenSubMenuOne((v) => !v);
            setOpenSubMenuZero(true);
            setOpenSubMenuTwo(true);
            setOpenSubMenuThree(true);
            setOpenSubMenuFour(true);
        }
    }

    const  HendlerOpenSubMenuTwo = () => {
        if(setOpenSubMenuTwo) {
            setOpenSubMenuTwo((v) => !v);
            setOpenSubMenuOne(true);
            setOpenSubMenuZero(true);
            setOpenSubMenuThree(true);
            setOpenSubMenuFour(true);
        }
    }

    const  HendlerOpenSubMenuThree = () => {
        if(setOpenSubMenuThree) {
            setOpenSubMenuThree((v) => !v);
            setOpenSubMenuOne(true);
            setOpenSubMenuTwo(true);
            setOpenSubMenuZero(true);
            setOpenSubMenuFour(true);
        }
    }

    const  HendlerOpenSubMenuFour = () => {
        if(setOpenSubMenuFour) {
            setOpenSubMenuFour((v) => !v);
            setOpenSubMenuOne(true);
            setOpenSubMenuTwo(true);
            setOpenSubMenuThree(true);
            setOpenSubMenuZero(true);
        }
    }

    return (
        <div onMouseLeave={HendlerCloseAllMenu}>
            <CSSTransition
                in={openMenuMobile}
                timeout={700}
                appear={true}
                classNames="transitionMenu"
            >
                {openMenuMobile ? setOpenMenuMobile : <div className="menuOverlay" onMouseLeave={() => setOpenMenuMobile((v) => !v)}>
                    <div className="menuCaption">
                        <svg onClick={() => setOpenMenuMobile((v) => !v)} className="closeMenu" width="30" height="30" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L13 13M1 13L13 1L1 13Z" stroke="#121212" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <h1 className="caption">M E N U</h1>
                    </div>
                    <div className="homeLink">
                        <Link to="/"><h2 className="textCaption">HOME</h2></Link>
                    </div>
                    <div className="accordionStyle">
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingZero">
                                <button className="accordion-button collapsed textCaption" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseZero" aria-expanded="false" aria-controls="flush-collapseZero">
                                    General Information
                                    <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill="#458FF6"/>
                                    </svg>
                                </button>
                                </h2>
                                <div id="flush-collapseZero" className="accordion-collapse collapse" aria-labelledby="flush-headingZero" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <ul className="menuList">
                                            <li><A className="textCaption" to="/generalInformation#generalInformation">Corporate Information</A></li>
                                            <li><A className="textCaption" to="/generalInformation#securityContactInformation">Security Contact Information</A></li>
                                            <li><A className="textCaption" to="/generalInformation#productInformation">Product Information</A></li>
                                            {sections.map((section) => (
                                                <li key={section.id}><A className="textCaption" to={`/generalInformation#${section.section_name.replace(/\s/g, '')}`}>{section.section_name}</A></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed textCaption" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    Data Security
                                    <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill="#458FF6"/>
                                    </svg>
                                </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <ul className="menuList">
                                            <li><Link className="textCaption" to="/securityOverview">Security Overview</Link></li>
                                            <li><Link className="textCaption" to="/softmouseEncryption">Softmouse Encryption</Link></li>
                                            <li><Link className="textCaption" to="/malwareDetection">Malware Detection</Link></li>
                                            <li><Link className="textCaption" to="/passwordManagement">Password Management</Link></li>
                                            <li><Link className="textCaption" to="/dataBackup">Data Backup</Link></li>
                                            <li><Link className="textCaption" to="/disasterRecovery">Disaster Recovery</Link></li>
                                            <li><Link className="textCaption" to="/googleCloudPlatform">Google Cloud Platform</Link></li>
                                            <li><Link className="textCaption" to="/whois">WHOIS Verification & Authentication</Link></li>
                                            <li><Link className="textCaption" to="/browserRequirements">Supported Internet Browsers</Link></li>
                                            <li><Link className="textCaption" to="/securityHygiene">Security Hygiene Tips</Link></li>
                                            <li><Link className="textCaption" to="/wisp">Written Information Security Program</Link></li>
                                            <li><Link className="textCaption" to="/ocsoexecutivesummary">The Office of the Chief Security Officer</Link></li>
                                            <li><Link className="textCaption" to="/NDA">Security Partner NDA</Link></li>
                                            <li><Link className="textCaption" to="/mapping">Security Compliance Mapping</Link></li>
                                            <li><Link className="textCaption" to="/scheduledSecurityTestingProcedures">Scheduled Security Testing Procedures</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                <button className="accordion-button collapsed textCaption" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    Policies
                                    <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill="#458FF6"/>
                                    </svg>
                                </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <ul className="menuList">
                                            <li><Link className="textCaption" to="/policyNotices">Policy Notices History</Link></li>
                                            <li><Link className="textCaption" to="/policiesOverview">Iseehear Policies Overview</Link></li>
                                            <li><Link className="textCaption" to="/privacyPolicy">Iseehear Privacy Policy (Full Version)</Link></li>
                                            <li><Link className="textCaption" to="/termsOfUse">Iseehear Terms of Use</Link></li>
                                            <li><Link className="textCaption" to="/cookiePolicy">Iseehear Cookie Policy</Link></li>
                                            <li><Link className="textCaption" to="/emailPolicy">Iseehear Email Policy Notice</Link></li>
                                            <li><Link className="textCaption" to="/intellectualProperty">Iseehear Intellectual Property Research Data Rights Policy</Link></li>
                                            <li><Link className="textCaption" to="/freeTrialPolicy">Iseehear Free Trial Policy</Link></li>
                                            <li><Link className="textCaption" to="/copyright">Iseehear Copyright Policy</Link></li>
                                            <li><Link className="textCaption" to="/trademark">Iseehear Trademark Notice</Link></li>
                                            <li><Link className="textCaption" to="/betaSiteAgreement">Iseehear Beta Site Agreement</Link></li>
                                            <li><Link className="textCaption" to="/subscriptionAgreement">Iseehear Subscription Agreement</Link></li>
                                            <li><Link className="textCaption" to="/accessibility">Accessibility</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>                       
                            <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                    <button className="accordion-button collapsed textCaption" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Communications
                                        <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill="#458FF6"/>
                                        </svg>
                                    </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <ul className="menuList">
                                                <li><Link className="textCaption" to="/upgrade">Scheduled Upgrades And System Maintenance Procedures</Link></li>
                                                <li><Link className="textCaption" to="/emergencyUpgrade">Emergency Upgrades And System Maintenance Procedures</Link></li>
                                                <li><Link className="textCaption" to="/communication">Information Resource Links</Link></li>
                                                <li><Link className="textCaption" to="/contactUs">Contact us Resources</Link></li>
                                                <li><Link className="textCaption" to="/contactForm">Contact Us Web Form</Link></li>   
                                                <li><Link className="textCaption" to="/humanRightsAndEthicsPolicy">Human Rights and Ethics Policy</Link></li>   
                                            </ul>
                                        </div>
                                    </div>
                            </div>
                            <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                    <button className="accordion-button collapsed textCaption" style={{color: "red"}} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        Emergency
                                        <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill="#458FF6"/>
                                        </svg>
                                    </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <ul className="menuList">
                                                <li><Link className="textCaption" to="/emergencyPlan">How you can prepare</Link></li>
                                                <li><Link className="textCaption" to="/emergencyPrepare">How we are prepared</Link></li>
                                                <li><Link className="textCaption" to="/WorkingFromHome">Working from home</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </CSSTransition>


            <CSSTransition
                in={openMenu}
                timeout={700}
                appear={true}
                classNames="transitionMenu"
            >
                {openMenu ? setOpenMenu : <div className="menuOverlayBig" onMouseLeave={() => setOpenMenu((v) => !v)}>
                    <div className="menuCaption">
                        <svg onClick={HendlerCloseAllMenu} className="closeMenu closeMenuBig" width="30" height="30" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L13 13M1 13L13 1L1 13Z" stroke="#121212" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <h1 className="caption">M E N U</h1>
                    </div>
                    <div className="homeLink">
                        <Link to="/"><h2 className="textCaption">HOME</h2></Link>
                    </div>
                    <div className="accordionStyle">
                        <div className="accordion accordion-flush">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button onClick={HendlerOpenSubMenuZero} id="buttonStartZero" className="accordion-button textCaption" type="button">
                                    General Information
                                    <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill="#458FF6"/>
                                    </svg>
                                </button>
                                </h2>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button onClick={HendlerOpenSubMenuOne} id="buttonStartOne" className="accordion-button textCaption" type="button">
                                    Data Security
                                    <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill="#458FF6"/>
                                    </svg>
                                </button>
                                </h2>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button onClick={HendlerOpenSubMenuTwo} id="buttonStartTwo" className="accordion-button textCaption" type="button">
                                    Policies
                                    <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill="#458FF6"/>
                                    </svg>
                                </button>
                                </h2>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button onClick={HendlerOpenSubMenuThree} id="buttonStartThree" className="accordion-button textCaption" type="button">
                                    Communications
                                    <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill="#458FF6"/>
                                    </svg>
                                </button>
                                </h2>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button onClick={HendlerOpenSubMenuFour} id="buttonStartFour" className="accordion-button textCaption" type="button" style={{color: "red"}}>
                                    Emergency
                                    <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill="#458FF6"/>
                                    </svg>
                                </button>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>}
            </CSSTransition>

            <div className="extraSideMenu">
                {/* General Information */}
                <CSSTransition
                    in={openSubMenuZero}
                    timeout={700}
                    appear={true}
                    classNames="transitionMenu"
                >
                    {openSubMenuZero ? setOpenSubMenuZero : <div id="sideStartZero" className="menuOverlayExtantion"
                        onMouseLeave={() => setOpenSubMenuZero((v) => !v)} 
                        onMouseEnter={() => setOpenMenu((v) => !v)}
                     >
                        <h2 className="textCaption" style={{margin: "0 auto", width: "fit-content", borderBottom: "1px solid lightgray", paddingBottom: "10px"}}>GENERAL INFORMATION</h2>
                        <div className="accordion-body-big">
                            <ul className="menuListBig">
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><A className="textCaption" to="/generalInformation#generalInformation">Corporate Information</A></li>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><A className="textCaption" to="/generalInformation#securityContactInformation">Security Contact Information</A></li>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><A className="textCaption" to="/generalInformation#productInformation">Product Information</A></li>
                                {sections.map((section) => (
                                    <li key={section.id}><img className="listStile" src={require("../img/square.png")} alt="listStile" /><A className="textCaption" to={`/generalInformation#${section.section_name.replace(/\s/g, '')}`}>{section.section_name}</A></li>
                               ))}
                            </ul>
                        </div>
                    </div>}
                </CSSTransition>

                {/* Data Security */}
                <CSSTransition
                    in={openSubMenuOne}
                    timeout={700}
                    appear={true}
                    classNames="transitionMenu"
                >
                    {openSubMenuOne ? setOpenSubMenuOne : <div id="sideStartOne" className="menuOverlayExtantion" 
                        onMouseLeave={() => setOpenSubMenuOne((v) => !v)} 
                        onMouseEnter={() => setOpenMenu((v) => !v)}>
                        <h2 className="textCaption" style={{margin: "0 auto", width: "fit-content", borderBottom: "1px solid lightgray", paddingBottom: "10px"}}>DATA SECURITY</h2>
                        <div className="accordion-body-big">
                            <ul className="menuListBig">
                                <p className="textCaption fw-bold mb-0">Documentation</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/securityOverview">Security Overview</Link></li>
                                <p className="textCaption fw-bold mb-0">Application Security</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/softmouseEncryption">Softmouse Encryption</Link></li>
                                <p className="textCaption fw-bold mb-0">Authentication, Authorization, and Accounting</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/malwareDetection">Malware Detection</Link></li>
                                <p className="textCaption fw-bold mb-0">Systems Management & Configuration</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/mapping">Security Compliance Mapping</Link></li>
                                <p className="textCaption fw-bold mb-0">Change Management</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/passwordManagement">Password Management</Link></li>
                                <p className="textCaption fw-bold mb-0">Business Continuity Plan</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/dataBackup">Data Backup</Link></li>  
                            </ul>
                            <ul className="menuListBig">
                                <p className="textCaption fw-bold mb-0">Database</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/whois">WHOIS Verification & Authentication</Link></li>
                                <p className="textCaption fw-bold mb-0">Datacenter</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/browserRequirements">Supported Internet Browsers</Link></li>
                                <p className="textCaption fw-bold mb-0">Firewalls, IDS, IPS, and Networking</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/securityHygiene">Security Hygiene Tips</Link></li>
                                <p className="textCaption fw-bold mb-0">Physical Security</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/wisp">Written Information Security Program</Link></li>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/ocsoexecutivesummary">The Office of the Chief Security Officer</Link></li>
                                <p className="textCaption fw-bold mb-0">Policies, Procedures, and Processes</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/NDA">Security Partner NDA</Link></li>              
                            </ul>
                            <ul className="menuListBig">
                                <p className="textCaption fw-bold mb-0">Disaster Recovery Plan</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/disasterRecovery">Disaster Recovery</Link></li>
                                <p className="textCaption fw-bold mb-0">Data</p>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/googleCloudplatform">Google Cloud Platform</Link></li>
                                <p className="textCaption fw-bold mb-0">Vulnerability Scanning</p>  
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/scheduledSecurityTestingProcedures">Scheduled Security Testing Procedures</Link></li>    
                            </ul>
                        </div>
                    </div>}
                </CSSTransition>

                {/* Policies */}
                <CSSTransition
                    in={openSubMenuTwo}
                    timeout={700}
                    appear={true}
                    classNames="transitionMenu"
                >
                {openSubMenuTwo ? setOpenSubMenuTwo : <div  id="sideStartTwo" className="menuOverlayExtantion"
                    onMouseLeave={() => setOpenSubMenuTwo((v) => !v)} 
                    onMouseEnter={() => setOpenMenu((v) => !v)}>
                    <h2 className="textCaption" style={{margin: "0 auto", width: "fit-content", borderBottom: "1px solid lightgray", paddingBottom: "10px"}}>POLICIES</h2>
                    <div className="accordion-body-big">
                        <ul className="menuListBig">
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/policyNotices">Policy Notices History</Link></li>
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/policiesOverview">Iseehear Policies Overview</Link></li>
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/privacyPolicy">Iseehear Privacy Policy (Full Version)</Link></li>
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/termsOfUse">Iseehear Terms of Use</Link></li>
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/cookiePolicy">Iseehear Cookie Policy</Link></li>
                        </ul>
                        <ul className="menuListBig">
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/intellectualProperty">Iseehear Intellectual Property Research Data Rights Policy</Link></li>
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/freeTrialPolicy">Iseehear Free Trial Policy</Link></li>
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/copyright">Iseehear Copyright Policy</Link></li>
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/trademark">Iseehear Trademark Notice</Link></li>
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/betaSiteAgreement">Iseehear Beta Site Agreement</Link></li>
                        </ul>
                        <ul className="menuListBig">
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/emailPolicy">Iseehear Email Policy Notice</Link></li>
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/subscriptionAgreement">Iseehear Subscription Agreement</Link></li>
                            <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/accessibility">Accessibility</Link></li>
                        </ul>
                    </div>
                </div>}
                </CSSTransition>

                {/* Communications */}
                <CSSTransition
                    in={openSubMenuThree}
                    timeout={700}
                    appear={true}
                    classNames="transitionMenu"
                >
                    {openSubMenuThree ? setOpenSubMenuThree : <div id="sideStartThree" className="menuOverlayExtantion"
                        onMouseLeave={() => setOpenSubMenuThree((v) => !v)} 
                        onMouseEnter={() => setOpenMenu((v) => !v)}>
                        <h2 className="textCaption" style={{margin: "0 auto", width: "fit-content", borderBottom: "1px solid lightgray", paddingBottom: "10px"}}>COMMUNICATIONS</h2>
                        <div className="accordion-body-big">
                            <ul className="menuListBig">
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/upgrade">Scheduled Upgrades And System Maintenance Procedures</Link></li>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/emergencyUpgrade">Emergency Upgrades And System Maintenance Procedures</Link></li>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/communication">Information Resource Links</Link></li>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/contactUs">Contact us Resources</Link></li>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/contactForm">Contact Us Web Form</Link></li>   
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/humanRightsAndEthicsPolicy">Human Rights and Ethics Policy</Link></li>   
                            </ul>
                        </div>
                    </div>}
                </CSSTransition>

                {/* Emergency */}                             
                <CSSTransition
                    in={openSubMenuFour}
                    timeout={700}
                    appear={true}
                    classNames="transitionMenu"
                >
                    {openSubMenuFour ? setOpenSubMenuFour : <div id="sideStartFour" className="menuOverlayExtantion"
                        onMouseLeave={() => setOpenSubMenuFour((v) => !v)} 
                        onMouseEnter={() => setOpenMenu((v) => !v)}>
                        <h2 className="textCaption" style={{margin: "0 auto", width: "fit-content", borderBottom: "1px solid lightgray", paddingBottom: "10px"}}>EMERGENCY</h2>
                        <div className="accordion-body-big">
                            <ul className="menuListBig">
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/emergencyPlan">How you can prepare</Link></li>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/emergencyPrepare">How we are prepared</Link></li>
                                <li><img className="listStile" src={require("../img/square.png")} alt="listStile" /><Link className="textCaption" to="/WorkingFromHome">Working from home</Link></li>
                            </ul>
                        </div>
                    </div>}
                </CSSTransition>
            </div>

            <nav className="navbar navbar-expand-lg navbar-light myHeader">
                <div className="container-fluid">
                    <div className="newLogo">                  
                        <Link className="navbar-brand" to="/"><img className="securityLogo" src={props.logo}   alt="logo" /></Link>
                    </div>
                    <ul className="navbar-nav dropDownBox">
                        <li className="nav-item">
                            <a className="dashboardBtn" href="https://iseehearsecurity.statuspage.io/" target="_blank" rel="noreferrer">
                                Status Dashboard
                            </a>
                        </li>
                        <li>
                            <Link className="notificationsBtn" to="/securityNotifications">
                                Security Notifications
                            </Link>
                        </li>
                        {/* <li className="nav-item loupeItem">
                            <img className="exitSearch" src={require("../img/error.png")} alt="exit" />
                            <input className="searchBox" id="searchBox" type="text" placeholder="Security Search" />
                            <img className="loupe" src={require("../img/loupe.png")} alt="loupe" />
                        </li> */}
                    </ul>
                    <div className="navigation">
                        <div onMouseEnter={() => setOpenMenu((v) => !v)} className="sideMenu sideMenuBig">
                            <h1 className="caption">M E N U</h1>
                            <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill={props.errowColor}/>
                            </svg>
                        </div>
                    </div>
                    <div className="navigationMobile">
                        <div onClick={() => setOpenMenuMobile((v) => !v)} className="sideMenu sideMenuBig">
                            <h1 className="caption">M E N U</h1>
                            <svg className="errowMenu" width="20" height="20" viewBox="2 1 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.561 4.262V0.643L7.502 2.458L0.561 4.262Z" fill={props.errowColor}/>
                            </svg>
                        </div>
                    </div>
                </div>
                {/* <SearchBox /> */}
            </nav>
            <BackToTopButton />
        </div>
    )
}