import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { HashLink as A } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

export const SecurityHygiene = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Security Hygiene</title>
                <meta name="description" content="It is important to keep your internet web browser up-to-date for various security reasons such as protecting your data and personal information. Maintaining an up-to-date internet web browser is the first line of security protecting your computer from malicious websites and bad actors (criminals). An up-to-date internet web browser enhances a optimal webpage navigation experience." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="security-hygiene">
                <div className="container">

                    <div className="row data-caption">
                            <div className="col-lg-9">
                                <h3 className="mainCaption">Good Internet Browsing Hygiene</h3>
                                <hr style={{borderBottom: "2px solid lightblue"}} />
                                <h4 className="caption">Information Security Management (ISM)</h4><br />
                            </div>
                    </div>
                    
                    <div className="content-section-b">
                            <div className="row">

                                <p className="text">It is important to keep your internet web browser up-to-date for various security reasons such as protecting your data and personal information. Maintaining an up-to-date internet web browser is the first line of security protecting your computer from malicious websites and bad actors (criminals). An up-to-date internet web browser enhances a optimal webpage navigation experience.</p> <br /><br />

                                <p className="text">Another reason to keep your browser up-to-date is because Iseehear Inc. Life Sciences is continuously updating and upgrading its SoftMouse.NET colony management software with the latest technologies thus making an up-to-date internet browser beneficial for efficient navigation.</p><br /><br />
                                <ul className="google-list">
                                    <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                                    <li className="text">Learn how to keep your Internet browsers up-to-date. <Link className="textLink" to="/browserRequirements">Learn more about SoftMouse browsers requirements</Link>.</li><br />
                                    <li className="text">Only visit websites that are HTTPS enabled. <Link className="textLink" to="/softmouseEncryption">Learn more about SoftMouse Secure Socket Layer Encryption</Link>.</li><br />
                                    <li className="text">Check and verify the address (URL) of the websites you visit. <Link className="textLink" to="/whois">Learn how to verify the SoftMouse.NET registration ownership Information</Link>.</li>
                                </ul>
                            </div>
                    </div>
                    <hr style={{borderBottom: "2px solid lightblue"}} />
                    <div className="content-section-b">    
                        <div className="row">
                                <h4 className="caption"><b>Good Email Security Hygiene</b></h4>
                                <p className="text">Email is one of the main infection methods.</p><br /><br />
                                <ul className="data_list">
                                    <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                                    <li className="text">If you are EVER unsure, about the source of an email don’t open it.</li> <br />

                                    <li className="text">Please note that Iseehear Inc. will never ask for your credit card or other personal information by email.</li><br />

                                    <li className="text">Do not send Credit Card information in your email correspondence  with Iseehear Inc. <Link className="textLink" to="#">Learn more about secure payment options here</Link></li><br />

                                    <li  className="text">Be cautious of unsolicited or suspicious emails asking for any personal information or that refer you to web sites that may ask for personal information.</li><br />

                                    <li className="text">Avoid clicking on email links or downloading email attachments from sources you do not know or trust.</li>
                                </ul>
                        </div>
                    </div>
                    <hr style={{borderBottom: "2px solid lightblue"}} />
                    <div className="content-section-b">
                            <div className="row">
                                    <h4 className="caption"><b>Good Password Security Hygiene</b></h4>
                                <ul className="data_list">
                                    <li className="text">When creating a password it is always best to use:</li>
                                    <ol className="malware_list">
                                        <li className="text">Uppercase character A, B, C, D, … X, Y, Z</li>
                                        <li className="text">Lowercase character a, b, c, d, … x, y, z</li>
                                        <li className="text">A number 0, 1, 2, 3, 4, 5, 6, 7, 8, 9</li>
                                        <li className="text">Special Character like !, @, #, $, %, ^, &, *, ?, _, ~</li>
                                        <li className="text">The  length of your password should be minimum 8 characters'é</li>
                                    </ol>

                                    <li className="text">It is good practice in general to change your passwords and security questions frequently, and to review all your services and financial accounts for any suspicious activity.</li><br />
                                    <li className="text">Do not use the same password on multiple accounts.</li>
                                </ul>
                            </div>
                    </div>
                    <hr style={{borderBottom: "2px solid lightblue"}} />
                    <div className="content-section-b">
                            <div className="row">
                                    <h4 className="caption"><b>Good Data Backup Hygiene Practices</b></h4>

                                <ul className="data_list">
                                    <li className="text">Backing up important data is the single most effective way of combating ransomware infection. <Link className="textLink" to="/dataBackup">Learn more about how Iseehear/SoftMouse provides you with multiple backup options</Link>.</li><br />
                                    <li className="text">Individuals should ensure that backups are appropriately protected or stored so that attackers can’t delete them.</li><br />
                                    <li className="text">The SoftMouse.NET Support Desk, recommends that our subscribers backup their data to a spreadsheet or PDF at least once a week or every two weeks.</li><br />
                                    <li className="text">Use Iseehear Inc. cloud services to help you retain previous versions of your colony management data files. </li>
                                </ul>
                            </div>
                    </div>
                    <hr style={{borderBottom: "2px solid lightblue"}} />
                    <div className="content-section-b">
                            <div className="row">
                                    <h4 className="caption"><b>Good Software update(s) Hygiene</b></h4>
                                <ul className="data_list">
                                    <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                                    <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                                    <li className="text">Anti-virus security software needs to be installed on every single computer and mobile device you own or use.</li><br />
                                    <li className="text">Always keep your Anti-virus security software up-to-date to protect your data and yourself.</li><br />
                                    <li className="text">Run a virus scan on your computers daily.</li><br />
                                    <li className="text">Keep your operating system and other software updated.</li><br />
                                    <li className="text">Software updates will frequently include patches for newly discovered security vulnerabilities that could be exploited by attackers.</li>
                                </ul>
                            </div>
                    </div>
                    <hr style={{borderBottom: "2px solid lightblue"}} />
                    <div className="content-section-b">
                            <div className="row">
                                <h4 className="caption"><b>Good Credit Card Hygiene</b></h4>
                                <ul className="data_list">
                                    <li className="text">DO NOT send Credit Card information in your email correspondence with Iseehear Inc. Life Sciences. <A className="textLink" to="#Email-Security-Hygiene">Learn more about Email Security Hygiene</A></li><br />
                                    <li className="text">DO NOT provide personal identifiable credit card information over the telephone as this would not be in compliance with privacy and credit card policies.</li>
                                </ul>
                            </div>
                    </div>

                </div>

            </section>
            <Footer />
        </div>
    )
}