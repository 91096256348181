import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const BrowserRequirements = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Browser Requirements</title>
                <meta name="description" content="We recommend the use of one of these supported browsers for an optimal workflow experience with your SoftMouseDB Colony Management account." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="browsers-support">
                <div className="container">

                        <div className="row data-caption">
                                <div className="col-lg-9">

                                    <h3 className="mainCaption">SoftMouse Browser Requirements</h3>
                                    <hr style={{borderBottom: "2px solid lightblue"}} />
                                                                 
                                    <h4 className="caption">Information Security Management (ISM)</h4><br />
                                </div>
                        </div>

                        <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px"}}>

                                <div className="row browser-description">
                                    <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                                    <h3 className="subCaption">SoftMouse DB is compatible with the following Internet browsers:</h3> <span className="bold "><p className="text">Chrome, FireFox, Safari and Edge.</p></span><br /><br />
                                    <div className="browsers">
                                        <a href="https://www.google.com/chrome/browser/desktop/" target="_blank" rel="noreferrer" style={{display: "inline", padding: "0 10px"}}><img className="data_list" src={require("../../img/soft-mouse-chrome-browser-0004.png")} alt="softmouse" /></a>
                                        <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noreferrer" style={{display: "inline", padding: "0 10px"}}><img className="data_list" src={require("../../img/soft-mouse-firefox-browser-0004.png")} alt="softmouse" /></a>
                                        <a href="https://support.apple.com/downloads/safari" target="_blank" rel="noreferrer" style={{display: "inline", padding: "0 10px"}}><img className="data_list" src={require("../../img/soft-mouse-safari-browser-0004.png")} alt="softmouse" /></a>
                                        <a href="http://www.microsoft.com/en-ca/download/details.aspx?id=48126" target="_blank" rel="noreferrer" style={{display: "inline", padding: "0 10px"}}><img className="data_list" src={require("../../img/soft-mouse-edge-browser-0004.png")} alt="softmouse" /></a>
                                    </div>
                                    <br /><br />
                                    <h3 className="subCaption">We recommend the use of one of these supported browsers for an optimal workflow experience with your <b>SoftMouse<i>DB</i></b> Colony Management account.</h3><br />
                                    <p className="text">Download</p>
                                    <div className="browsers-download">                   
                                        <a className="data_list text" href="https://www.google.com/chrome/browser/desktop/" target="_blank" rel="noreferrer">Chrome <img src={require("../../img/external-link-red.png")} alt="link" /></a>
                                        <a className="data_list text" href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noreferrer">Firefox <img src={require("../../img/external-link-red.png")} alt="link" /></a>
                                        <a className="data_list text" href="https://support.apple.com/downloads/safari" target="_blank" rel="noreferrer">Safari <img src={require("../../img/external-link-red.png")} alt="link" /></a>
                                        <a className="data_list text" href="http://www.microsoft.com/en-ca/download/details.aspx?id=48126" target="_blank" rel="noreferrer">Edge <img src={require("../../img/external-link-red.png")} alt="link" /></a>
                                    </div>
                                        <br /><br />
                                    <p className="text">Internet Browser Security Hygiene Tips: <Link className="textLink" to="/securityHygiene">Learn more</Link></p><br />
                                    <br />
                                    
                                </div>
                        </div>
                        <hr style={{borderBottom: "2px solid lightblue"}} />
                        <div className="content-section-b" style={{paddingBottom: "20px", paddingTop: "20px;"}}>
                            <div className="row">
                                <h4 className="caption"><b>Keep your Internet browsers up-to-date </b></h4>
                                <ul className="google-list">
                                    <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                                    <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                                    <li className="text"><a className="textLink" href="https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en-GB" target="_blank" rel="noreferrer">Update Google Chrome <img src={require("../../img/external-link-red.png")}  alt="link" /></a> - https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en-GB </li> <br />
                                    <li className="text"><a className="textLink" href="https://support.mozilla.org/en-US/products/firefox/install-and-update-firefox" target="_blank" rel="noreferrer">Update Firefox <img src={require("../../img/external-link-red.png")}  alt="link" /></a> - https://support.mozilla.org/en-US/products/firefox/install-and-update-firefox</li><br />
                                    <li className="text"><a className="textLink" href="https://support.apple.com/en-ca/HT204416" target="_blank" rel="noreferrer">Update Safari <img src={require("../../img/external-link-red.png")}  alt="link" /></a> Safari is part of the MAC Operating System and can't be separately updated. It receives updates through MAC Operating System  Update, like the rest of the operating system.</li><br />
                                    <li className="text">Microsoft Edge is part of the Windows operating system and can't be separately updated. It receives updates through Windows Update, like the rest of the operating system.</li>
                                </ul>
                            </div>
                        </div>
                    <div className="text"><img src={require("../../img/external-link-red.png")}  alt="link" />&nbsp;&nbsp;Indicates the link goes to a third party website.</div><br />
                </div>
            </section>
            <Footer />
        </div>
    )
}