import React from 'react'
import ShowQuestionData from './ShowQuestionData';
import { useContext } from 'react';
import GeneralContext from '../../Context/GeneralContext';

export const FetchDataQuestions = ({sectionid, searchValue}) => {

    const {questions} = useContext(GeneralContext)

    const filterSentences = (questions) => {
        const searchWords = searchValue.trim().toLowerCase().split(' ');
        return questions.filter((questionData) => {
        return searchWords.every((word) => questionData.question.toLowerCase().includes(word) || questionData.answer.toLowerCase().includes(word));
        });
      };
      
    const filteredSentences = filterSentences(questions);

        return (
            <>        
                {filteredSentences
                .map((questionData) => {                   
                    if(sectionid === questionData.section_id) {
                        return (
                            <ShowQuestionData 
                                key={questionData.id} 
                                {...questionData}
                            />
                        )                
                    }
                })} 
            </>
        )
}