import { createContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import React from "react";
import GeneralReduser from "./GeneralReduser";
import {GET_QUESTIONS} from "../Components/ApiURL";
import {GET_PASSWORD} from "../Components/ApiURL";
import {GET_SECTIONS} from "../Components/ApiURL";
import {GET_USER} from "../Components/ApiURL";
const GeneralContext = createContext();
export const GeneralProvider = ({ children }) => {
  const initialState = {
    question: [],
    error: null,
  };

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [sections, setSections] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [userName, setUserName] = useState([]);
  const [state, dispatch] = useReducer(GeneralReduser, initialState);

  const fetchAllQuestions = () => {
    axios
      .get(GET_QUESTIONS)
      .then((res) => setQuestions(res.data))
      .catch((err) => console.log(err.msg));
  };

  const fetchAllUserName = () => {
    axios
      .get(GET_PASSWORD)
      .then((res) => setUserName(res.data))
      .catch((err) => console.log(err.msg));
  };

  const fetchAllSections = async () => {
    try {
      const sections = await axios.get(GET_SECTIONS);
      setSections(sections.data);
    } catch (error) {
      console.log("This is an Error of Fetch data", error);
      setError();
    }
  };

  const onRemoveItem = async (questionData) => {
    await axios.delete(
      `${GET_QUESTIONS}/${questionData}`
    );
  };

  const onRemoveUser = async (username) => {
    await axios.delete(
      `${GET_USER}/${username}`
    );
  };

  const setError = () => dispatch({ type: "SET_ERROR" });

  useEffect(() => {
    fetchAllSections();
    fetchAllQuestions();
    fetchAllUserName();
  }, [ignored]);

  return (
    <GeneralContext.Provider
      value={{
        userName: userName,
        sections: sections,
        questions: questions,
        error: state.error,
        forceUpdate,
        onRemoveItem,
        onRemoveUser,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralContext;
