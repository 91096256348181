import React from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { HashLink as A } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const EmailPolicy = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Email Policy Notice</title>
                <meta name="description" content="An electronic mail message or e-mail is a recognized form of communication in business due to the ease and speed of sending and receiving information electronically. E-mail communications serve as records that provide information about and evidence of business or personal activities, decision-making, agreements and service accountability. Please read the Iseehear E-mail Policy carefully so that you understand our policy and practices." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="E-mailPolicyNotice">
                <div className="container">

                    <div className="row data-caption">
                        <div className="col-lg-9">

                            <h3 className="mainCaption">E-mail Policy Notice</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />

                        </div>
                    </div>

                    <div className="polisy-summary subscription-list">
                        <p className="text">Effective Date: May 25, 2018 This Privacy Policy may be amended from time to time.</p><br /><br />
                        <img src={require("../../img/iseehear-logo.png")} alt="logo" className="policiLogo" /><br /><br />
                        <p className="text">Iseehear Inc. Life Sciences (“Iseehear”, “we”, “us”, and “our”).</p><br /><br />
                        <span className="caption">Email:</span><br /><br />

                        <p className="text">An electronic mail message or e-mail is a recognized form of communication in business due to the ease and speed of sending and receiving information electronically. E-mail communications serve as records that provide information about and evidence of business or personal activities, decision-making, agreements and service accountability. Please read the Iseehear E-mail Policy carefully so that you understand our policy and practices.</p><br />
                    </div>

                    <div className="google-list">
                        <A className="text" to="#emailpolicy">Iseehear Email Policy</A><br />
                        <A className="text" to="#confidentiality">Iseehear Email Confidentiality Notice</A><br />
                        <A className="text" to="#disclaimer">Disclaimer</A><br />
                        <A className="text" to="#why">Why You are Receiving this E-Mail</A><br />
                        <A className="text" to="#unsubscribe">How to Unsubscribe</A><br />
                        <A className="text" to="#personal-information">Your Personal Information</A><br />
                        <A className="text" to="#customer-data">Our Policy on Customer Research Data Rights</A><br />
                        <A className="text" to="#no-spam">Our No SPAM Policy</A><br />
                    </div>

                    <div id="emailpolicy" className="subscription-list">
                        <span className="caption">Iseehear Email Policy</span><br /><br />
                        <h3 className="subCaption">Marketing Emails</h3><br />
                        <p className="text">We do not send marketing related email promotions without prior consent from our visitors, registrants and subscribers. When you click the consent box on any Iseehear registration form or consent form, Iseehear may, from time to time, use your email address to send you information on Iseehear's services, applications, webinars, seminars, conference attendance, and topics of current interest. If you DO NOT want to receive Iseehear marketing related email promotions then please DO NOT click the consent box on any Iseehear registration form or consent form. (Please view our <Link className="textLink" to="/privacyPolicy">Privacy Policy</Link>)</p><br /><br />
                        <h3 className="subCaption">Essential Emails</h3><br />
                        <p className="text">Essential emails and legitimate business purposes: Please note Iseehear will send only essential emails of legitimate business interest related to; customer support, scheduled upgrades, new features / upgrades, service / system maintenance bulletins and security bulletins.</p><br /><br />
                        <h3 className="subCaption">Free Trial Emails</h3><br />
                        <p className="text">FREE TRIAL Registration Email Confirmation: After you have registered for a FREE TRIAL, you will receive an email confirming your registration and that your account has been activated. (Please view our <A className="textLink" to="/freeTrialPolicy#free-trial-registration-process">FREE TRIAL Registration Process</A>) <br />Please submit complete and accurate information in the Registration and Subscription process. Your email address is an important tool in our essential business communication with you. (Please view our <Link className="textLink" to="/freeTrialPolicy">Free Trial Policy</Link>)</p><br /><br />
                        <p className="text">We do not send Spam emails (Please view our <A className="textLink" to="#no-spam">No Spam Policy</A>).</p>
                    </div>

                    <div id="confidentiality" className="subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <span className="caption">Iseehear Email Confidentiality Notice</span><br /><br />
                        <p className="text">This email may contain privileged and confidential information only for the use of the addressee named above. If you are not the intended recipient of this email or the employee or agent responsible for delivering it to the recipient, you are hereby notified that any dissemination or copying or saving of this email is strictly prohibited. <br /><br />If you have received this email in error, please immediately notify Iseehear Inc. by email and or phone (+1 416-492-4358). You are advised to immediately delete this email transmission from your hard drive.</p>
                    </div>

                    <div id="disclaimer" className="subscription-list">
                        <span className="caption">Disclaimer</span><br /><br />
                        <p className="text">Iseehear Inc accepts no liability for the content of this e-mail, or for the consequences of any actions taken on the basis of the information provided.
                        Electronic mail (e-mail) is not a 100% secure nor can it be guaranteed to be error-free as information could be intercepted, corrupted, lost, destroyed, arrive late or incomplete, or contain viruses. You should be aware of this when e-mailing Iseehear Inc. or receiving an e-mail from Iseehear Inc. Therefore, Iseehear Inc does not accept responsibility for any errors or omissions that are present in this message, or any attachment, that have arisen as a result of e-mail transmission. If verification is required, please request a hard-copy version.
                        Iseehear Inc. has taken reasonable precautions to ensure no viruses are present in this e-mail. 
                        Iseehear Inc. cannot accept responsibility for any loss or damage arising from the use of this e-mail or attachments. This e-mail and any attachments have been scanned for viruses, but it is the responsibility of the recipient to conduct their own security measures and no responsibility is accepted by Iseehear Inc for the loss or damage arising from the receipt or use of this email.</p>
                    </div>

                    <div id="why" className="subscription-list">
                        <span className="caption">Why You are Receiving this E-Mail</span><br /><br />
                        <p className="text">You are receiving this e-mail from the Iseehear Support Desk because you have registered for a 30 DAY FREE TRIAL or are a registered subscriber of an Iseehear Inc. data management service. Receiving e-mail from the Iseehear Support Desk allows us to communicate new feature releases, important service related updates and system alerts to our subscribers. For effective customer service communication it is important to ensure that you continue to receive e-mails from the Iseehear Support Desk.</p>
                    </div>

                    <div id="unsubscribe" className="subscription-list">
                        <span className="caption">How to Unsubscribe</span><br /><br />
                        <p className="text">If you would like to unsubscribe from future communications from Iseehear, please email: unsubscribe@iseehear.com with unsubscribe in the subject line.</p>
                    </div>

                    <div id="personal-information" className="subscription-list">
                        <img className="element3" src={("../../img/newPage/element.png")} alt="element" />
                        <img className="element" src={("../../img/newPage/element.png")} alt="element" />
                        <span className="caption">Your Personal Information</span><br /><br />
                        <p className="text">Personal Information (identifiable personal data) collected about you, by Iseehear, including registration contact information, allows us to communicate with you and respond in a timely
                            manner to enquiries or follow ups about your subscription, work flow, our services and applications.</p><br /><br />
                        <p className="text">We do not disclose your personal information to other organizations or individuals, except as required to fulfill the purpose(s) of the transaction or only to the extent required by law.
                            Should any situation arise which would cause the use of personal information which would be inconsistent with the above statement, your prior consent for such use will be required.</p><br /><br />
                        <p className="text">Please visit and read the Iseehear Inc. <Link className="textLink" to="/privacyPolicy">Privacy Policy</Link> Your personal information is protected under the Federal Privacy Act of Canada. For more information on the Privacy Act,
                            please visit <a className="textLink" href="https://www.priv.gc.ca/en/report-a-concern" target="_blank" rel="noreferrer">https://www.priv.gc.ca/en/report-a-concern  <img src={require("../../img/external-link-red.png")} alt="link-red" /></a>.</p>
                    </div>

                    <div id="customer-data" className="subscription-list">
                        <span className="caption">Intellectual Property Research Data Rights Policy</span><br /><br />
                        <p className="text">First and foremost, the research data that our customers enter into the SoftMouse.NET database is their proprietary data and each customer maintains and retains full ownership of their data at all times. The Iseehear Inc. Life Sciences <Link className="textLink" to="/documents/subscription+agreement.pdf" target="_blank" rel="noreferrer">Subscription Agreement</Link> addresses customer data rights in these sections:</p><br /><br />
                        <ol className="data_list">
                            <li className="text">Article 2.2 Nature of Services</li>
                            <ul>
                                <li className="text">2.2b</li>
                                <li className="text">2.2d</li>
                            </ul>
                            <li className="text">SCHEDULE A - TERMS OF USE</li>
                            <ul>
                                <li className="text">Intellectual Property</li>
                            </ul>
                        </ol><br />
                        <p className="text">Our systems logically isolate each customer’s data from that of other customers, even when the data is stored on the same physical server. For our employees, access rights and levels are based on their job function and role, using the concepts of least-privilege and need-to-know to match access privileges to defined responsibilities.</p><br /><br />
                        <p className="text">To ensure the confidentiality, integrity, and availability of customer data, internal access to customer data is limited to three individuals at Iseehear Inc. Life Sciences: the CEO, Customer Service Manager and Cloud Operations Manager (Cloud Admin).</p><br /><br />
                        <p className="text">Internal access to customer research data is dictated by our Security Policy on Internal Access to Customer Data.</p><br /><br />

                            <h3 className="subCaption">Security Policy on Internal Access to Customer Data:</h3><br />
                            <ol className="data_list">
                                <li className="text">Customer data is only accessed in the event of a support-related inquiry that cannot otherwise be addressed via email/telephone troubleshooting or screen sharing.</li>
                                <li className="text">Only three members of the SoftMouse.NET Support Desk Team have the ability to access customer data.</li>
                                <li className="text">Before customer data is accessed, the customer must provide data access consent.</li>
                                <li className="text">In order to provide data access consent the customer must change their Access Permissions for Intellectual Property and Research Data Rights:</li>
                                <ol>
                                    <li className="text">1. Customer logs into their SoftMouse.NET account</li>
                                    <li className="text">2. Go to the “My Profile”</li>
                                    <li className="text">3. Scroll down to “Access Permissions for Intellectual Property Research and  Data Rights” section</li>
                                    <li className="text">4. Select “Yes” in the drop-down menu (“No” is automatically selected by default)</li>
                                    <li className="text">5. Confirm your selection (in the pop-up window on the screen)</li>
                                    <li className="text">6. Then click save button and a confirmation email will be sent to the customer</li>

                                </ol>
                                <li className="text">Once permission has been granted by the customer, their data can then be accessed if necessary by the Support Desk team in order to address a specific customer issue or request.</li>
                            </ol><br />

                            <p className="text">In the event of contract / subscription termination Iseehear Inc. Life Sciences will provide a backup of customer data in a spreadsheet format. If there is a written request for the data to be deleted, a letter confirming deletion will be provided.</p>
                    </div>

                    <div id="no-spam" className="subscription-list">
                        <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                        <span className="caption">Our No SPAM Policy</span><br /><br />
                        <p className="text">We <b>do not</b> trade, buy or sell e-mail addresses. We <b>do not</b> send unsolicited e-mails.  We <b>do not</b> SPAM you with weekly annoying or unethical junk e-mail.</p><br /><br />
                        <p className="text">Iseehear Inc. has not sent, does not send, and will not send out unsolicited email, also known as spam. We will never send you unsolicited emails or pass your details onto others who would. When you submit your business information and e-mail address to us it will be used solely for the purpose of communicating follow-ups, subscription and Iseehear Support Desk service information.</p><br /><br />
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}