import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { HashLink as A } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

export const SubscriptionAgreement = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Subscription Agreement</title>
                <meta name="description" content="Iseehear has developed an information technology application primarily for use by scientific researchers the purpose of which is to enhance collaboration among researchers and related stakeholders." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="ubscriptionAgreement">
                    <div className="container">

                        <div className="row data-caption">
                            <div className="col-lg-9">
                                <h3 className="mainCaption">Subscription Agreement</h3>
                                <hr style={{borderBottom: "2px solid lightblue"}} />
                            </div>
                        </div>

                        <div className="polisy-summary">
                            <p className="text">Effective Date: October 22, 2020 This Subscription Agreement may be amended from time to time.</p><br /><br />
                            <img src={require("../../img/iseehear-logo.png")} alt="logo" className="policiLogo" /><br /><br />
                            <p className="text">Iseehear Inc. Life Sciences (“Iseehear”, “we”, “us”, and “our”).</p><br /><br />
                        </div>

                        <div className="google-list anchorLink">
                            <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                            <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                            <div className="anchor-link">
                                <A className="text" to="#ISEEHEARINCSUBSCRIPTIONAGREEMENT">ISEEHEAR INC. LIFE SCIENCES SUBSCRIPTION AGREEMENT</A><br />
                                <A className="text" to="#BACKGROUND">BACKGROUND</A><br />
                                <A className="text" to="#ARTICLE1">ARTICLE 1: DEFINITIONS AND INTERPRETATION</A><br />
                                <A className="text" to="#ARTICLE2">ARTICLE 2: SUBSCRIPTION FOR SERVICES</A><br />
                                <A className="text" to="#ARTICLE3">ARTICLE 3: GRANT OF RIGHT TO USE</A><br />
                                <A className="text" to="#ARTICLE4">ARTICLE 4: PAYMENT TO ISEEHEAR</A><br />
                                <A className="text" to="#ARTICLE5">ARTICLE 5: INTELLECTUAL PROPERTY OWNERSHIP</A><br />
                                <A className="text" to="#ARTICLE6">ARTICLE 6: CONFIDENTIALITY</A><br />
                                <A className="text" to="#ARTICLE7">ARTICLE 7: COVENANTS, REPRESENTATIONS AND WARRANTIES</A><br />
                                <A className="text" to="#ARTICLE8">ARTICLE 8: INTELLECTUAL PROPERTY INFRINGEMENT</A><br />
                                <A className="text" to="#ARTICLE9">ARTICLE 9: TERMINATION</A><br />
                                <A className="text" to="#ARTICLE10">ARTICLE 10: DEFAULTS</A><br />
                                <A className="text" to="#ARTICLE11">ARTICLE 11: GENERAL</A><br />
                                <A className="text" to="#SCHEDULEATERMSOFUSE">SCHEDULE A – TERMS OF USE</A><br />
                                <A className="text" to="#SCHEDULEBFEES">SCHEDULE B – FEES</A><br />
                                <A className="text" to="#SCHEDULECSERVICELEVELCOMMITMENTS">SCHEDULE C – SERVICE LEVEL COMMITMENTS</A><br />
                                <A className="text" to="#SCHEDULEDADDITIONALPROVISIONS">SCHEDULE D – ADDITIONAL PROVISIONS</A><br />    
                            </div>
                            <div>
                                <Link className="text pull-right" to="/documents/subscription-agreement.pdf" target="_blank" rel="noreferrer">Download PDF <img src={require("../../img/SoftMouse_download_black.png")} alt="softmouse" /></Link><br />
                            </div>
                        </div>

                        <div id="ISEEHEARINCSUBSCRIPTIONAGREEMENT" className="subscription-list">  
                            <p className="text">THIS AGREEMENT is made B E T W E E N:</p>
                            <p className="text">ISEEHEAR INC. LIFE SCIENCES, a company incorporated in Ontario, with its principal office at 250 University Avenue, Suite 200, Toronto, Ontario, Canada, M5H 3E5</p>
                            <p className="text">(“ISH” / "ISEEHEAR")</p>
                            <p className="text">- and –</p>
                            <p className="text">The registered applicant to this Subscription Agreement whose contact information has been provided to Iseehear in the course of entering into this electronic contract.</p>
                            <p className="text">(“Subscriber”)</p>
                        </div>

                        <div id="BACKGROUND" className="subscription-list">
                            <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                            <span className="caption">BACKGROUND:</span><br /><br />       
                            <p className="text">Iseehear has developed an information technology application primarily for use by scientific researchers the purpose of which is to enhance collaboration among researchers and related stakeholders. </p>
                            <p className="text">Access to the Iseehear Technology is delivered by an Internet browser accessible Website that contains the records for one or more Research Projects as determined by the Subscriber. Website Users will be authorized by the Subscriber to create and access documents for Research Projects relevant to the Website Users by means of Username and Password authentication. The rights of various Website Users will be determined by the Subscriber's administrators.</p>
                            <p className="text">This Agreement, including the schedules hereto, (collectively, the "Agreement") sets out the provisions pursuant to which the Subscriber is entitled to utilize the Technology.</p>
                        </div>

                        <div id="ARTICLE1" className="subscription-list">
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne1" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne1">
                                    <h3 className="caption">ARTICLE 1</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne1" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne1">
                                <div className="accordion-body">
                                
                                    <h3 className="subCaption">DEFINITIONS AND INTERPRETATION</h3>
                                    <h3 className="subCaption">1.1	Definitions</h3>
                                    <p className="text">Unless the context otherwise specifies or requires, for the purposes of this Agreement all capitalized terms set out herein shall have the meanings set forth below:</p>
                    
                                    <br />
                                    <p className="text">"Business Day" means a day on which the head office of each of the parties is open for business, not including a Saturday, Sunday or statutory holiday;</p>
                                    <br />
                                    <p className="text">"Business Information" means all</p>
                                    <ul className="article">
                                        <li className="text">financial, business and personal information and data relating to Subscriber and Iseehear and their respective customers, clients, affiliates, consultants and employees including, in the case of Subscriber, all Materials and other information respecting any Research Projects and including, in the case<br />
                                            of Iseehear, all feedback, suggestions and requirements submitted by users of the Technology;</li><br />                
                                        <li className="text">products and services supplied by each of Subscriber and Iseehear and their respective affiliates;</li><br />
                                        <li className="text">business and marketing plans and strategies each of Subscriber and Iseehear; and,</li><br />
                                        <li className="text">studies, customer lists, charts, plans, tables and compilations of business and industry information acquired or prepared by or on behalf of either of Subscriber or Iseehear;</li><br />
                                    </ul>               
                                        <p className="text">"Confidential Information" means all information contained in any form whatsoever that is not generally available to or used by others or the utility or value of which is not generally known or recognized, whether or not the underlying details are in the public domain, that is acquired or developed by or on behalf of either of the parties from time to time and that is disclosed or made available by a Disclosing Party to a Receiving Party in connection with this Agreement or the performance of obligations hereunder, and includes in the case of Subscriber, all Materials, information in relation to Research Projects and Subscriber's Business Information, and, in the case of Iseehear, all Iseehear's Business Information and the Technology, whether patentable or not;</p>
                                        <p className="text">“CSV” stands for "comma-separated values”. CSV is a simple file format used to store tabular data, such as a spreadsheet or database. Files in the CSV format can be imported to and exported from programs that store data in tables, such as Microsoft Excel or OpenOffice Calc.</p>             
                                        <p className="text">"Default" has the meaning set out in Article 10;</p>        
                                        <p className="text">"Disclosing Party" means a party to this Agreement that discloses, either directly or through agents, Confidential Information to a Receiving Party;</p>
                                        <p className="text">"Fees" means the fees to be paid to Iseehear by Subscriber pursuant to Section 4.1 for the Services;</p>
                                        <p className="text">"Force Majeure Event" has the meaning ascribed thereto in Section 11.1;</p>               
                                        <p className="text">"Functional Specifications" means the description of the functionality of the Technology as set out in Iseehear's help screens;</p>
                                        <p className="text">"Iseehear Event of Default" means a Default, other than a breach of a Service Level Commitment, by Iseehear, the notice and cure periods (if any) respecting which have expired without the curing of the Default;</p>
                                        <p className="text">"Materials" means the materials relating to Research Projects placed from time to time on the Website for access by Website Users but not including anything placed on the Website by Iseehear.</p>
                                        <p className="text">"Receiving Party" means a party to this Agreement that receives Confidential Information from a Disclosing Party;</p>
                                        <p className="text">"Representative" means any employee, researcher, agent or independent contractor of a party to this Agreement;</p>
                                        <p className="text">"Research Project" means a single Research Project (which may include any number of related sub-Research Projects) involving Subscriber and other Website Users, where Subscriber is either a member of the research team for the Research Project or an adviser to the Research Project;</p>
                                        <p className="text">"Services" means the services provided by Iseehear pursuant to this Agreement, including access to the Website via Chrome, FireFox, Safari, Edge (or other browser approved and supported by Iseehear) and other Iseehear technology, data encryption, transmission, access and storage of Materials in digital form during the course of a Research Project;</p>
                                        <p className="text">"Service Level Commitment" means a service level commitment to Subscriber by Iseehear contained in Schedule C.</p>
                                        <p className="text">"Subscriber Event of Default" means a Default by Subscriber or any Website User, the notice and cure periods (if any) respecting which have expired without the curing of the Default;</p>
                                        <p className="text">“Subscription Term” means subscription services that run for a set period of time, such as one year, two years etc;</p>
                                        <p className="text">“Telecommunications Systems” means information technology systems, third party internet services;</p>
                                        <p className="text">"Technology" means the information technology application developed by Iseehear including such features as checklists and tables, the ability to build multiple checklists, tables, sub-lists and sub-tables for a variety of sub-components of a Research Project, post documents, search documents, manage multiple drafts of Materials and document commentaries, assign tasks, the</p>
                                        <p className="text">tracking of changes to documents, the authorization and tracking of access to documents, the generation of reports, the generation and viewing of Research Project "dashboards", the organization and completion of Research Projects, the management of research groups, the generation of Research Project alerts and the facilitation of the publication of digital reports and tables;</p>
                                        <p className="text">"Website" refers to each Internet-accessible web site containing Research Project data and documents that is accessible, depending on authorizations by the Subscriber, to Website Users via Chrome, FireFox, Safari, Edge (or other browser approved and supported by Iseehear) and that contains Materials posted by Website Users pertaining to Research Projects and that is enabled by the Technology; and</p>
                                        <p className="text">"Website Users" means those persons who are authorized by Subscriber to access the Website for purposes of participating in one or more Research Projects in accordance with this Agreement.</p>              
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo1" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo1">
                                    <h3 className="caption">1.2 Construction of Agreement</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo1" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo1">
                                <div className="accordion-body">
                                    <h3 className="subCaption">In this Agreement:</h3>
                                    <ol className="article">
                                        <li className="text">all usage of the word "including" or the phrase "e.g." in this Agreement shall mean "including, without limitation" throughout this Agreement; and</li>
                                        <li className="text">words or abbreviations which have well known or trade meanings are used herein in accordance with their recognized meanings.</li>
                                    </ol>    
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree1" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree1">
                                    <h3 className="caption">1.3 Schedules</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree1" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree1">
                                <div className="accordion-body">
                                    <p className="text">Schedules A (Terms of Use), B (Fees), C (Service Level Commitments) and D (Additional Provisions) annexed hereto form part of this Agreement.</p>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div id="ARTICLE2" className="subscription-list">
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne2" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne2">
                                    <h3 className="caption">ARTICLE 2</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne2" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne2">
                                <div className="accordion-body">                            
                                    <h3 className="subCaption">SUBSCRIPTION FOR SERVICES</h3>
                                    <h3 className="subCaption">2.1 Subscription</h3>
                                    <p className="text">Subscriber hereby subscribes for the Services as set out herein, and Iseehear hereby agrees to provide the Services in accordance with this Agreement provided that</p>
                                    <p className="text">Subscriber carries out its obligations set out in this Agreement.</p>   
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo2" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo2">
                                    <h3 className="caption">2.2 Nature of Services</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo2" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo2">
                                <div className="accordion-body">
                                    <h3 className="subCaption">In this Agreement:</h3>
                                    <ol className="article">
                                        <li className="text">Iseehear makes available to Subscriber and Website Users a digital information repository pertaining to Research Projects where Website Users can examine digitally stored project documents and, where authorized, add to or modify same. Iseehear will maintain the Technology in good working order in accordance with its Functional Specifications and will provide support desk response to Website Users within 24 hours of receipt by way of phone or
                                            email. Subscriber and Website Users have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all Materials and Iseehear shall not be responsible or liable for the deletion, correction, destruction, damage, loss or failure by Subscriber or Website Users or for their failure to store any Materials or for their misuse of the Technology.</li>
                                        <li className="text">If Subscriber requests by email, Iseehear will make available to Subscriber, by way of download, a CSV digital file of the Materials within 10 days of delivery of such request. It will be the responsibility of the Subscriber to effect the download within such 10 day period. This provision will survive termination of this Agreement for 30 days.</li>
                                        <li className="text">Upon termination for Subscriber Event of Default, the right of Subscriber and Website Users to access or use Materials immediately ceases, and Iseehear shall have no obligation to maintain any Materials beyond a period of 30 days following termination.</li>
                                        <li className="text">ISEEHEAR WILL NEVER EXERCISE ANY LIEN AGAINST THE MATERIALS OR OTHER DATA STORED ON THE WEBSITE AND WILL NEVER BREACH ITS CONFIDENTIALITY OBLIGATIONS CONTAINED IN THIS AGREEMENT FOR ANY REASON, INCLUDING TERMINATION OF THIS AGREEMENT.</li>
                                    </ol>    
                                </div>
                                </div>
                            </div>
                        </div>

                        <div id="ARTICLE3" className="subscription-list">
                            <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                            <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                            <span className="caption">ARTICLE 3</span><br /><br />     
                            <h3 className="subCaption">GRANT OF RIGHT TO USE</h3>
                            <h3 className="subCaption">3.1 Grant of Right to Use</h3>
                            <ol className="article">
                                <li className="text">Iseehear hereby grants Subscriber the right to access and use, and to grant the Website Users access to, and use of, the Technology only via the Website and offline modules forming part of the Technology and for the sole purpose of providing Website Users access to the Materials. The right granted to Subscriber to use the Technology, and the Services, terminates when this Agreement terminates or expires. Iseehear retains all rights in the Technology not specifically granted to Subscriber.</li>
                                <li className="text">Subscriber acknowledges that each Website User may be required to agree to Iseehear's Terms of Use (attached as Schedule A) prior to being permitted to access and use the Website.</li>
                                <li className="text">Subscriber does not have the right, and hereby covenants not, to reverse engineer, disassemble, decompile, unlock, recreate, copy, license, sublicense, create derivative software from, sell, rent, lease, loan, distribute, transfer or otherwise deal with the Technology or any part thereof.</li>
                            </ol>   
                        </div>

                        <div id="ARTICLE4" className="subscription-list">
                            <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                            <span className="caption">ARTICLE 4</span><br /><br />     
                            <h3 className="subCaption">PAYMENT TO ISEEHEAR</h3>
                            <h3 className="subCaption">4.1 Payment to Iseehear</h3>
                            <ol className="article">
                                <li className="text">In consideration of the provision of the Services by Iseehear, Subscriber shall pay to Iseehear the Fees described in Schedule B.</li>
                                <li className="text">Subscriber shall pay all applicable taxes on all Fees, but shall not be responsible to pay any taxes on Iseehear's income.</li>
                                <li className="text">If subscriber accounts are in arrears for greater than ninety days (90 days), then Subscriber shall pay interest to Iseehear on overdue accounts at the rate of 1 percent per month (12 percent per annum), compounded annually.</li>
                                <li className="text">In addition to any other rights granted to Iseehear herein, Iseehear reserves the right, upon 30 days' written notice, to suspend access to the Website for non-payment of Fees.</li>
                            </ol>   
                        </div>

                        <div id="ARTICLE5" className="subscription-list">
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne5" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne5">
                                    <h3 className="caption">ARTICLE 5</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne5" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne5">
                                <div className="accordion-body">
                                
                                    <h3 className="subCaption">INTELLECTUAL PROPERTY OWNERSHIP</h3>
                                    <h3 className="subCaption">5.1 Intellectual Property</h3>
                                    <ul className="article">
                                        <li className="text">Iseehear owns and will own all intellectual property rights in the Technology and the Website, including the look and feel of the Website and the Technology, feedback, requirements and suggestions of Website Users, the database designs, the architecture of all information technology and any elements of such technology that are intrinsic to the Technology.</li>
                                        <li className="text">As between Iseehear and Subscriber, Subscriber will own all intellectual property rights in the Materials. Subscriber and Website Users are responsible to enter into their own agreements for intellectual property ownership among them.</li>
                                    </ul>                            
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo5" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo5">
                                    <h3 className="caption">5.2 Private Branding</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo5" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo5">
                                <div className="accordion-body">
                                    <p className="text">The parties may agree, by separate agreement, that the Website be private branded using the Subscriber's trade-mark. Notwithstanding the use of such brand on the Website, all trade-marks of Subscriber shall remain the property of Subscriber and shall not be used by Iseehear for any purpose.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree5" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree5">
                                    <h3 className="caption">5.3 Iseehear By-Line</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree5" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree5">
                                <div className="accordion-body">
                                    <p className="text">Subscriber recognizes that Iseehear shall be entitled to insert a legend or footnote on the Website indicating that the Website has been developed by Iseehear, and Iseehear may insert a hyperlink in such legend or footnote linking the Website to Iseehear's own web site the URL of which is http://www.iseehear.com.</p>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div id="ARTICLE6" className="subscription-list">
                            <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne6" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne6">
                                    <h3 className="caption">ARTICLE 6</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne6" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne6">
                                <div className="accordion-body">
                                
                                    <h3 className="subCaption">CONFIDENTIALITY</h3>
                                    <h3 className="subCaption">6.1 Confidentiality Covenant</h3>
                                    <ul className="article">
                                        <li className="text">Each party acknowledges that it would be damaging to the other party if its Confidential Information were disclosed to or obtained by third parties. Accordingly, each party shall make all reasonable efforts during the term of this Agreement and thereafter to prevent the other party's Confidential Information from being disclosed to or obtained by any person or entity for any purpose except as described in this Article 6. Each party's efforts will not be less than those that it takes to prevent disclosure of its own proprietary information of like significance. The Receiving Party will be responsible for breaches by its Representatives of this Article 6. Without limiting the generality of the foregoing, Iseehear will keep confidential all Confidential Information furnished to it or its Representatives and will use such Confidential Information solely for the purpose of providing the Services.</li>
                                    </ul>                            
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo6" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo6">
                                    <h3 className="caption">6.2 Permitted Disclosure</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo6" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo6">
                                <div className="accordion-body">
                                    <p className="text">It is not a breach of Section 6.1 to disclose Confidential Information required to be disclosed by law, judicial or arbitration process or by governmental authorities, provided that the Receiving Party first gives the Disclosing Party reasonable notice of such law, order or process and takes all reasonable steps to restrict such disclosure and protect the confidentiality to the extent possible.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree6" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree6">
                                    <h3 className="caption">6.3 Injunctive Relief</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree6" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree6">
                                <div className="accordion-body">
                                    <p className="text">Each party acknowledges and agrees that:</p>
                                    <ol className="article">
                                        <li className="text">the restrictions set forth in this Article 6 are reasonable in the circumstances and all defenses to the strict enforcement thereof by the Disclosing Party are hereby waived;</li>
                                        <li className="text">a violation of any of the provisions of this Article 6 will result in immediate and irreparable harm and damage to the Disclosing Party; and</li>
                                        <li className="text">in the event of any violation of any provision of this Article 6, the Disclosing Party shall be entitled to apply for equitable relief by way of temporary or permanent injunction and to such other relief as any court of competent jurisdiction may deem just and proper.</li>
                                    </ol>   
                                </div>
                                </div>
                            </div>
                        </div>

                        <div id="ARTICLE7" className="subscription-list">
                            <div className="accordion">
                                <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne7" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne7">
                                    <h3 className="caption">ARTICLE 7</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne7" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne7">
                                <div className="accordion-body">                  
                                    <h3 className="subCaption">COVENANTS, REPRESENTATIONS AND WARRANTIES</h3>
                                    <h3 className="subCaption">7.1 Mutual</h3>
                                    <p className="text">Each party covenants, represents and warrants to the other that it has the right to enter into this Agreement.</p>                           
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo7" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo7">
                                    <h3 className="caption">7.2 Iseehear</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo7" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo7">
                                <div className="accordion-body">
                                    <p className="text">Iseehear covenants, represents and warrants to Subscriber as follows:</p>
                                    <ol className="article">
                                        <li className="text">Subject to Section 2.2(b), Iseehear will not remove Materials from the Website during the currency of this Agreement.</li>
                                        <li className="text">Iseehear will maintain security of the Website in accordance with competent information technology practices and will notify Subscriber promptly by email if it learns of any breach of Website security. Iseehear will not allow access to the Website to any person other than its own database administrator(s) and Website Users. Iseehear will automatically monitor the Website for computer viruses but is not responsible for viruses that are contained in Materials or for any automatic quarantine or expunging of Materials that have been uploaded with viruses.</li>
                                        <li className="text">Iseehear will use reasonable commercial efforts to achieve the Service Level Commitments throughout the term of this Agreement.</li>
                                    </ol>  
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree7" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree7">
                                    <h3 className="caption">7.3 Exclusion</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree7" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree7">
                                <div className="accordion-body">
                                    <p className="text">THE REPRESENTATIONS AND WARRANTIES CONTAINED IN THIS AGREEMENT ARE IN LIEU OF ANY OTHER WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABLE QUALITY OR FITNESS FOR A PARTICULAR PURPOSE, AND THOSE ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED.</p>  
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsefoure7" aria-expanded="false" aria-controls="panelsStayOpen-collapsefoure7">
                                        <h3 className="caption">7.4 Disclaimer</h3>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapsefoure7" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingfoure7">
                                    <div className="accordion-body">
                                        <p className="text">EXCEPT AS SPECIFICALLY SET FORTH IN THIS AGREEMENT, ISEEHEAR MAKES NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, TRUTH, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICE. ISEEHEAR DOES NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA, (B) THE SERVICE WILL MEET THE REQUIREMENTS OR EXPECTATIONS OF SUBSCRIBER OR WEBSITE USERS, (C) ANY STORED DATA WILL BE ACCURATE OR RELIABLE, (D) ERRORS OR DEFECTS WILL BE CORRECTED, OR (E) THE SERVICE OR THE SERVER(S) THAT MAKE THE SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. EXCEPT AS SPECIFICALLY SET FORTH IN THIS AGREEMENT, ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABLE QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY INTELLECTUAL PROPERTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY ISEEHEAR.</p>  
                                    </div>
                                </div>
                                </div>
                        </div>

                        <div id="ARTICLE8" className="subscription-list">
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne8" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne8">
                                    <h3 className="caption">ARTICLE 8</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne8" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne8">
                                <div className="accordion-body">                            
                                    <h3 className="subCaption">INTELLECTUAL PROPERTY INFRINGEMENT</h3>
                                    <h3 className="subCaption">8.1 Indemnity by Iseehear</h3>
                                    <ul className="article">
                                        <li className="text">Iseehear shall defend or settle at its own expense any and all suits, actions or claims against Subscriber charging that:</li><br />
                                        <ul>
                                            <li className="text">1. any part of the Technology or of the Services;</li><br />
                                            <li className="text">2. the use of the Technology or the Services by Subscriber in the manner contemplated by this Agreement; or</li><br />
                                            <li className="text">3. the copying or use of any part of the materials produced by Iseehear in the course of rendering the Services;<br />
                                            infringes any relevant trade-mark, industrial design, trade secret or copyright provided that Iseehear is notified promptly, has the exclusive right to control such defense and, at its request and expense, is given authority and assistance by Subscriber reasonably required for such defense. Iseehear will pay all damages awarded in any such suit, action or claim and will indemnify and save harmless Subscriber from any other liability reasonably incurred by it as a result of such suit, action or claim.</li><br />
                                        </ul>
                                            <li className="text">If any part of the Technology or the Services is, or in Iseehear's opinion is likely to become, the subject of such a suit, action or claim, Iseehear at its option and at no expense to Subscriber may:</li><br />
                                        <ul>
                                            <li className="text">1. obtain for Subscriber from all persons who claim an interest in the Technology, the Services or the work products arising from the Services, the right allowing Subscriber to use the same as contemplated by this Agreement; or</li><br />
                                            <li className="text">2. substitute non-infringing equivalent Technology or Services;         
                                            and this indemnity shall extend thereto; provided that nothing in this Section 8.1 shall require Iseehear to indemnify Subscriber for any matter arising from modifications made by Subscriber, from any Default by Subscriber, from the combination of the Technology by Subscriber with other software, equipment, systems or process or from a claim that is based on Subscriber's use of the Technology other than in accordance with this Agreement.</li><br /><br />
                                        </ul>
                                    </ul> 
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo8" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo8">
                                    <h3 className="caption">8.2 Indemnity by Subscriber</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo8" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo8">
                                <div className="accordion-body">
                                    <p className="text">Subscriber shall defend or settle at its own expense any and all suits, actions or claims against Iseehear charging that any part of the Materials or the posting thereof on the Website infringes any relevant trade-mark, industrial design, trade secret or copyright or constitutes an illegal disclosure of the confidential information of a third party, provided that Subscriber is notified promptly, has the exclusive right to control such defense and at its request and expense, is given authority and assistance by Iseehear reasonably required for such defense. Subscriber will pay all damages awarded in any such suit, action or claim, and will indemnify and save harmless Iseehear from any other cost or liability reasonably incurred by it as a result of such suit, action or claim; provided that nothing in this Section 8.2 shall require Subscriber to indemnify Iseehear for any matter arising exclusively from any Default by Iseehear.</p>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div id="ARTICLE9" className="subscription-list">
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne9" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne9">
                                    <h3 className="caption">ARTICLE 9</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne9" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne9">
                                <div className="accordion-body">
                                
                                    <h3 className="subCaption">TERMINATION</h3>
                                    <h3 className="subCaption">9.1 Rights of Termination</h3>
                                    <ul className="article">
                                        <ol className="article">
                                            <li className="text">Subscriber may terminate this Agreement upon an Iseehear Event of Default.</li>
                                            <li className="text">Subscriber may elect to terminate this Agreement if Iseehear fails to achieve certain Service Level Commitments, as set out in Schedule C.</li>
                                            <li className="text">Iseehear may terminate this Agreement upon a Subscriber Event of Default.</li>
                                        </ol> 
                                    </ul>                            
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo9" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo9">
                                    <h3 className="caption">9.2 Notice of Termination</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo9" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo9">
                                <div className="accordion-body">
                                    <p className="text">Any termination hereof shall be by written notice of the terminating party to the other party setting out, as the case may be, the Iseehear Event of Default or Subscriber Event of Default or failure to achieve Service Level Commitments for 30 consecutive days that has given rise to the right of termination.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree9" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree9">
                                    <h3 className="caption">9.3 Upon Termination</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree9" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree9">
                                <div className="accordion-body">
                                    <p className="text">In the event of the termination hereof, each party shall return to the other party the other party's Confidential Information or, upon request, destroy the Confidential Information and all copies thereof and certify in writing that the same have been destroyed. Notwithstanding such termination, all obligations arising prior thereto shall be complied with and any provisions of this Agreement that by their nature operate beyond the termination or expiry of this Agreement shall survive such termination.</p>
        
                                </div>
                                </div>
                            </div>
                        </div>

                        <div id="ARTICLE10" className="subscription-list">
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne10" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne10">
                                    <h3 className="caption">ARTICLE 10</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne10" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne10">
                                <div className="accordion-body">           
                                    <h3 className="subCaption">DEFAULTS</h3>
                                    <h3 className="subCaption">10.1 Defaults and Events of Default</h3>
                                    <p className="text">The occurrence of any one or more of the following events shall constitute a Default by a party under this Agreement but shall only be considered a Subscriber Event of Default or Iseehear Event of Default, as the case may be, if such Default is not remedied prior to the expiry of the relevant notice period (if any) or the relevant cure period (if any) applicable to such Default as hereinafter set out:</p>
                                    <ul className="article">
                                        <ol className="article">
                                            <li className="text">A party fails to perform or observe any of its material obligations under this Agreement (other than achievement of Service Level Commitments, governed by Section 10.4) and such failure shall continue unremedied for a period of 10 days following receipt of notice thereof (giving particulars of the failure in reasonable detail) from the other party, or such longer period as may be reasonably necessary to cure such failure (if such failure is capable of being cured);</li>
                                            <li className="text">A party fails to make payment when due to the other party and such failure shall continue unremedied for a period of 30 days following receipt of notice thereof from the other party.</li>
                                            <li className="text">A party has entered court sanctioned bankruptcy protection and is subsequently deemed insolvent by a court.</li>
                                        </ol> 
                                    </ul>                            
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo10" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo10">
                                    <h3 className="caption">10.2 Rights Upon an Event of Default</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo10" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo10">
                                <div className="accordion-body">
                                    <p className="text">Upon the occurrence of a Subscriber Event of Default or an Iseehear Event of Default, the non- defaulting party may do any or all of the following as such party, in its sole and absolute discretion shall determine:</p>
                                    <ul className="article">
                                        <ol className="article">
                                            <li className="text">it may immediately terminate this Agreement by giving notice thereof pursuant to Section 9.2; and</li>
                                            <li className="text">it may exercise any of its other rights and remedies provided for hereunder or otherwise available to it at law or in equity.</li>
                                        </ol> 
                                    </ul>  
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree10" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree10">
                                    <h3 className="caption">10.3 Right of Iseehear to Suspend</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree10" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree10">
                                <div className="accordion-body">
                                    <p className="text">Upon the failure of a Subscriber to pay any amount payable to Iseehear, Iseehear may, in addition to any other rights herein or available at law, suspend the availability of Services to the Subscriber and Website Users pending payment of such amount.</p>  
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFoure10" aria-expanded="false" aria-controls="panelsStayOpen-collapseFoure10">
                                        <h3 className="caption">10.4 Failure to Achieve Service Level Commitments</h3>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFoure10" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFoure10">
                                    <div className="accordion-body">
                                        <p className="text">If Iseehear fails to achieve Service Level Commitments for 30 consecutive days, Subscriber's remedies are limited to availing itself of the remedies set out in Schedule C. Subscriber is not entitled to damages or any other remedy save the foregoing for failure to achieve the Service Level Commitments.</p>  
                                    </div>
                                </div>
                                </div>
                        </div>

                        <div id="ARTICLE11" className="subscription-list">
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne11" aria-expanded={true} aria-controls="panelsStayOpen-collapseOne11">
                                    <h3 className="caption">ARTICLE 11</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne11" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne11">
                                <div className="accordion-body">           
                                    <h3 className="subCaption">GENERAL</h3>
                                    <h3 className="subCaption">11.1 Force Majeure</h3>
                                    <p className="text">Neither party shall be liable to the other for any delay or failure to perform due to fire, flood, earthquake, acts of God, acts of war, riots, civil disorder, strikes, lock-outs or labour disruptions, the failure of telecommunications systems or any other cause whether similar or dissimilar beyond its reasonable control (a "Force Majeure Event"). Upon the occurrence of a Force Majeure Event, the non-performing party shall be excused from any further performance of
                                    those of its obligations pursuant to this Agreement affected by the Force Majeure Event only for so long as such Force Majeure Event continues and such party continues to use commercially reasonable efforts to recommence performance whenever and to whatever extent possible without delay.</p>                           
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo11" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo11">
                                    <h3 className="caption">11.2 Notices</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo11">
                                <div className="accordion-body">
                                    <p className="text">All notices, requests, approvals, consents and other communications required or permitted under this Agreement shall be given by email addressed as follows:</p>
                                    <ul className="article">
                                        <li className="text">if to Customer, As per the email address submitted by the Subscriber in the registration form</li>
                                        <li className="text">if to Iseehear, Email: support@iseehear.com</li>                                   
                                    </ul>  
                                    <p className="text">Either party may change its email address for notification purposes by giving the other party notice of the new email address and the date upon which it will become effective. A communication shall be deemed to have been received as of the next Business Day following its transmission if transmitted after 4 p.m.</p>
                                    <p className="text">Eastern Time (Toronto).</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree11" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree11">
                                    <h3 className="caption">11.3 Electronic Signature</h3>
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree11">
                                <div className="accordion-body">
                                    <p className="text">This Agreement may be executed in counterparts, all of which shall constitute one single agreement between the parties.</p>  
                                </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFoure11" aria-expanded="false" aria-controls="panelsStayOpen-collapseFoure11">
                                        <h3 className="caption">11.4 Relationship</h3>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFoure11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFoure11">
                                    <div className="accordion-body">
                                        <p className="text">Except as expressly set out herein, nothing contained in this Agreement shall create or imply any agency relationship between the parties, nor shall this Agreement be deemed to constitute a joint venture or partnership between the parties.</p>  
                                    </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive11" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive11">
                                        <h3 className="caption">11.5 Severability</h3>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFive11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive11">
                                    <div className="accordion-body">
                                        <p className="text">If any provision of this Agreement is held by a court of competent jurisdiction to be unenforceable or contrary to law, then the remaining provisions of this Agreement, or the application of such provisions to persons or circumstances other than those as to which they are invalid or unenforceable, shall not be affected thereby, and each such provision shall be valid and enforceable to the extent granted by law.</p>  
                                    </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix11" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix11">
                                        <h3 className="caption">11.6 Entire Agreement</h3>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseSix11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix11">
                                    <div className="accordion-body">
                                        <p className="text">This Agreement constitutes the entire agreement between the parties relating to the subject matter hereof, and there are no other representations, understandings or agreements between the parties relating to the subject matter hereof.</p>  
                                    </div>
                                </div>
                            </div>
                            <div className="accordion">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven11" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven11">
                                        <h3 className="caption">11.7 Governing Law</h3>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseSeven11" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven11">
                                    <div className="accordion-body">
                                        <p className="text">These Terms of Use shall be governed by the laws of the Province of Ontario and the federal laws of Canada applicable therein, excluding their rules governing conflicts of laws. The courts situate in the City of Toronto shall have exclusive jurisdiction over all disputes. The parties agree that jurisdiction and venue in such courts is appropriate and irrevocably attorn to the jurisdiction of such courts.</p>  
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="SCHEDULEATERMSOFUSE" className="subscription-list">
                            <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                            <span className="caption">SCHEDULE A - TERMS OF USE</span><br /><br />       
                            <p className="text">THESE TERMS OF USE ARE APPLICABLE TO ALL USERS OF ISEEHEAR WEBSITES.</p>
                            <p className="text">The term "Subscriber" in these Terms of Use refers to the customer whose information has been provided to Iseehear in the course of entering into the Subscription Agreement. The term "Website User" refers to an individual who has access to Subscriber's Research Project Website for purposes established by the Subscriber. You are also a Website User. The term "Website" refers to a Research Project website operated by Subscriber. The term "Research Project" means a single Research Project (which may include any number of related sub- projects) involving Subscriber and Website Users, where Subscriber is involved in the Research Project.</p>
                            <p className="text">Each Website is operated by Subscriber. By accessing and using a Website you agree to be bound by the following terms of use (the "Terms of Use") and by all applicable law. Subscriber reserves the right to modify the Terms of Use at any time. Your continued access and use of the Website is your acceptance of the Terms of Use as modified.</p>
                        </div>

                        <div className="subscription-list">
                            <span className="caption">Nature of Services</span><br /><br />       
                            <p className="text">Iseehear Inc. ("Iseehear") makes available to Subscriber, via the Website and offline modules forming part of the Technology, a digital information repository pertaining to Research Projects where you and other Website Users can examine digitally stored documents and, where authorized, amend same or negotiate the amendment of same. You have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all documents and information you post on the Website and Iseehear shall not be responsible or liable for your or any third party's deletion, correction, destruction, damage, loss or failure to store any documents and information or for their or your misuse of Iseehear's technology.</p>
                        </div>

                        <div className="subscription-list">
                            <span className="caption">Your Obligations as a Website User</span><br /><br />       
                            <p className="text">Every time you wish to access the Website, you are required to submit your Username and Password. Your Username and Password are confidential. You are responsible for maintaining the confidentiality and security of your Username and Password, and any and all related transmissions or communications using your Username and Password, whether or not authorized by you. You must not share your Username or Password with anyone else or permit anyone to access the Website using your personal Username and Password. You are responsible for promptly advising Subscriber of any need to change or deactivate your Username or Password.</p>
                            <p className="text">You acknowledge and agree that Subscriber may, but is not obliged, to maintain any documents and information posted to a Website beyond the conclusion of the Research Project as determined by Subscriber at its sole discretion. All documents and information posted on a Website are posted in good faith but neither Subscriber nor Iseehear makes any representations or warranties of any kind, express or implied, regarding the content, completeness or accuracy of the documents and information posted to a Website. Any use or reliance upon any document or information posted on a Website by you or any other Website User shall be at your or his or her risk, as the case may be. Neither Subscriber nor Iseehear undertakes any obligation to provide Website Users with access to any additional information or to update or complete the documents or information posted to a Website or correct any inaccuracies therein.</p>
                            <p className="text">Subject to the access rights you have been assigned, you may be eligible to partake in uploading documents to a Website in which case you acknowledge that you have voluntarily assumed or will assume responsibility for posting your documents on the Website with a view to facilitating the completion of a Research Project and agree that you will be solely responsible for all documents so posted. Without limiting the generality of the foregoing, you agree that you will not post documents or information: (a) of which you do not possess sufficient right, title or interest to allow the posting; (b) that infringe or violate any intellectual property, privacy or publicity rights, laws or regulations, or are libelous, defamatory, harmful to minors, obscene or pornographic, or are materially false, misleading or inaccurate; (c) that contain any viruses or programming routines intended to damage, disable, surreptitiously intercept or expropriate any system, data or personal information; or (d) are unrelated to the Research Project. Subscriber may take such remedial action as it considers appropriate if the documents or information you or any other Website User posts violate this provision; however, neither Subscriber nor Iseehear is under any obligation to review any documents for accuracy or potential liability. Website User grants to Subscriber a right and license to use, copy and modify such documents and information to the fullest extent necessary for Subscriber to operate the Website and facilitate completion of the Research Project for which the documents and other information were posted.</p>
                            <p className="text">If you are eligible to partake in uploading documents or information to a Website, you acknowledge that you are responsible to create electronic versions of documents for posting to the Website in the file format of applications supported by the Website (MS Word, Adobe Acrobat, MS Excel and any other application from time to time approved by Subscriber) ("Acceptable Format"). You agree to: (a) post clear and legible documents, in Acceptable Format, that have been confirmed as virus-free by a recognized virus screening application; (b) cooperate with Subscriber in correcting any problems associated with documents posted to a Website by you or on your behalf; (c) retain as a back-up resource a complete set of the documents posted by you or on your behalf; (d) report promptly to Subscriber any problems or errors that you observe or discover with respect to documents or information posted to a Website by you or on your behalf; and (e) notify Subscriber, promptly by e-mail, of all court orders restricting the use, distribution or disposition of any documents or information posted to Website by you or on your behalf.</p>
                        </div>

                        <div className="subscription-list">
                            <span className="caption">Restrictions on Use</span><br /><br />       
                            <p className="text">You acknowledge that you do not have the right, and you hereby promise not, to reverse engineer, disassemble, decompile, unlock, recreate, copy, license, sublicense, create derivative software from, sell, rent, lease, loan, distribute, transfer or otherwise deal with any of Iseehear's technology. You promise not to assist any third party to do any of the foregoing.</p>
                        </div>

                        <div className="subscription-list">
                            <span className="caption">Intellectual Property</span><br /><br /> 
                            <p className="text">As between you and Iseehear, Iseehear owns and will own all intellectual property rights in its own technology, including the websites you access in order to use the Iseehear technology and each Iseehear Website, including the look and feel of the Website and the underlying technology, database designs and architecture and any elements of such technology that are intrinsic to such technology.</p>
                            <p className="text">Iseehear has no intellectual property rights in the documents and data you post to the Website.</p> 
                            <p className="text">The posting of documents and information to the Website will not affect any right, title, or interest (including without limitation all copyright and trade mark rights) of you or any other Website User or any other person in the documents and information posted.</p> 
                            <p className="text">Subscriber is the owner or licensee and authorized user of all trademarks, registered trademarks and/or service marks appearing on the Website and is the copyright and intellectual property licensee of the design, structure and function of the Website, including but not limited to all screens appearing at the Website, and all software downloadable or otherwise used from or on the Website. Subscriber does not grant license or other authorization to you or any other Website User of Subscriber trademarks, registered trademarks, service marks, or other copyright protected material or other intellectual property, by placing them on the Website except such limited use as is permitted pursuant to these Terms of Use for the purpose of the Research Project. All rights not expressly granted herein are reserved to Subscriber.</p>    
                        </div>

                        <div className="subscription-list">
                            <span className="caption">Confidentiality Covenant</span><br /><br />       
                            <p className="text">For purposes of these Terms of Use, the term "Confidential Information" means proprietary information now or hereafter owned by or otherwise within the possession of you, Subscriber, any other Website User or Iseehear, including, but not limited to, patented and unpatented inventions, copyright, business and trade secrets, marketing plans, programs (including source code), data, listings, flowcharts, manuals, instructions, notes and other documentation, customer and shareholder information, and other tangible information related to the business of that party. The software code, documentation, design and layout of the pages contained in and on the Website are deemed to be Confidential Information of Iseehear. The documents and information will be deemed to be Confidential Information of the Website Users posting the documents and information. Notwithstanding the forgoing, the term Confidential Information does not include:</p>
                            <ul className="article">
                                <li className="text">information that was in the receiving party's possession or was known to it prior to its receipt from the disclosing party as evidenced in writing in the receiving party's files;</li>
                                <li className="text">information that is or becomes public other than as a result of disclosure directly or indirectly by the receiving party, or those for whom it is responsible at law, in breach of this agreement;</li>
                                <li className="text">information that is or becomes rightfully available on an unrestricted basis to the receiving party from a source other than the disclosing party, unless the receiving party knows that such source is in breach of a contractual, fiduciary or other legal obligation of confidentiality to the disclosing party; or</li>
                                <li className="text">information that becomes available on an unrestricted basis to a third party from the disclosing party or from someone acting with its authorization or under its control. You will not at any time without the prior written consent of the appropriate other party publish, disclose or otherwise disseminate, duplicate or use, directly or indirectly, Confidential Information of the other party to or for your own benefit or that of any of your employees or subcontractors or any other third party. You acknowledge that the Internet is an open system and that Subscriber cannot guarantee that third parties will not unlawfully access the Website and the Website User's Confidential Information.</li>
                            </ul> 
                        </div>

                        <div className="subscription-list">
                            <span className="caption">Injunctive Relief</span><br /><br />       
                            <p className="text">Each of you, Subscriber and Iseehear acknowledges and agrees that:</p>
                            <ul className="article">
                                <li className="text">the restrictions set forth in these Terms of Use are reasonable in the circumstances and all defenses to the strict enforcement thereof by each of us are hereby waived;</li>
                                <li className="text">a violation of any of the provisions of these Terms of Use will result in immediate and irreparable harm and damage; and</li>
                                <li className="text">in the event of any violation of any provision of these Terms of Use, the party suffering damage shall be entitled to apply for equitable relief by way of temporary or permanent injunction and to such other relief as any court of competent jurisdiction may deem just and proper.</li>
                            </ul> 
                        </div>

                        <div className="subscription-list">
                            <span className="caption">Disclaimer</span><br /><br />       
                            <p className="text">SUBSCRIBER AND ISEEHEAR AND EACH OF THEIR LICENSORS MAKE NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, TRUTH, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICES. SUBSCRIBER, ISEEHEAR AND EACH OF THEIR LICENSORS DO NOT REPRESENT OR WARRANT THAT</p>
                            <ul className="article">
                                <li className="text">THE USE OF THE SERVICES WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA,</li>
                                <li className="text">THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS,</li>
                                <li className="text">ANY STORED DATA WILL BE ACCURATE OR RELIABLE,</li>
                                <li className="text">ERRORS OR DEFECTS WILL BE CORRECTED, OR</li>
                                <li className="text">THE SERVICES OR THE SERVER(S) THAT MAKE THE SERVICES AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE WEBSITE IS PROVIDED STRICTLY ON AN "AS IS" BASIS.</li>
                            </ul> 
                            <p className="text">EXCEPT AS SPECIFICALLY SET FORTH IN THIS AGREEMENT, ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, OR NON- INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY SUBSCRIBER, ISEEHEAR AND EACH OF THEIR LICENSORS.</p>
                        </div>

                        <div className="subscription-list">
                            <span className="caption">Rights of Termination</span><br /><br /> 
                            <p className="text">Subscriber or Iseehear may terminate your rights of access to the Website(s) immediately if you breach any of these Terms of Use. Iseehear may inform you of termination by sending a notice of termination to the Subscriber that has arranged for you to have access to a Website. In no event will your right to access the Website extend beyond the conclusion of the Research Project as determined by Subscriber at its sole discretion.</p>      
                        </div>

                        <div className="subscription-list">
                            <span className="caption">Limitation of Liability and Damages</span><br /><br />
                            <p className="text">Subscriber has taken reasonable precautions and implemented measures to protect the security of the information and materials posted on the Website. However, Subscriber does not warrant that the Website will be compatible with your computer operating system, or that your access to the Website will be uninterrupted, timely, completely secure or error free. You understand and agree that any material downloaded or otherwise obtained through your use of the Website is done at your own discretion and risk and that you will be solely responsible for any damage to your computer system or loss of data that results from downloading such material.</p>
                            <p className="text">Neither Subscriber nor Iseehear nor any of their respective partners, associates, employees, agents, successors, assigns, affiliates, or content or service providers shall be liable to you or any other Website User or any third party for direct or indirect damages of any kind, including without limitation loss of profits or other economic loss, or for any exemplary, incidental, special or consequential damages, even if it has been advised of the possibility of such damages, arising out of the posting or non-posting of documents and information, the design, functionality or use of the Website or inability to gain access to or use the Website or any part thereof or to any documents and information, or out of any breach of any warranty, express or implied.</p>
                        </div>

                        <div className="subscription-list">
                            <span className="caption">Force Majeure</span><br /><br /> 
                            <p className="text">Iseehear shall be not liable to you for any delay or failure to perform due to fire, flood, earthquake, acts of God, acts of war, riots, civil disorder, strikes, lock-outs or labour disruptions, the failure of telecommunications systems or any other cause whether similar or dissimilar beyond its reasonable control (a "Force Majeure Event"). Upon the occurrence of a Force Majeure Event, Iseehear shall be excused from any further performance of those of its obligations pursuant to these Terms of Use affected by the Force Majeure Event only for so long as such Force Majeure Event continues and Iseehear continues to use commercially reasonable efforts to recommence performance whenever and to whatever extent possible without delay.</p>
                        </div>

                        <div className="subscription-list">
                            <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                            <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                            <span className="caption">Governing Law</span><br /><br /> 
                            <p className="text">These Terms of Use shall be governed by the laws of the Province of Ontario and the federal laws of Canada applicable therein, excluding their rules governing conflicts of laws. The courts situate in the City of Toronto shall have exclusive jurisdiction over all disputes. The parties agree that jurisdiction and venue in such courts is appropriate and irrevocably attorn to the jurisdiction of such courts.</p>
                        </div>

                        <div className="subscription-list">
                            <span className="caption">Assignment</span><br /><br /> 
                            <p className="text">You may not assign or delegate any part of this Agreement without prior notice to, and the express written consent of Subscriber. These Terms of Use constitute the entire agreement between you, the Subscriber and Iseehear and supersede all previous agreements, promises, proposals, representations, understanding and negotiations, whether written or oral, between the parties respecting the subject matter hereof. The failure of Subscriber or Iseehear to enforce any provisions of these terms or to respond to a breach by you or any other Website User of these terms shall not in any way waive Subscriber's or Iseehear's right to subsequently enforce any of the terms and conditions contained herein or to act with respect to similar breaches.</p>
                        </div>

                        <div id="SCHEDULEBFEES" className="subscription-list">
                            <span className="caption">SCHEDULE B - FEES</span><br /><br /> 
                            <p className="text">(A) Subscription Fees</p>
                            <p className="text">Please refer to the Subscriber’s official subscription quote.</p>
                            <p className="text">All sales are final 30 days post account activation.</p>
                            <p className="text">(B) Subscription Term</p>
                            <p className="text">The Subscription Term commences on the date posted in the accepted Subscriber Quote and continues until all subscriptions hereunder have expired or have been terminated.</p>
                            <p className="text">The Subscription Term of each subscription shall be as specified in the accepted Subscriber Quote.</p>
                            <p className="text">At the end of the subscription term, except as otherwise specified in the accepted Subscriber Quote, subscriptions will NOT automatically renew for an additional term.</p>
                            <p className="text">The Subscriber must inform Iseehear that they want to renew for additional terms. The Subscriber has no obligation to renew at the conclusion of their subscription.</p>
                            <p className="text">The Subscriber does NOT have to provide prior written notice that they do not intend to renew their subscription.</p>
                            <p className="text">Any renewal in which subscription volume or subscription length for any Services has increased or decreased from the prior term will result in re-pricing at renewal without regard to the prior term’s per-unit pricing. </p>
                            <p className="text">(C) Implementation fees Refund Policy</p>
                            <p className="text">If an implementation fee has been quoted to the Subscriber, and in the event of un-remedied failure to meet Service Level Commitments, and only if the subscription is cancelled within 3 (three) months of subscription start date, the implementation fee will be refunded to the Subscriber. Refunds will be transacted in the currency that the subscriber used to pay for the subscription.</p>
                        </div>

                        <div id="SCHEDULECSERVICELEVELCOMMITMENTS" className="subscription-list">
                            <span className="caption">SCHEDULE C - SERVICE LEVEL COMMITMENTS</span><br /><br /> 
                            <ul className="scheduleC">
                                <li className="text">Iseehear will ensure that its servers and other information technology systems under its control that allow access to the Website are operating at a rate of 99 percent of potential uptime.</li>
                                <ul className="article">
                                    <li className="text">Potential uptime is the number of hours in a particular calendar month less planned downtime of 12 hours per calendar month, but no more than 8 consecutive hours at any given time.</li>
                                    <li className="text">If required, planned downtime will occur only during the night-time hours between 9 p.m. and 3 a.m., Eastern Time (Toronto).</li>
                                    <li className="text">If downtime is required or in the event of service disruption, event status information will be posted and updated at the Iseehear: Health Status Check Dashboard https://iseehearhealth.com/status.</li>
                                </ul>
                                <li className="text">Iseehear will maintain the Technology in good working order in accordance with its Functional Specifications.</li>
                                <li className="text">Iseehear will provide support desk response to Website Users within 24 hours of receipt by way of phone or email.</li>
                                <li className="text">Iseehear will maintain security over its servers that host the Websites as follows:</li>
                                <ul className="article">
                                    <li className="text">Iseehear will cause to be maintained at all times data centre physical security consisting of uniformed security personnel both on-site and in the surrounding area 24/7/365 as well as an industry standard security system and security protocols. This physical security system of the data centre is a configurable point monitoring and access control system providing a reliable means of monitoring building security devices and systems such as card readers, alarm management, recording of data and comprehensive reporting capabilities as well as a photo identification system. Incorporated into this system are optical turnstiles, which provide a second level of security at the main entrance to the data centre. There are closed circuit television (CCTV) cameras located through-out the building housing the data centre.</li>
                                    <li className="text">The database of materials posted on the Website is protected by the following:</li>
                                    <ul className="latinTitle">
                                        <li className="text">128 bit SSL security certificates for data encryption;</li>
                                        <li className="text">Network firewalls and anti-virus and intrusion applications; and</li>
                                        <li className="text">Application firewall that actively monitors user behaviour to ensure it within acceptable parameters of application use. Any unusual behaviour is flagged and the user session terminated automatically.</li>
                                    </ul>
                                </ul>
                                <li className="text">Iseehear provides enhanced business continuity with the following:</li>
                                <ul className="article">
                                    <li className="text">the data centre is equipped with diesel fuel storage facilities to provide fuel to the data centre generators in the case of power failure. Refueling trucks and roll-up generators have unrestricted access to the building to ensure the 24/7/365 operation of facilities during an emergency;</li>
                                    <li className="text">in excess of 25 fibre optic entrances to the data centre; and</li>
                                    <li className="text">Iseehear backs up data physically once each day to an out of district location in case of failure of the data centre.</li>
                                    <li className="text">Iseehear is responsible for delivering regular upgrades and continuous enhancements to the Website. Iseehear will notify you prior to releasing upgrades and new functionality and will notify you again once the upgrades and new functionality have been deployed.</li>
                                </ul>
                            </ul>
                        </div>

                        <div id="SCHEDULEDADDITIONALPROVISIONS" className="subscription-list">
                            <span className="caption">SCHEDULE D - ADDITIONAL PROVISIONS</span><br /><br /> 
                            <p className="text">There are no additional provisions at this time.</p>
                            <p className="subCaption">Contact Information</p>
                            <p className="text">If you have any questions please contact Iseehear Inc. at information@iseehear.com or at</p>
                            <p className="text">Iseehear Inc. Life Sciences</p>
                            <p className="text">250 University Avenue</p>
                            <p className="text">Suite 200</p>
                            <p className="text">Toronto, Ontario</p>
                            <p className="text">Canada, M5H 3E5</p>
                        </div>

                        <div className="subscription-list">
                            <p className="text">Iseehear is a trademark of Iseehear Inc.</p>
                            <p className="text">Copyright (C) 2000-2019 Iseehear Inc. All rights reserved.</p>
                            <p className="text">Document Last Revised October 22, 2020</p>
                        </div>

                    </div>
            </section>
            <Footer />
        </div>
    )
}