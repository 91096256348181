import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { HashRouter as Router } from 'react-router-dom';
import { GeneralProvider } from './Context/GeneralContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GeneralProvider>
    <App /> 
  </GeneralProvider>
);

reportWebVitals();