import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const SecurityOverview = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Security Overview</title>
                <meta name="description" content="The Iseehear Inc. Information Security Management policies were established in order to protect our subscriber's Data, Intellectual Property, Privacy, as well as address Iseehear's IT Security, Risk Management, Research Continuity and Support Services." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="security_overviev">
                    <div className="container">

                        <div className="row security_caption">
                            <div className="col-lg-9 elemetn_mash">
                                <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                <h3 className="mainCaption">Data Security Overview</h3>
                                <hr style={{borderBottom: "2px solid lightblue"}} />
                                <h4 className="caption">Information Security Management (ISM)</h4><br />
                            </div>
                        </div>

                        <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px"}}>
                            <div className="information_security">
                                <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                                <div className="">
                                    <p className="text">There are a few basic realities that must be observed related to providing secure and continuous Cloud based Software as a Service (SaaS) solutions. Software may have minor or major defects (bugs), databases may crash, hardware may fail, networks may get overwhelmed and good people sometimes miscalculate or make mistakes. In order to address the risks associated with providing Internet, Cloud and Mobile centric services Iseehear Inc. has established Information Security Management policies as well as Disaster Recovery procedures.<br /><br /></p>
                                    <p className="text">The Iseehear Inc. Information Security Management policies were established in order to protect our subscriber's Data, Intellectual Property, Privacy, as well as address Iseehear's IT Security, Risk Management, Research Continuity and Support Services.<br /><br /></p>

                                    <p className="text">Iseehear Inc. is committed to providing Trusted and Reliable continuous service and to that end, Disaster Recovery and Research Continuity processes and services have been established to address the inevitable service disruption  challenges that will arise from time to time. <Link className="textLink" to="/disasterRecovery">Click here to learn more about Iseehear Disaster Recovery and Research Continuity processes.</Link></p>
                                    <p className="text"><br />
                                        All Iseehear cloud applications are supported by a robust Information Security Management and Data Governance structure that addresses Iseehear's application and IT infrastructure security, risk management policies, disaster recovery policies and research continuity policies.
                                    </p>

                                    <p className="subCaption subscription-caption">The security, compliance and research continuity features included with all Iseehear Inc. Life Sciences subscriptions:</p>
                                    <ul className="subscription-list">
                                        <li className="text">256 bit Secure-Socket Layer (SSL) encryption of all client data transmitted over the network, including username and passwords. <Link to="/softmouseEncryption"><button className="button sub_button">Learn more</button></Link></li>
                                        <li className="text">256-bit encryption of all customer data at rest within Iseehear's databases.</li>
                                        <li className="text">Malware surveillance is run on a daily basis on the https://www.SoftMouse.NET website.</li>
                                        <li className="text">Cloud system redundancy, disaster recovery and research continuity plans are tested regularly.</li>
                                        <li className="text">All Iseehear Inc. Life Sciences customer passwords are securely hashed (passwords are never stored in plaintext).</li>
                                        <li className="text">All data access is protected by a role-based access-control, which only lets users view data for which they have permission.</li>
                                        <li className="text">Iseehear Inc. Life Sciences ensures that customer data remains available through full, automated daily backups. </li>
                                        <li className="text">Iseehear Inc. Life Sciences customer’s also have the option to manually back their data up to a spreadsheet whenever they so desire.</li>
                                    </ul>


                                    <p className="text">Please navigate through the menus of this website for an overview of Iseehear Inc. Information Security Management documents supporting the delivery of Iseehear Inc. Life Sciences Internet Cloud Service.</p>
                                </div>
                            </div>
                        </div>

                                <hr style={{borderBottom: "2px solid lightblue"}} />
                              
                                    <h4 className="caption"><b>Intellectual Property Rights (Including all data and content)</b></h4>
                                    <p className="text">Iseehear Inc. Life Sciences subscribers reserve all right, title and interest (including all intellectual property and proprietary rights) in and to all content and data that they input, manipulate and store using Iseehear's Software and Database Services. Iseehear Inc. Life Sciences will never use its subscribers' data or make their data available to any third party.</p><br />
                                    <div className="agriment-list">                                       
                                        <div className="largefont element_left">
                                            <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                            <br /><br /><h2 className="subCaption">Please read more by reviewing the Iseehear Inc. Life Sciences Subscription Agreement:</h2><br />
                                            <ul className="largefont">
                                                <li><Link className="text" to="/subscriptionAgreement">The Iseehear Subscription Agreement <img src={require('../../img/external-link-black.jpg')} alt="link" /></Link></li><br />
                                                <li><Link className="text" to="/privacyPolicy">The Iseehear Privacy Policy <img src={require('../../img/external-link-black.jpg')} alt="link" /></Link></li><br />
                                                <li><Link className="text" to="/betaSiteAgreement">The Iseehear Beta Site Agreement <img src={require('../../img/external-link-black.jpg')} alt="link" /></Link></li><br />
                                            </ul>
                                        </div>

                                        <div className="largefont element_right">
                                            <img className="element" src={require("../../img/newPage/element.png")} alt="element" />
                                            <h4 className="subCaption"><b>Additional Information Security Links</b></h4>

                                            <ul className="largefont">

                                                <li><Link className="text" to="/securityOverview">Security Overview</Link></li>                                              
                                                <li><Link className="text" to="/softmouseEncryption">Softmouse Encryption</Link></li>
                                                <li><Link className="text" to="/malwareDetection">Malware Detection</Link></li>
                                                <li><Link className="text" to="/passwordManagement">Password Management</Link></li>
                                                <li><Link className="text" to="/dataBackup">Data Backup</Link></li>
                                                <li><Link className="text" to="/disasterRecovery">Disaster Recovery</Link></li>
                                                <li><Link className="text" to="/googleCloudPlatform">Google Cloud Platform</Link></li>
                                                <li><Link className="text" to="/whois">WHOIS Verification & Authentication</Link></li>
                                                <li><Link className="text" to="/browserRequirements">Supported Internet Browsers</Link></li>
                                                <li><Link className="text" to="/securityHygiene">Security Hygiene Tips</Link></li>
                                                <li><Link className="text" to='/intellectualProperty'><span>Intellectual Property Research Data Rights Policy</span></Link></li>
                                                <li><Link className="text" to='/emergencyPlan'><span>Emergency Planning and Preparedness</span> <img src={require('../../img/external-link-black.jpg')} alt="link" /></Link></li>

                                            </ul>
                                            <br /><br /><br />                                           
                                        </div>
                                    </div>
                                        <div className="required-links">
                                            <div className="text"><img src={require('../../img/external-link-black.jpg')} alt="link" />&nbsp;&nbsp;Indicates the link goes to another Iseehear owned or authorized website</div>
                                            <div className="text"><img src={require('../../img/SoftMouse_download_black.png')} alt="softmouse" />&nbsp;&nbsp;Indicates PDF Download<br /></div>
                                        </div>

                    </div>
            </section>
            <Footer />
        </div>
    )
}