import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const GoogleCloudPlatform = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Google Cloud Platform</title>
                <meta name="description" content="The SoftMouse.NET software as a service cloud infrastructure is provided by Google Cloud Platform, a scalable and secure cloud services platform. The Google Cloud Platform’s physical infrastructure has been accredited under ISO 27001, SOC 1/SOC 2/SSAE 16/ISAE 3402, PCI Level 1, FISMA, and Sarbanes-Oxley." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="google-cloud-platform">
                <div className="container">

                    <div className="row disaster-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">Google Cloud Platform Security Links</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                            <h4 className="caption">Information Security Management (ISM)</h4><br />
                        </div>
                    </div>

                    <div className="content-section-b" style={{border: "none", paddingBottom: "20px", paddingTop: "20px"}}>

                        <div className="largefont">

                            <br /><p className="text">The SoftMouse.NET software as a service cloud infrastructure is provided by Google Cloud Platform, a scalable and secure cloud services platform.

                            The Google Cloud Platform’s physical infrastructure has been accredited under ISO 27001, SOC 1/SOC 2/SSAE 16/ISAE 3402, PCI Level 1, FISMA, and Sarbanes-Oxley.</p><br /><br />


                            <div className="google-list">
                                <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                                <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                                <p className="text">Google Infrastructure Security Design Overview<br />
                                <a className="google-link textLink" href="https://cloud.google.com/security/security-design/" target="_blank" rel="noreferrer">https://cloud.google.com/security/security-design/ <img src={require("../../img/external-link-red.png")} alt="link-red" /></a></p><br /><br />

                                <p className="text">Google Cloud Platform Security<br />
                                Independent Audits of Infrastructure, Services, and Operations<br />
                                <a className="google-link textLink" href="https://cloud.google.com/security/compliance" target="_blank" rel="noreferrer">https://cloud.google.com/security/compliance <img src={require("../../img/external-link-red.png")} alt="link-red" /></a></p><br /><br />

                                <p className="text">Google Security Whitepaper<br />
                                <a className="google-link textLink" href="https://cloud.google.com/security/whitepaper" target="_blank" rel="noreferrer">https://cloud.google.com/security/whitepaper <img src={require("../../img/external-link-red.png")} alt="link-red" /></a></p><br /><br />


                                <p className="text">Encryption at Rest in Google Cloud Platform<br />
                                <a className="google-link textLink" href="https://cloud.google.com/security/encryption-at-rest/default-encryption/" target="_blank" rel="noreferrer">https://cloud.google.com/security/encryption-at-rest/default-encryption/ <img src={require("../../img/external-link-red.png")} alt="link-red" /></a></p><br /><br />

                                <p className="text">Encryption in Transit in Google Cloud<br />
                                <a className="google-link textLink" href="https://cloud.google.com/security/encryption-in-transit/" target="_blank" rel="noreferrer">https://cloud.google.com/security/encryption-in-transit/ <img src={require("../../img/external-link-red.png")} alt="link-red" /></a></p><br /><br />
                            </div>
                        </div>

                    </div>
                    
                    <br /><br />
                    <div className="row">
                        <div className="indicates-required-white"><p className="text"><img src={require("../../img/external-link-red.png")} alt="link-red" />&nbsp;&nbsp;Indicates the link goes to a third party website.</p><br /><br /></div>
                        <br /><br />
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}