import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const Ocsoexecutivesummary = () => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>Summary of Written Information Security Program</title>
                <meta name="description" content="At Iseehear Inc., the Office of the Chief Security Officer (OCSO) plays a pivotal role in safeguarding our subscribers' data and upholding our commitment to information security. The OCSO oversees the implementation of robust Information Security Management policies and Disaster Recovery procedures." />
                <meta http-equiv="keywords" content="life, sciens, status, health, check" />
            </Helmet>
            <Header logo={require("../../img/iseehear-logo3.png")} errowColor={"#462E6A"} />
            <section className="wisp">
                <div className="container">
                    <div className="row disaster-caption">
                        <div className="col-lg-9">
                            <h3 className="mainCaption">The Office of the Chief Security Officer (OCSO) Executive Summary</h3>
                            <hr style={{borderBottom: "2px solid lightblue"}} />
                        </div>
                    </div>

                <div className="information_security">
                    <img className="frame" src={require("../../img/newPage/Frame (5).png")} alt="frame" />
                    <div>
                        <h4 className="caption">Office of the Chief Security Officer</h4><br/>
                        <p className="text">At Iseehear Inc., the Office of the Chief Security Officer (OCSO) plays a pivotal role in safeguarding our subscribers' data and upholding our commitment to information security. The OCSO oversees the implementation of robust Information Security Management policies and Disaster Recovery procedures. This includes ensuring the integrity of our cloud-based Software as a Service (SaaS) solutions. This entails deploying robust security protocols to anticipate and mitigate potential threats, including cyberattacks or data breaches.<br/><br/></p>
                        <p className="text">Through continuous monitoring, risk assessment, and strategic planning, the OCSO and their team work diligently to mitigate risks and maintain the highest standards of data protection, privacy, and IT security as well as ensuring governance of the organization based on the Regulatory environment and our Compliance frameworks.<br/></p>
                    </div>
                </div>

                <div className="information_security">
                    <h4 className="caption">Providing Peace of Mind for Our Customers</h4><br/>
                    <div>
                        <p className="text">At Iseehear Inc., we prioritize the peace of mind of our valued customers. Here are the factors that contribute to ensuring a secure and reliable experience:<br/></p>
                        <ul className="subscription-list">
                            <li className="text">Encryption: We employ 256-bit Secure-Socket Layer (SSL) encryption for all client data transmitted over the network. Additionally, all customer data stored within our databases is encrypted using 256-bit encryption, both in transit and at rest.</li>
                            <li className="text">Automated Backups: To minimize data loss and ensure business continuity, we implement automated backup measures with a targeted zero Recovery Point Objective (RPO). This means that even in the event of an unforeseen disruption, our customers can rest assured that their data remains secure and accessible.</li>
                            <li className="text">Single Sign-On (SSO): We offer Single Sign-On functionality for secure login, enhancing convenience while maintaining stringent authentication protocols. With SSO, users can access our services with confidence, knowing that their credentials are protected by encryption protocols.</li>
                         </ul>
                        <p className="text">By combining these measures with our comprehensive Information Security Management framework and Disaster Recovery plans, we provide our customers with the assurance they need to focus on their core objectives, knowing that their data is safeguarded with the highest standards of integrity and reliability.<br/><br/></p>
                    </div>
                </div>

                    <div className="google-list anchorLink">
                        <img className="element3" src={require("../../img/newPage/element.png")} alt="element" />
                        <img className="element2" src={require("../../img/newPage/element.png")} alt="element" />
                        <div>
                            <Link className="text pull-right" to="/documents/The-Office-of-the-Chief-Security-Officer-(OCSO)-Executive-Summary.pdf" target="_blank" rel="noreferrer">Download PDF <img src={require("../../img/SoftMouse_download_black.png")} alt="softmouse" /></Link><br />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}