import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Admin } from './Components/Admin/Admin';
import { Accessibility } from './Components/pages/Accessibility';
import { BetaSiteAgreement } from './Components/pages/BetaSiteAgreement';
import { BrowserRequirements } from './Components/pages/BrowserRequirements';
import { Communication } from './Components/pages/Communication';
import  ContactForm  from './Components/pages/ContactForm';
import { ContactUs } from './Components/pages/ContactUs';
import { CookiePolicy } from './Components/pages/CookiePolicy';
import { Copyright } from './Components/pages/Copyright';
import { DataBackup } from './Components/pages/DataBackup';
import { DisasterRecovery } from './Components/pages/DisasterRecovery';
import { EmailPolicy } from './Components/pages/EmailPolicy';
import { EmergencyPlan } from './Components/pages/EmergencyPlan';
import { EmergencyPrepare } from './Components/pages/EmergencyPrepare';
import { EmergencyUpgrade } from './Components/pages/EmergencyUpgrade';
import { FreeTrialPolicy } from './Components/pages/FreeTrialPolicy';
import { GeneralInformation } from './Components/pages/GeneralInformation';
import { GoogleCloudPlatform } from './Components/pages/GoogleCloudPlatform';
import { HumanRightsAndEthicsPolicy } from './Components/pages/HumanRightsAndEthicsPolicy';
import { Index } from './Components/pages/Index';
import { IntelectualProperty } from './Components/pages/IntellectualProperty';
import { MalwareDetection } from './Components/pages/MalwareDetection';
import { Mapping } from './Components/pages/Mapping';
import { NDA } from './Components/pages/NDA';
import { PasswordManagement } from './Components/pages/PasswordManagement';
import { PoliciesOverview } from './Components/pages/PoliciesOverview';
import { PolicyNotices } from './Components/pages/PolicyNotices';
import { PrivacyPolicy } from './Components/pages/PrivacyPolicy';
import { ScheduledSecurityTestingProcedures } from './Components/pages/ScheduledSecurityTestingProcedures';
import { SecurityHygiene } from './Components/pages/SecurityHygiene';
import { SecurityNotifications } from './Components/pages/SecurityNotifications';
import { SecurityOverview } from './Components/pages/SecurityOverview';
import { SoftmouseEncryption } from './Components/pages/SoftmouseEncryption';
import { SubscriptionAgreement } from './Components/pages/SubscriptionAgreement';
import { TermsOfUse } from './Components/pages/TermsOfUse';
import { Trademark } from './Components/pages/Trademark';
import { Upgrade } from './Components/pages/Upgrade';
import { Whois } from './Components/pages/Whois';
import { Wisp } from './Components/pages/Wisp';
import { WorkingFromHome } from './Components/pages/WorkingFromHome';
import { Ocsoexecutivesummary } from './Components/pages/ocsoexecutivesummary';
import  Login  from './Components/PrivateRoute/Login';
import { PrivateRoute } from './Components/PrivateRoute/PrivateRoute';
import GoogleAnalytics from './Components/GoogleAnalytics';
import './scss/main.scss';

function App() {
  return (
    <div className="App">
      <GoogleAnalytics />
      <Router> 
        <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/accessibility' element={<Accessibility />} />
          <Route path='/generalInformation' element={<GeneralInformation />} />
          <Route path='/betaSiteAgreement' element={<BetaSiteAgreement />} />
          <Route path='/browserRequirements' element={<BrowserRequirements />} />
          <Route path='/communication' element={<Communication />} />
          <Route path='/contactForm' element={<ContactForm />} />
          <Route path='/contactUs' element={<ContactUs />} />
          <Route path='/cookiePolicy' element={<CookiePolicy />} />
          <Route path='/copyright' element={<Copyright />} />
          <Route path='/dataBackup' element={<DataBackup />} />
          <Route path='/disasterRecovery' element={<DisasterRecovery />} />
          <Route path='/emailPolicy' element={<EmailPolicy />} />
          <Route path='/emergencyPlan' element={<EmergencyPlan />} />
          <Route path='/emergencyPrepare' element={<EmergencyPrepare />} />
          <Route path='/emergencyUpgrade' element={<EmergencyUpgrade />} />
          <Route path='/freeTrialPolicy' element={<FreeTrialPolicy />} />
          <Route path='/googleCloudPlatform' element={<GoogleCloudPlatform />} />
          <Route path='/humanRightsAndEthicsPolicy' element={<HumanRightsAndEthicsPolicy />} />
          <Route path='/intellectualProperty' element={<IntelectualProperty />} />
          <Route path='/malwareDetection' element={<MalwareDetection />} />
          <Route path='/mapping' element={<Mapping />} />
          <Route path='/nda' element={<NDA />} />
          <Route path='/passwordManagement' element={<PasswordManagement />} />
          <Route path='/policiesOverview' element={<PoliciesOverview />} />
          <Route path='/policyNotices' element={<PolicyNotices />} />
          <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
          <Route path='/securityHygiene' element={<SecurityHygiene />} />
          <Route path='/securityNotifications' element={<SecurityNotifications />} />
          <Route path='/securityOverview' element={<SecurityOverview />} />
          <Route path='/softmouseEncryption' element={<SoftmouseEncryption />} />
          <Route path='/subscriptionAgreement' element={<SubscriptionAgreement />} />
          <Route path='/termsOfUse' element={<TermsOfUse />} />
          <Route path='/trademark' element={<Trademark />} />
          <Route path='/upgrade' element={<Upgrade />} />
          <Route path='/whois' element={<Whois />} />
          <Route path='/wisp' element={<Wisp />} />
          <Route path='/workingFromHome' element={<WorkingFromHome />} />
          <Route element={<PrivateRoute />}>
            <Route path='/admin' element={<Admin />} />
          </Route>
          <Route path='/login' element={<Login />} />
          <Route path='/scheduledSecurityTestingProcedures' element={<ScheduledSecurityTestingProcedures />} />
          <Route path='/ocsoexecutivesummary' element={<Ocsoexecutivesummary />} />
        </Routes>   
      </Router>
    </div>
  );
}

export default App;